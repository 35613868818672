import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { IActivateInactivateResult } from "../../Components/CoreLib/library";
import { BaseDto, QueryResultsDto } from "../../dtos";
import { buildSearchQueryRoute } from "../../util";
import { SearchQueryParams } from "../types";

export class BaseApi<T extends BaseDto> {
    route: string;
    tagName: string;
    builder: EndpointBuilder<any, any, any>;

    constructor(route: string, tagName: string, builder: EndpointBuilder<any, any, any>) {
        this.route = route;
        this.tagName = tagName;
        this.builder = builder;
    }

    getAll() {
        return this.builder.query<QueryResultsDto<T>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute(this.route, params),
            providesTags: [{ type: this.tagName }],
        });
    }

    getById() {
        return this.builder.query<T, string>({
            query: (id) => ({ url: `${this.route}/${id}` }),
            providesTags: [{ type: this.tagName }],
        });
    }

    create() {
        return this.builder.mutation<T, T>({
            query: (clientDto) => ({
                url: this.route,
                method: 'POST',
                body: clientDto,
            }),
            invalidatesTags: [{ type: this.tagName }],
        });
    }

    update() {
        return this.builder.mutation<void, T>({
            query: (clientDto) => ({
                url: this.route,
                method: 'PUT',
                body: clientDto,
            }),
            invalidatesTags: [{ type: this.tagName }],
        });
    }

    archive() {
        return this.builder.mutation<void, string>({
            query: (id) => ({
                url: `${this.route}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: this.tagName }],
        });
    }

    unarchive() {
        return this.builder.mutation<void, string>({
            query: (id) => ({
                url: `${this.route}/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: this.tagName }],
        });
    }

    bulkArchive() {
        return this.builder.mutation<IActivateInactivateResult, string[]>({
            query: (recordIds) => ({
                url: `${this.route}/bulkArchive`,
                method: 'PUT',
                body: recordIds
            }),
            invalidatesTags: [{ type: this.tagName }],
        });
    }

    bulkUnarchive() {
        return this.builder.mutation<IActivateInactivateResult, string[]>({
            query: (recordIds) => ({
                url: `${this.route}/bulkUnarchive`,
                method: 'PUT',
                body: recordIds
            }),
            invalidatesTags: [{ type: this.tagName }],
        });
    }

}