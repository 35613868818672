import { serverApi } from '..';
import { WorkflowConfigClientDto } from '../../dtos';
import { BaseApi } from './base-api';

const workflowConfigClientApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        var baseApi = new BaseApi<WorkflowConfigClientDto>('WorkflowConfigClient', 'WorkflowConfigClient', builder);
        return {
            getWorkflowConfigClients: baseApi.getAll(),
            getWorkflowConfigClient: baseApi.getById(),
            createWorkflowConfigClient: baseApi.create(),
            updateWorkflowConfigClient: baseApi.update(),
            archiveWorkflowConfigClient: baseApi.archive(),
            unarchiveWorkflowConfigClient: baseApi.unarchive(),
            bulkArchiveWorkflowConfigClient: baseApi.bulkArchive(),
            bulkUnarchiveWorkflowConfigClient: baseApi.bulkUnarchive(),
            getCurrentUsersWorkflowConfigClient: builder.query<WorkflowConfigClientDto, void>({
                query: () => ({ url: `${baseApi.route}/current` }),
                providesTags: [{ type: "WorkflowConfigClient" }],
            }),
        };
    },
});

export const {
    useGetWorkflowConfigClientsQuery,
    useLazyGetWorkflowConfigClientsQuery,
    useGetWorkflowConfigClientQuery,
    useLazyGetWorkflowConfigClientQuery,
    useCreateWorkflowConfigClientMutation,
    useUpdateWorkflowConfigClientMutation,
    useArchiveWorkflowConfigClientMutation,
    useUnarchiveWorkflowConfigClientMutation,
    useBulkArchiveWorkflowConfigClientMutation,
    useBulkUnarchiveWorkflowConfigClientMutation,
    useGetCurrentUsersWorkflowConfigClientQuery
} = workflowConfigClientApi;
