import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";

export const serverApi = createApi({
  reducerPath: "serverApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: [
    "Document",
    "User",
    "Asin",
    "SearchTerm",
    "WorkflowConfig",
    "Import",
    "Country",
    "BillingConfig",
    "Brand Analytics",
    "SearchResult",
    "ShelfSpace",
    "ReportDefinitions",
    "WorkflowConfigBrand",
    "WorkflowConfigClient"
  ],
  endpoints: () => ({}),
});
