import { DownloadForOffline, InsertChart } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers'
import { Box, Button, CircularProgress, FormControl, FormLabel, Grid, IconButton, OutlinedInput, Tab, TextField, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { FormSection, LoadingIndicator, NavBreadcrumbs, PaginatedProps, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { CompanyBrandCountrySelect } from '../../Components/Forms/CustomFormSections';
import { WorkflowConfigBrandDto, WorkflowConfigCountryDto, WorkflowConfigDto } from '../../dtos';
import { BrandAnalyticsQueryParams, SearchQueryParams } from '../../store';
import { useGetBrandAnalyticsWeeksQuery, useGetMarketShareDataQuery, useGetMultipleBrandAnalyticsQuery, useGetUnitsPurchasedDataQuery, useLazyDownloadBrandAnalyticsDataCsvQuery } from '../../store/apis/brand-analytics';
import { emptyGuid } from '../../util';
import { BrandAnalyticsDataChart } from './BrandAnalyticsDataChart';
import { MarketShareChart } from './MarketShareChart';
import { BrandAnalyticsTabProps } from './types';
import { UnitsPurchasedChart } from './UnitsPurchasedChart';

const defaultBrandAnalyticsPaginationProps: BrandAnalyticsQueryParams = {
    sortKey: 'RANK',
    sortAsc: false,
    page: 0,
    pageSize: 10,
};

export const BrandAnalyticsView: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const companySearchParam = searchParams.get('company') ?? undefined;
    const brandSearchParam = searchParams.get('brand') ?? undefined;
    const countrySearchParam = searchParams.get('country') ?? undefined;
    const searchQuerySearchParam = searchParams.get('searchQuery') ?? undefined;

    const [company, setCompany] = useState(companySearchParam ?? '');
    const [brand, setBrand] = useState(brandSearchParam ?? '');
    const [country, setCountry] = useState(countrySearchParam ?? '');
    const [searchQuery, setSearchQuery] = useState(searchQuerySearchParam ?? '');
    const [weekEnding, setWeekEnding] = useState<Date | null | undefined>(null);

    const [brandAnalyticsPaginationProps, setBrandAnalyticsPaginationProps] = useState<BrandAnalyticsQueryParams>({
        ...defaultBrandAnalyticsPaginationProps,
        companyId: companySearchParam,
        brandId: brandSearchParam,
        countryId: countrySearchParam,
        searchQuery: searchQuerySearchParam,
    });
    const [marketSharePaginationProps, setMarketSharePaginationProps] = useState<BrandAnalyticsQueryParams>({
        ...defaultBrandAnalyticsPaginationProps,
        sortKey: 'LABEL',
        companyId: companySearchParam,
        brandId: brandSearchParam,
        countryId: countrySearchParam,
        searchQuery: searchQuerySearchParam,
    });
    const [unitsPurchasedPaginationProps, setUnitsPurchasedPaginationProps] = useState<BrandAnalyticsQueryParams>({
        ...defaultBrandAnalyticsPaginationProps,
        sortKey: 'LABEL',
        companyId: companySearchParam,
        brandId: brandSearchParam,
        countryId: countrySearchParam,
        searchQuery: searchQuerySearchParam,
    });

    const {
        data: brandAnalyticsData,
        isFetching: brandAnalyticsIsLoading,
        error: brandAnalyticsError,
        refetch: refetchBrandAnalytics,
        isError: isBrandAnalyticsError,
    } = useGetMultipleBrandAnalyticsQuery({
        sortKey: brandAnalyticsPaginationProps?.sortKey,
        sortAsc: brandAnalyticsPaginationProps?.sortAsc,
        page: brandAnalyticsPaginationProps?.page,
        pageSize: brandAnalyticsPaginationProps?.pageSize,
        includeInactive: false,
        companyId: brandAnalyticsPaginationProps.companyId,
        brandId: brandAnalyticsPaginationProps.brandId,
        countryId: brandAnalyticsPaginationProps.countryId,
        searchQuery: brandAnalyticsPaginationProps.searchQuery,
        weekEnding: brandAnalyticsPaginationProps.weekEnding,
    });

    const {
        data: marketShareData,
        isFetching: marketShareIsLoading,
        error: marketShareError,
        refetch: refetchMarketShare,
        isError: isMarketShareError,
    } = useGetMarketShareDataQuery({
        sortKey: marketSharePaginationProps?.sortKey,
        pageSize: 5000,
        includeInactive: false,
        companyId: marketSharePaginationProps.companyId,
        brandId: marketSharePaginationProps.brandId,
        countryId: marketSharePaginationProps.countryId,
        searchQuery: marketSharePaginationProps.searchQuery,
    });

    const {
        data: unitsPurchasedData,
        isFetching: unitsPurchasedIsLoading,
        error: unitsPurchasedError,
        refetch: refetchUnitsPurchased,
        isError: isUnitsPurchasedError,
    } = useGetUnitsPurchasedDataQuery({
        sortKey: unitsPurchasedPaginationProps?.sortKey,
        pageSize: 5000,
        includeInactive: false,
        companyId: unitsPurchasedPaginationProps.companyId,
        brandId: unitsPurchasedPaginationProps.brandId,
        countryId: unitsPurchasedPaginationProps.countryId,
        searchQuery: unitsPurchasedPaginationProps.searchQuery,
    });

    const [downloadBrandAnalyticsDataCsv, { isLoading: isLoadingCsv, isError: isCsvError }] = useLazyDownloadBrandAnalyticsDataCsvQuery();

    const {
        data: brandAnalyticsWeeks,
        isLoading: isLoadingBrandAnalyticsWeeks,
        isError: isBrandAnalyticsWeeksError,
    } = useGetBrandAnalyticsWeeksQuery({
        companyId: brandAnalyticsPaginationProps.companyId,
        brandId: brandAnalyticsPaginationProps.brandId,
        countryId: brandAnalyticsPaginationProps.countryId,
        searchQuery: brandAnalyticsPaginationProps.searchQuery,
    });

    useFailedActionSnackbar('retrieve', 'brand analytics', isBrandAnalyticsError);
    useFailedActionSnackbar('retrieve', 'market share', isMarketShareError);
    useFailedActionSnackbar('retrieve', 'units purchased', isUnitsPurchasedError);
    useFailedActionSnackbar('retrieve', 'weeks', isBrandAnalyticsWeeksError);

    const isLoadingData = () => brandAnalyticsIsLoading || marketShareIsLoading || unitsPurchasedIsLoading;
    const canDownloadCsv = (brandAnalyticsData?.pageResults.length ?? 0) > 0;
    const [isFormDirty, setIsFormDirty] = useState(false);

    useFailedActionSnackbar('generate', 'CSV', isCsvError);

    useEffect(() => {
        if (isLoadingCsv || brandAnalyticsIsLoading || !brandAnalyticsData) {
            setIsFormDirty(true);
        } else if (brandAnalyticsData && !brandAnalyticsIsLoading) {
            if (brandAnalyticsData.totalQueryResults > 0) {
                setIsFormDirty(false);
            }
        }
    }, [brandAnalyticsData, brandAnalyticsIsLoading, isLoadingCsv]);

    useEffect(() => {
        let updatedSearchParams = {};
        if (company !== emptyGuid && company !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                company,
            };
        }

        if (brand !== emptyGuid && brand !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                brand,
            };
        }

        if (country !== emptyGuid && country !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                country,
            };
        }

        if (searchQuery !== emptyGuid && searchQuery !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                searchQuery,
            };
        }

        setSearchParams(createSearchParams(updatedSearchParams));
    }, [company, brand, country, searchQuery, setSearchParams]);

    const isBasePaginationPropsDifferent = (currentSearchParams: SearchQueryParams, incomingPaginationProps: PaginatedProps) => {
        const isDifferent =
            incomingPaginationProps.sortKey !== currentSearchParams.sortKey ||
            incomingPaginationProps.sortAsc !== currentSearchParams.sortAsc ||
            incomingPaginationProps.page !== currentSearchParams.page ||
            incomingPaginationProps.pageSize !== currentSearchParams.pageSize;
        return isDifferent;
    };

    const handleMarketDataPaginationChange = (paginationProps: PaginatedProps) => {
        if (isBasePaginationPropsDifferent(marketSharePaginationProps, paginationProps)) {
            setMarketSharePaginationProps({
                ...marketSharePaginationProps,
                ...paginationProps,
            });
        }
    };

    const handleUnitsPurchasedPaginationChange = (paginationProps: PaginatedProps) => {
        if (isBasePaginationPropsDifferent(unitsPurchasedPaginationProps, paginationProps)) {
            setUnitsPurchasedPaginationProps({
                ...unitsPurchasedPaginationProps,
                ...paginationProps,
            });
        }
    };

    const handleBrandAnalyticsPaginationChange = (paginationProps: PaginatedProps) => {
        if (isBasePaginationPropsDifferent(brandAnalyticsPaginationProps, paginationProps)) {
            setBrandAnalyticsPaginationProps({
                ...brandAnalyticsPaginationProps,
                ...paginationProps,
            });
        }
    };

    const handleCompanyChange = (company: WorkflowConfigDto | null) => {
        setIsFormDirty(true);
        if (company === null) {
            setCompany(emptyGuid);
        } else {
            setCompany(company.workflowId);
        }
    }

    const handleBrandChange = (selectedBrand: WorkflowConfigBrandDto | null) => {
        setIsFormDirty(true);
        if (selectedBrand === null) {
            setBrand(emptyGuid);
        } else {
            setBrand(selectedBrand.id);
        }
    }

    const handleCountryChange = (selectedCountry: WorkflowConfigCountryDto | null) => {
        setIsFormDirty(true);
        if (selectedCountry === null) {
            setCountry(emptyGuid);
        } else {
            setCountry(selectedCountry.countryId);
        }
    }

    const onSearch = () => {
        setBrandAnalyticsPaginationProps({
            ...brandAnalyticsPaginationProps,
            companyId: company,
            brandId: brand,
            countryId: country,
            searchQuery: searchQuery,
            weekEnding: weekEnding?.toDateString() ?? undefined,
        });

        setMarketSharePaginationProps({
            companyId: company,
            brandId: brand,
            countryId: country,
            searchQuery: searchQuery,
        });

        setUnitsPurchasedPaginationProps({
            companyId: company,
            brandId: brand,
            countryId: country,
            searchQuery: searchQuery,
        });
    };

    const searchButton = (
        <Grid item container xs={1} alignItems='center' marginTop={'22px'}>
            <Button variant='contained' color='secondary' onClick={onSearch} sx={{ width: 88 }} disabled={isLoadingData()}>
                {isLoadingData() ? <CircularProgress size={24} color='inherit' /> : 'Search'}
            </Button>
        </Grid>
    );

    const downloadCsv = () => {
        downloadBrandAnalyticsDataCsv({
            sortKey: brandAnalyticsPaginationProps?.sortKey,
            companyId: brandAnalyticsPaginationProps.companyId,
            brandId: brandAnalyticsPaginationProps.brandId,
            countryId: brandAnalyticsPaginationProps.countryId,
            searchQuery: brandAnalyticsPaginationProps.searchQuery,
            weekEnding: brandAnalyticsPaginationProps.weekEnding,
        })
            .then((results) => {
                if (results.data) {
                    fetch('data:text/csv;charset=UTF-8;base64,' + (results.data as any).contents)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const url = URL.createObjectURL(blob);
                            const fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.download = results.data.name;
                            fileLink.click();
                        });
                }
            })
            .catch(() => { });
    };

    const sharedFilterElements: any = (
        <>
            <Grid item xs={6}>
                <CompanyBrandCountrySelect
                    selectedCompanyId={company}
                    selectedBrandId={brand}
                    selectedCountryId={country}
                    setSelectedCompany={handleCompanyChange}
                    setSelectedBrand={handleBrandChange}
                    setSelectedCountry={handleCountryChange}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel>Search Query</FormLabel>
                    <OutlinedInput
                        sx={{ backgroundColor: '#FFFFFF' }}
                        value={searchQuery}
                        onChange={(e) => {
                            setIsFormDirty(true);
                            setSearchQuery(e.target.value);
                        }}
                    />
                </FormControl>
            </Grid>
        </>
    );

    const tabs: BrandAnalyticsTabProps[] = [
        {
            label: 'Charts',
            value: 'Charts',
            panel: (
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <FormSection backgroundColor={'white'}>
                            <Grid item container direction='row' spacing={2}>
                                {sharedFilterElements}
                                {searchButton}
                            </Grid>
                        </FormSection>
                    </Grid>
                    <MarketShareChart
                        initValues={marketShareData}
                        setPaginatedProps={handleMarketDataPaginationChange}
                        isLoading={marketShareIsLoading}
                        refetch={refetchMarketShare}
                        error={marketShareError}
                    />
                    <UnitsPurchasedChart
                        initValues={unitsPurchasedData}
                        setPaginatedProps={handleUnitsPurchasedPaginationChange}
                        isLoading={unitsPurchasedIsLoading}
                        refetch={refetchUnitsPurchased}
                        error={unitsPurchasedError}
                    />
                </Grid>
            ),
        },
        {
            label: 'Data',
            value: 'Data',
            panel: (
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <FormSection backgroundColor={'white'}>
                            <Grid item container direction='row' spacing={2} wrap='nowrap' alignItems='center'>
                                {sharedFilterElements}
                                <Grid item xs={2}>
                                    <FormLabel>Week Ending</FormLabel>
                                    <DatePicker
                                        value={weekEnding}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['clear']
                                            }
                                        }}
                                        onChange={(e: any) => {
                                            setIsFormDirty(true);
                                            setWeekEnding(e?.$d);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                disabled
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                sx={{ backgroundColor: '#FFFFFF' }}
                                            />
                                        )}
                                        shouldDisableDate={(day: any) => {
                                            const date: Date | undefined = day?.$d;
                                            if (!date) {
                                                return true;
                                            }
                                            return !brandAnalyticsWeeks?.some((endingDate) => new Date(endingDate).toUTCString() === date.toUTCString()) ?? true;
                                        }}
                                        shouldDisableYear={(year: any) => {
                                            const date: Date | undefined = year?.$d;
                                            if (!date) {
                                                return true;
                                            }

                                            return (
                                                !brandAnalyticsWeeks?.some((endingDate) => new Date(endingDate).getUTCFullYear() === date.getUTCFullYear()) ??
                                                true
                                            );
                                        }}
                                    />
                                </Grid>
                                {searchButton}
                                <Grid item xs={1} justifyContent='center'>
                                    {!isLoadingCsv ? (
                                        <IconButton
                                            disabled={!canDownloadCsv || isFormDirty}
                                            onClick={downloadCsv}
                                            color='secondary'
                                            sx={{ marginTop: '20px' }}>
                                            <Tooltip title='Download'>
                                                <DownloadForOffline fontSize='large' />
                                            </Tooltip>
                                        </IconButton>
                                    ) : (
                                        <LoadingIndicator />
                                    )}
                                </Grid>
                            </Grid>
                        </FormSection>
                    </Grid>
                    <Grid item sx={{ width: '100%', overflowX: 'auto' }}>
                        <BrandAnalyticsDataChart
                            initValues={brandAnalyticsData}
                            setPaginatedProps={handleBrandAnalyticsPaginationChange}
                            isLoading={brandAnalyticsIsLoading}
                            refetch={refetchBrandAnalytics}
                            error={brandAnalyticsError}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);

    if (isLoadingBrandAnalyticsWeeks) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <InsertChart /> Brand Analytics
                    </Typography>
                    <NavBreadcrumbs links={[{ label: 'Home', navLink: '/' }]} currentPageLabel='Brand Analytics' />
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: '100%' }}>
                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, v) => setCurrentTab(v)}>
                            {tabs.map((t) => (
                                <Tab key={`tab-${t.value}`} label={t.label} value={t.value} />
                            ))}
                        </TabList>
                    </Box>
                    {tabs.map((t) => (
                        <TabPanel
                            key={`tab-content-${t.value}`}
                            value={t.value}
                            sx={{
                                paddingX: 0,
                                paddingY: 2,
                            }}>
                            {t.panel}
                        </TabPanel>
                    ))}
                </TabContext>
            </Grid>
        </Grid>
    );
};
