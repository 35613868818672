import { FC, useEffect, useState } from 'react';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { AccountBox, AddCircle, Delete, MoreVert, Search } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { UserDto } from '../../dtos';
import { useArchiveUserMutation, useGetUsersQuery, useUnarchiveUserMutation } from '../../store/apis/user-api';
import { getErrorMessage } from '../../util/errorHelper';
import { ApiError, AuthenticatedComponent, DataTableColumn, LoadingIndicator, NavBreadcrumbs, PaginatedDataTable, PaginatedProps } from '../../Components/CoreLib/library';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const UsersListView: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>(defaultPaginationProps);
    const { data, isLoading, error, refetch } = useGetUsersQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<UserDto>[] = [
        { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
        { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
        { key: 'role', label: 'User Role', sortKey: 'ROLE' },
        { key: 'email', label: 'Email', sortKey: 'EMAIL' },
        { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
    ];
    const [archiveUser, { error: archiveError, isSuccess: archiveSuccess, reset: resetArchive }] = useArchiveUserMutation();
    const [unarchiveUser, { error: unarchiveError, isSuccess: unarchiveSuccess, reset: resetUnarchive }] = useUnarchiveUserMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

    useEffect(() => {
        if (archiveError && isUpdatingStatus && 'data' in archiveError) {
            enqueueSnackbar(getErrorMessage(archiveError), { variant: 'error' });
            resetArchive();
            setIsUpdatingStatus(false);
        } else if (archiveSuccess && isUpdatingStatus) {
            if (selected.length > 1) {
                enqueueSnackbar('Users inactivated');
            } else {
                enqueueSnackbar('User inactivated');
            }
            resetArchive();
            setIsUpdatingStatus(false);
            setSelected([]);
        }
        if (unarchiveError && isUpdatingStatus && 'data' in unarchiveError) {
            enqueueSnackbar(getErrorMessage(unarchiveError), { variant: 'error' });
        } else if (unarchiveSuccess && isUpdatingStatus) {
            if (selected.length > 1) {
                enqueueSnackbar('Users activated');
            } else {
                enqueueSnackbar('User activated');
            }
            resetUnarchive();
            setIsUpdatingStatus(false);
            setSelected([]);
        }
    }, [
        isUpdatingStatus,
        setIsUpdatingStatus,
        archiveError,
        archiveSuccess,
        resetArchive,
        unarchiveError,
        unarchiveSuccess,
        resetUnarchive,
        setSelected,
        selected,
        enqueueSnackbar,
    ]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        navigate('/user/create');
    };

    const handleMenuEdit = () => {
        const selectedUserId = data?.pageResults[selected[0]].id;
        if (selectedUserId) {
            navigate(`/user/${selectedUserId}`);
        }
    };

    const handleTableEdit = (id: string) => {
        navigate(`/user/${id}`);
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveUser(data?.pageResults[index].id!);
        });
    };

    const handleSetInactive = async () => {
        for (let i = 0; i < selected.length; i++) {
            setIsUpdatingStatus(true);
            await archiveUser(data?.pageResults[selected[i]].id!);
        }
    };

    const canSetActive = () => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => data?.pageResults[index].isActive);
    };

    const handleTableRowClick = (id: string) => {
        navigate(`/user/${id}`);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <AccountBox /> Users
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Admin', navLink: '', isText: true },
                        ]}
                        currentPageLabel='Users'
                    />
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:users']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive()}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:users']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <Grid item xs={1}>
                        <IconButton size='large' onClick={handleMenuOpen}>
                            <MoreVert fontSize='inherit' />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <AuthenticatedComponent
                                requiredPermissions={['create:users']}
                                component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                            />
                            <AuthenticatedComponent
                                requiredPermissions={['edit:users']}
                                component={
                                    <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                        Edit
                                    </MenuItem>
                                }
                            />
                            <AuthenticatedComponent
                                requiredPermissions={['delete:users']}
                                component={
                                    <>
                                        <Divider />
                                        <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>
                                            Make Active
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>
                                            Make Inactive
                                        </MenuItem>
                                    </>
                                }
                            />
                        </Menu>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='FIRST_NAME'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:users'] }}
                    onEdit={handleTableEdit}
                    onRowClick={handleTableRowClick}
                />
            </Grid>
        </Grid>
    );
};
