import { Summarize } from '@mui/icons-material';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { IFilterChip, PaginatedProps, StandardReadOnlyListView } from '../../Components/CoreLib/library';
import { AsinDto, SearchTermDto, WorkflowConfigBrandDto, WorkflowConfigDto } from '../../dtos';
import { useGetSearchResultsQuery } from '../../store/apis/search-result-api';
import { SearchResultFilterDialog } from './SearchResultFilterDialog';
import { AMAZON_SEARCH_RESULTS_COLUMNS, AMAZON_SEARCH_RESULTS_DEFAULT_SORT_KEY } from './types';

const defaultPaginationProps: PaginatedProps = {
    sortKey: AMAZON_SEARCH_RESULTS_DEFAULT_SORT_KEY,
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const CHIP_KEYS = {
    RunDate: 'Run Date',
    SearchTerm: 'Search Term',
    Asin: 'ASIN',
    Company: 'Company',
    Brand: 'Brand'
}

export const SearchResultsReportView: FC = () => {
    const [isSearchResultFilterVisible, setIsSearchResultFilterVisible] = useState(false);
    const [runDateFilter, setRunDateFilter] = useState<Date | null | undefined>();
    const [searchTermFilter, setSearchTermFilter] = useState<SearchTermDto | null | undefined>();
    const [asinFilter, setAsinFilter] = useState<AsinDto | null | undefined>();
    const [companyFilter, setCompanyFilter] = useState<WorkflowConfigDto | null | undefined>();
    const [brandFilter, setBrandFilter] = useState<WorkflowConfigBrandDto | null | undefined>();

    const handleOpenFilterDialog = () => {
        setIsSearchResultFilterVisible(true);
    }
    
    const handleCloseFilterDialog = () => {
        setIsSearchResultFilterVisible(false);
    }
    
    const handleRemoveFilterChip = (filterChipKey: string) => {
        switch (filterChipKey) {
            case CHIP_KEYS.RunDate:
                setRunDateFilter(null);
                break;
            case CHIP_KEYS.SearchTerm:
                setSearchTermFilter(null);
                break;
            case CHIP_KEYS.Asin:
                setAsinFilter(null);
                break;
            case CHIP_KEYS.Company:
                setCompanyFilter(null);
                break;
            case CHIP_KEYS.Brand:
                setBrandFilter(null);
                break;
            default:
                console.warn('invalid filter chip key provided')
                break;
        }
    }

    const getFilterChips = () => {
        const filterChips: IFilterChip[] = [];
        if (runDateFilter) {
            filterChips.push({
                key: CHIP_KEYS.RunDate,
                text: `${format(new Date(runDateFilter.toString()), 'M/d/yyyy')}`
            })
        }
        if (searchTermFilter) {
            filterChips.push({
                key: CHIP_KEYS.SearchTerm,
                text: searchTermFilter.searchQuery
            })
        }
        if (asinFilter) {
            filterChips.push({
                key: CHIP_KEYS.Asin,
                text: asinFilter.ecommerceAsin
            })
        }
        if (companyFilter) {
            filterChips.push({
                key: CHIP_KEYS.Company,
                text: companyFilter.companyName
            })
        }
        if (brandFilter) {
            filterChips.push({
                key: CHIP_KEYS.Brand,
                text: brandFilter.brandName
            })
        }
        return filterChips;
    }

    const getFilterQueryParams = () => {
        var queryParams: any = {};
        if (runDateFilter) {
            queryParams.runDate = runDateFilter.toISOString();
        }
        if (searchTermFilter) {
            queryParams.searchTermId = searchTermFilter.id
        }
        if (asinFilter) {
            queryParams.asin = asinFilter.ecommerceAsin
        }
        if (companyFilter) {
            queryParams.workflowId = companyFilter.workflowId
        }
        if (brandFilter) {
            queryParams.brandId = brandFilter.id
        }
        return queryParams;
    }

    const isOwnAsin = (row: any) => {
        if (row.isOwnAsin) {
            return {
                backgroundColor: '#FEF7EF',
            };
        }
        return {};
    };

    return (
        <>
            <StandardReadOnlyListView
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetSearchResultsQuery}
                tableColumns={AMAZON_SEARCH_RESULTS_COLUMNS}
                entityNamePlural='Search Results'
                headerTitle='Search Results'
                headerIcon={<Summarize />}
                breadCrumbs={[{ label: 'Home', navLink: '/' }]}
                handleFilterClicked={handleOpenFilterDialog}
                filterChips={getFilterChips()}
                handleFilterChipDelete={handleRemoveFilterChip}
                additionalQueryParameters={getFilterQueryParams()}
                showCompactToggle
                customRowStyling={isOwnAsin}
            />
            <SearchResultFilterDialog
                isVisible={isSearchResultFilterVisible}
                onClose={handleCloseFilterDialog}
                runDateFilter={runDateFilter}
                setRunDateFilter={setRunDateFilter}
                searchTermFilter={searchTermFilter}
                setSearchTermFilter={setSearchTermFilter}
                asinFilter={asinFilter}
                setAsinFilter={setAsinFilter}
                companyFilter={companyFilter}
                setCompanyFilter={setCompanyFilter}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
            />
        </>
    );
}