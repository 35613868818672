import { useAuth0 } from '@auth0/auth0-react';
import { Home, ShoppingCart, Search, Tune, InsertChart, Summarize, Lightbulb } from '@mui/icons-material';
import { FC, PropsWithChildren, useEffect } from 'react';
import { LoadingIndicator } from '../CoreLib/library';
import NavigationDrawer from '../NavigationDrawer';
import { useLazyGetUserByAuth0IdQuery } from '../../store/apis/user-api';
import { NavigationDrawerRouteDTO } from './types';

export const NavigationDrawerContainer: FC<PropsWithChildren> = (props) => {
    const { user, isLoading, error } = useAuth0();
    const [getUserData] = useLazyGetUserByAuth0IdQuery();

    useEffect(() => {
        if (user?.sub) {
            getUserData(user?.sub);
        }
    }, [user, getUserData]);

    const publicRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Home />,
            name: 'Home',
            route: '/',
        }
    ];

    const reportRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <InsertChart />,
            name: 'Brand Analytics',
            route: '/reports/brand-analytics',
            requiredPermissions: ['read:brandAnalytics'],
        },
        {
            icon: <InsertChart />,
            name: 'Marketing Funnel',
            route: '/reports/marketing-funnel',
            requiredPermissions: ['read:brandAnalytics'],
        },
        {
            icon: <Summarize />,
            name: 'Search Results',
            route: '/reports/search-results',
            requiredPermissions: ['read:reports'],
        },
        {
            icon: <Summarize />,
            name: 'Shelf Space',
            route: '/reports/shelf-space',
            requiredPermissions: ['read:reports'],
        },
    ];

    const adminRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <ShoppingCart />,
            name: 'ASINs',
            route: '/asins',
            requiredPermissions: ['read:asins'],
        },
        {
            icon: <Search />,
            name: 'Search Terms',
            route: '/search-terms',
            requiredPermissions: ['read:searchTerms'],
        },
        {
            icon: <Tune />,
            name: 'Configuration',
            route: '/client/edit',
            requiredPermissions: ['read:workflowConfig', 'read:countries'],
        },
        {
            icon: <Lightbulb />,
            name: 'RoboSource Support',
            route: '/support',
            requiredPermissions: ['read:support'],
        },
    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }
    return (
        <div>
            <NavigationDrawer routes={publicRoutes} adminRoutes={adminRoutes} reportRoutes={reportRoutes}>
                {props.children}
            </NavigationDrawer>
        </div>
    );
};
