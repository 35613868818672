import { serverApi, ShelfSpaceQueryParams } from '..';
import { QueryResultsDto, ShelfSpaceDto } from '../../dtos';
import { buildSearchQueryRoute } from '../../util';

const shelfSpaceApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            getShelfSpaces: builder.query<QueryResultsDto<ShelfSpaceDto>, ShelfSpaceQueryParams>({
                query: (params) => buildSearchQueryRoute('Reports/ShelfSpace', params),
                providesTags: [{ type: 'ShelfSpace' }],
            }),
        };
    },
});

export const { useGetShelfSpacesQuery } = shelfSpaceApi;
