import { FC } from 'react';
import { DataTableColumn, IDataTableColumnGroup, LoadingIndicator, ApiError, PaginatedDataTable } from '../../Components/CoreLib/library';
import { BrandAnalyticsDto } from '../../dtos';
import { BrandAnalyticsChartProps, getDateString, getPercentString } from './types';

export const BrandAnalyticsDataChart: FC<BrandAnalyticsChartProps<BrandAnalyticsDto>> = (props) => {
    const { initValues, setPaginatedProps, isLoading, error, refetch } = props;

    const tableColumns: DataTableColumn<BrandAnalyticsDto>[] = [
        { key: 'rank', label: 'Rank', sortKey: 'RANK', align: 'right', unsorted: false, },
        { key: 'searchTerm.companyName', label: 'Company Name', sortKey: 'COMPANY', unsorted: false, align: 'left', },
        { key: 'brandName', label: 'Brand Name', sortKey: 'BRAND_NAME', unsorted: false, align: 'left', },
        { key: 'countryName', label: 'Country', sortKey: 'COUNTRY_NAME', unsorted: false, align: 'left', },
        { key: 'searchTerm.searchQuery', label: 'Search Query', sortKey: 'SEARCH_QUERY', align: 'left', unsorted: false },
        { key: 'weekEndingOn', label: 'Week Ending On', sortKey: 'WEEK_ENDING_ON', align: 'left', unsorted: false, keyFieldFormatter: getDateString },
        { key: 'searchQueryVolume', label: 'Search Query Volume', sortKey: 'SEARCH_QUERY_VOLUME', align: 'right', unsorted: false },
        { key: 'totalCountImpressions', label: 'Total Count', sortKey: 'TOTAL_COUNT_IMPRESSIONS', align: 'left', unsorted: false },
        { key: 'brandCountImpressions', label: 'Brand Count', sortKey: 'BRAND_COUNT_IMPRESSIONS', align: 'left', unsorted: false },
        { key: 'brandShareImpressions', label: 'Brand Share', sortKey: 'BRAND_SHARE_IMPRESSIONS', align: 'left', unsorted: false, keyFieldFormatter: getPercentString },
        { key: 'totalCountClicks', label: 'Total Count', sortKey: 'TOTAL_COUNT_CLICKS', align: 'left', unsorted: false },
        { key: 'brandCountClicks', label: 'Brand Count', sortKey: 'BRAND_COUNT_CLICKS', align: 'left', unsorted: false },
        { key: 'brandShareClicks', label: 'Brand Share', sortKey: 'BRAND_SHARE_CLICKS', align: 'left', unsorted: false, keyFieldFormatter: getPercentString },
        { key: 'sameDayShippingSpeedClicks', label: 'Same Day Shipping Speed', sortKey: 'SAME_DAY_SHIPPING_SPEED_CLICKS', align: 'left', unsorted: false },
        { key: 'totalCountCartAdds', label: 'Total Count', sortKey: 'TOTAL_COUNT_CART_ADDS', align: 'left', unsorted: false },
        { key: 'brandCountCartAdds', label: 'Brand Count', sortKey: 'BRAND_COUNT_CART_ADDS', align: 'left', unsorted: false },
        { key: 'brandShareCartAdds', label: 'Brand Share', sortKey: 'BRAND_SHARE_CART_ADDS', align: 'left', unsorted: false, keyFieldFormatter: getPercentString },
        { key: 'sameDayShippingSpeedCartAdds', label: 'Same Day Shipping Speed', sortKey: 'SAME_DAY_SHIPPING_SPEED_CART_ADDS', align: 'left', unsorted: false },
        { key: 'totalCountPurchases', label: 'Total Count', sortKey: 'TOTAL_COUNT_PURCHASES', align: 'left', unsorted: false },
        { key: 'brandCountPurchases', label: 'Brand Count', sortKey: 'BRAND_COUNT_PURCHASES', align: 'left', unsorted: false },
        { key: 'brandSharePurchases', label: 'Brand Share', sortKey: 'BRAND_SHARE_PURCHASES', align: 'left', unsorted: false, keyFieldFormatter: getPercentString },
        { key: 'sameDayShippingSpeedPurchases', label: 'Same Day Shipping Speed', sortKey: 'SAME_DAY_SHIPPING_SPEED_PURCHASES', align: 'left', unsorted: false },
    ];

    const columnGroupStyles = { backgroundColor: '#2A3BA3', textColor: 'white' }

    const columnGroups: IDataTableColumnGroup[] = [
        { label: '', colSpan: 7 },
        { label: 'Impressions', colSpan: 3, ...columnGroupStyles },
        { label: 'Clicks', colSpan: 4, ...columnGroupStyles },
        { label: 'Cart Adds', colSpan: 4, ...columnGroupStyles },
        { label: 'Purchases', colSpan: 4, ...columnGroupStyles },
    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (error) {
        <ApiError onReloadClick={refetch} />;
    }
    return (
        <PaginatedDataTable
            columns={tableColumns}
            columnGroups={columnGroups}
            loading={false}
            queryResults={initValues}
            defaultSortKey='RANK'
            setPagination={setPaginatedProps}
            isDashboardStyle={false}
            isInactiveStyleApplied={false}
            isViewOnly={true}
        />
    );
};
