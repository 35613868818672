import { FilterList } from "@mui/icons-material";
import { Autocomplete, Button, debounce, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { CompanyBrandCountrySelect } from "../../Components/Forms/CustomFormSections";
import { SearchTermDto, WorkflowConfigBrandDto, WorkflowConfigDto } from "../../dtos";
import { useGetSearchTermsQuery } from "../../store/apis/search-term-api";
import { emptyGuid } from "../../util";
import { ShelfSpaceFilterProps } from "./types";

export const ShelfSpaceFilterDialog: FC<ShelfSpaceFilterProps> = (props) => {
    const {
        isVisible,
        onClose,
        searchTermFilter,
        setSearchTermFilter,
        companyFilter,
        setCompanyFilter,
        brandFilter,
        setBrandFilter
    } = props;
    const [selectedSearchTerm, setSelectedSearchTerm] = useState<SearchTermDto | null>(searchTermFilter ?? null);
    const [searchTermSearchText, setSearchTermSearchText] = useState('');
    const [searchTermQueryText, setSearchTermQueryText] = useState('');
    const [selectedCompany, setSelectedCompany] = useState<WorkflowConfigDto | null>(companyFilter ?? null);
    const [selectedBrand, setSelectedBrand] = useState<WorkflowConfigBrandDto | null>(brandFilter ?? null);
    const { data: searchTerms, isLoading: isLoadingSearchTerms, isFetching: isFetchingSearchTerms, isError: isErrorGettingSearchTerms } = useGetSearchTermsQuery({
        searchText: searchTermQueryText,
        sortKey: 'SEARCH_QUERY',
        page: 0,
        pageSize: 50,
        includeInactive: false
    });

    useFailedActionSnackbar('retrieving', 'search terms', isErrorGettingSearchTerms);

    useEffect(() => {
        setSelectedSearchTerm(searchTermFilter ?? null);
        setSelectedCompany(companyFilter ?? null);
        setSelectedBrand(brandFilter ?? null);
    }, [searchTermFilter, companyFilter, brandFilter]);

    const handleClose = () => {
        setSearchTermSearchText('');
        onClose();
    };

    const handleFilter = () => {
        setSearchTermFilter(selectedSearchTerm);
        setCompanyFilter(selectedCompany);
        setBrandFilter(selectedBrand);
        handleClose();
    };

    const handleClear = () => {
        setSelectedSearchTerm(null);
        setSelectedCompany(null);
        setSelectedBrand(null);
    };

    const handleSelectSearchTerm = (_: any, newTerm: SearchTermDto | null) => {
        if (newTerm) {
            setSelectedSearchTerm(newTerm);
        } else {
            setSelectedSearchTerm(null);
        }
    }

    // eslint-disable-next-line
    const debouncedSearchTermSearchInputHandler = useCallback(debounce(setSearchTermQueryText, 500), []);

    useEffect(() => {
        debouncedSearchTermSearchInputHandler(searchTermSearchText)
    }, [searchTermSearchText, debouncedSearchTermSearchInputHandler])

    return (
        <Dialog
            open={isVisible}
            onClose={handleClose}
            PaperProps={{ sx: { width: '100%' } }}
        >
            <DialogTitle>
                <Typography variant='h2'>
                    {<FilterList />} {'Filter Report'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' pt={3} spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth disabled={isLoadingSearchTerms}>
                            <FormLabel>Search Term</FormLabel>
                            <Autocomplete
                                loading={isFetchingSearchTerms || searchTermSearchText !== searchTermQueryText}
                                value={selectedSearchTerm}
                                onChange={handleSelectSearchTerm}
                                options={searchTerms?.pageResults ?? []}
                                getOptionLabel={(option) => option.searchQuery}
                                isOptionEqualToValue={(option, value) => option.searchQuery === value.searchQuery}
                                renderInput={(params) => <TextField {...params} />}
                                inputValue={searchTermSearchText}
                                onInputChange={(_, newInputValue) => {
                                    setSearchTermSearchText(newInputValue);
                                }}
                            ></Autocomplete>
                        </FormControl>
                    </Grid>
                    <Grid item container direction='row'>
                        <Grid item xs={12}>
                            <CompanyBrandCountrySelect
                                selectedCompanyId={selectedCompany?.workflowId ?? emptyGuid}
                                selectedBrandId={selectedBrand?.id ?? emptyGuid}
                                setSelectedCompany={setSelectedCompany}
                                setSelectedBrand={setSelectedBrand}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClear} size='large'>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog >
    );
};