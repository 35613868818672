import { Dashboard, InsertChart } from '@mui/icons-material';
import { Card, CardContent, Grid, Link, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { useGetCurrentUsersCompanyBrandSummaryQuery } from '../../store/apis/workflow-config-api';
import { useGetCurrentUsersWorkflowConfigClientQuery } from '../../store/apis/workfow-config-client-api';
import { useNavigate } from 'react-router';
import { CompanyBrandSummaryDto } from '../../dtos';
import { useFailedActionSnackbar, LoadingIndicator, ApiError, AuthenticatedComponent } from '../../Components/CoreLib/library';

export const Home: FC = () => {
    const { data: client, isLoading: isLoadingClient, isError: isErrorLoadingClient, refetch: refetchClient } = useGetCurrentUsersWorkflowConfigClientQuery();
    const {
        data: companyBrandSummaries,
        isLoading: isLoadingSummaries,
        isError: isErrorLoadingSummaries,
        refetch: refetchSummaries,
    } = useGetCurrentUsersCompanyBrandSummaryQuery();
    const isLoading = isLoadingClient || isLoadingSummaries;
    const isError = isErrorLoadingClient || isErrorLoadingSummaries;
    const navigate = useNavigate();

    useFailedActionSnackbar('retrieving', 'client information', isErrorLoadingClient);
    useFailedActionSnackbar('retrieving', 'company and brand information', isErrorLoadingSummaries);

    const refetchAll = () => {
        refetchClient();
        refetchSummaries();
    };

    const generateAsinAndSearchTermQueryString = (summary: CompanyBrandSummaryDto) => {
        return `workflowId=${summary.workflowId}&companyName=${summary.companyName}&brandId=${summary.workflowConfigBrandId}&brandName=${summary.brandName}`;
    };

    const generateBrandAnalyticsQueryString = (summary: CompanyBrandSummaryDto) => {
        return `company=${summary.workflowId}&brand=${summary.workflowConfigBrandId}`;
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (isError) {
        return <ApiError onReloadClick={refetchAll} />;
    }

    return (
        <Grid container direction='column'>
            <Grid item container direction='column' justifyContent='start' xs={6}>
                <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                    <Dashboard /> {client?.clientName}
                </Typography>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                {companyBrandSummaries?.map((summary, index) => {
                    const asinAndSearchTermQueryString = generateAsinAndSearchTermQueryString(summary);
                    const brandAnalyticsQueryString = generateBrandAnalyticsQueryString(summary);
                    return (
                        <Grid item xs={12} sm={6} md={3} key={`summary-${index}`}>
                            <Card>
                                <CardContent style={{ paddingBottom: '0px' }}>
                                    <Tooltip title={summary.companyName} followCursor>
                                        <Typography fontWeight='bold' noWrap>
                                            {summary.companyName}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={summary.brandName} followCursor>
                                        <Typography variant='h6' fontWeight='bold' mb={2} noWrap>
                                            {summary.brandName}
                                        </Typography>
                                    </Tooltip>
                                    <AuthenticatedComponent
                                        requiredPermissions={['read:asins']}
                                        component={
                                            <Link href='#' onClick={() => navigate(`/asins?${asinAndSearchTermQueryString}`)} underline='hover'>
                                                <Typography>{summary.asinCount.toLocaleString()} ASINs</Typography>
                                            </Link>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['read:searchTerms']}
                                        component={
                                            <Link href='#' onClick={() => navigate(`/search-terms?${asinAndSearchTermQueryString}`)} underline='hover'>
                                                <Typography>{summary.searchTermCount.toLocaleString()} Search Terms</Typography>
                                            </Link>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['read:brandAnalytics']}
                                        component={
                                            <Link href='#' onClick={() => navigate(`/reports/brand-analytics?${brandAnalyticsQueryString}`)} underline='hover'>
                                                <InsertChart sx={{ marginTop: 1, marginLeft: '-4px' }} fontSize='large' color='primary' />
                                            </Link>
                                        }
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};
