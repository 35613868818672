import { useAuth0 } from '@auth0/auth0-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { publicRoutes, routes } from './routing/Routes';
import jwt from 'jsonwebtoken';
import Shell from './Components/Shell/Shell';
import { IRoute } from './routing/types';
import { useAppDispatch, setToken } from './store';
import theme from './Theme';
import { LoadingIndicator, NotAuthorizedError, userHasPermissions, UserPermissionContext } from './Components/CoreLib/library';

function App() {
    const auth = useAuth0();
    const dispatch = useAppDispatch();
    const [accessTokenSet, setAccessTokenSet] = useState(false);
    const [userPermissions, setUserPermissions] = useState<string[] | null>(null);

    useEffect(() => {
        (async () => {
            if (auth.isAuthenticated) {
                if (!userPermissions) {
                    const accessToken = await auth.getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
                    const decodedToken = jwt.decode(accessToken);
                    const permissions = ((decodedToken as any)?.permissions as string[]) ?? [];
                    
                    setUserPermissions(permissions);
                    dispatch(setToken(accessToken));
                    setAccessTokenSet(true);
                }
            }
        })();
    }, [userPermissions, setUserPermissions, auth, dispatch]);

    if (auth.isLoading || (auth.isAuthenticated && !accessTokenSet)) {
        return <LoadingIndicator />;
    }

    if (auth.error) {
        return <p>{auth.error.message}</p>;
    }

    const renderRoute = (route: IRoute) => {
        if (userPermissions && userHasPermissions(route.requiredPermissions ?? [], userPermissions)) {
            return <Route key={route.path} path={route.path} element={route.component} />;
        } else {
            return <Route key={route.path} path={route.path} element={<NotAuthorizedError />} />;
        }
    };

    const togglePermission = (permission: string ): void => {
        if (!userPermissions) {
            return;
        }
        const isPermissionActive = userPermissions.includes(permission);
        if (isPermissionActive) {
            setUserPermissions([...userPermissions.filter(p => p !== permission)]);
        } else {
            setUserPermissions([...userPermissions, permission]);
        }
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <SnackbarProvider maxSnack={3}>
                            <UserPermissionContext.Provider value={{
                                permissions: userPermissions ?? [],
                                togglePermission: togglePermission
                            }}>
                                <Routes>
                                    <Route path='/' element={<Shell isProtected={true} />}>
                                        {routes.map(renderRoute)}
                                    </Route>
                                    <Route path='/' element={<Shell />}>
                                        {publicRoutes.map(renderRoute)}
                                    </Route>
                                </Routes>
                            </UserPermissionContext.Provider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </CssBaseline>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
