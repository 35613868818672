import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Divider, Grid, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import logo from '../../../src/logoHorizontal.png';
import { NavigationDrawerContainer } from '../NavigationDrawerContainer.tsx/NavigationDrawerContainer';
import { useLazyGetAllAvailablePermissionsQuery, useLazyGetUserByAuth0IdQuery } from '../../store/apis/user-api';
import { AuthenticatedComponent, GeneratedAvatar, LoadingIndicator, PermissionToggler } from '../CoreLib/library';
import { IShellProps } from './types';


const Shell: FC<IShellProps> = (props) => {
    const { isProtected } = props;
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading: isAuthLoading } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [getUserData] = useLazyGetUserByAuth0IdQuery();

    useEffect(() => {
        if (isAuthenticated) {
            if (user?.sub) {
                getUserData(user?.sub);
            }
        }
    }, [user, getUserData, isAuthenticated]);

    if (isProtected && !isAuthenticated) {
        loginWithRedirect();
        return <LoadingIndicator />;
    }

    const handleLogout = () => {
        setAnchorEl(null);
        logout({ returnTo: window.location.origin });
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <NavigationDrawerContainer>
                <AppBar position='sticky' elevation={0} sx={{ height: '72px' }}>
                    <Toolbar variant='dense'>
                        <Grid item sx={{ paddingY: 8, marginX: 0, paddingX: 0 }}>
                            <a href='/'>
                                <img src={logo} alt='First Page Logo' height={'100%'} width={'244'} />
                            </a>
                        </Grid>
                        {isAuthenticated && (
                            <Grid container direction='row' justifyContent='end'>
                                <div style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                    <GeneratedAvatar name={user?.name} />
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={profileMenuOpen}
                                    onClose={handleProfileMenuClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    PaperProps={{
                                        sx: {
                                            mt: 1.5,
                                            border: 'solid 1px #AAAAAA',
                                            borderRadius: '10px',
                                            padding: '8px',
                                        },
                                    }}>
                                    <Grid container direction='column' alignItems='center' sx={{ width: '240px' }}>
                                        <GeneratedAvatar name={user?.name} />
                                        <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>{user?.name}</Typography>
                                        <Typography sx={{ fontSize: '14px', marginBottom: '12px' }}>{user?.email}</Typography>
                                    </Grid>
                                    <Divider sx={{ marginBottom: '12px' }} />
                                    <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                        Sign Out
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
                <div style={{ padding: '24px', minHeight: 'calc(100vh - 72px)' }}>{isAuthLoading || !isAuthenticated ? <LoadingIndicator /> : <Outlet />}</div>
            </NavigationDrawerContainer>
            <AuthenticatedComponent
                requiredPermissions={['read:permissionToggle']}
                component={<PermissionToggler getPermissionsQuery={useLazyGetAllAvailablePermissionsQuery} />}
            />
        </>
    );
};

export default Shell;
