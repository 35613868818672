import { RunInterval, WorkflowConfigDto } from "../../../dtos";
import { emptyGuid } from "../../../util/constants";

export const DEFAULT_WORKFLOW_CONFIG: WorkflowConfigDto = {
    id: emptyGuid,
    isActive: true,
    workflowId: emptyGuid,
    createdOn: new Date(),
    clientName: "",
    runInterval: RunInterval.None,
    freeMonthlyCredit: 0,
    perSearchFee: 0,
    workflowConfigCountries: [],
    companyName: "",
    searchTermCount: 0,
    countryCount: 0,
    runsPerMonth: 0,
    searchesPerMonth: 0,
    billableSearchesPerMonth: 0,
    monthlyEstimatedCost: 0
};