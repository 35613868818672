import { QueryResultsDto } from '../../dtos';
import { MarketingFunnelDto } from '../../dtos/generated/MarketingFunnelDto';
import { MarketingFunnelSearchRequestDto } from '../../dtos/generated/MarketingFunnelSearchRequestDto';
import { serverApi } from '../api';

const marketingFunnelApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getMarketingFunnel: builder.query<QueryResultsDto<MarketingFunnelDto>, MarketingFunnelSearchRequestDto>({
            query: (dto) => ({
                url: 'marketingFunnel',
                method: 'POST',
                body: dto,
            }),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
        // this endpoint says it returns a string but it should be a date string. Dates always come back to us a string between the front-end and back-end
        getMostRecentWeekEndingDate: builder.query<string, void>({
            query: () => ({
                url: 'marketingFunnel/latestWeekEnding',
                method: 'GET'
            }),
            providesTags: [],
        }),
        downloadMarketingFunnelDataCsv: builder.query<any, MarketingFunnelSearchRequestDto>({
            query: (dto) => ({
                url: 'marketingFunnel/download',
                method: 'POST',
                body: dto,
            }),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
    }),
});

export const {
    useGetMarketingFunnelQuery,
    useLazyGetMarketingFunnelQuery,
    useDownloadMarketingFunnelDataCsvQuery,
    useLazyDownloadMarketingFunnelDataCsvQuery,
    useGetMostRecentWeekEndingDateQuery
} = marketingFunnelApi;
