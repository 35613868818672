import { Warning } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

export function useConfirmationModal<T = void>(actionToConfirm: (args: T) => void, message: string) {
    const [isVisible, setIsVisible] = useState(false);
    const [actionArguments, setActionArguments] = useState<T>();
    
    const close = useCallback(() => {
        setIsVisible(false);
        setActionArguments(undefined);
    }, []);
    
    const confirmAction = useCallback((args: T) => {
        setIsVisible(true);
        setActionArguments(args);
    }, []);

    const handleConfirmAction = useCallback(() => {
        actionToConfirm(actionArguments!);
        close();
    }, [actionToConfirm, close, actionArguments]);

    const renderConfirmationModal = useMemo(() => {
        return (
            <Dialog open={isVisible} onClose={close} PaperProps={{ sx: { width: '100%' } }}>
                <DialogTitle>
                    <Typography variant='h2'>
                        {<Warning />} Are You Sure?
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ marginTop: 3 }}>
                    <Typography>{message}</Typography>
                </DialogContent>
                <DialogActions sx={{ py: 2 }}>
                    <Grid container direction='row' justifyContent='end'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={close}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' size='medium' onClick={handleConfirmAction} sx={{ color: 'error', backgroundColor: 'primary' }}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }, [message, handleConfirmAction, close, isVisible])

    return {
        confirmAction,
        renderConfirmationModal
    }
}