import { DownloadForOffline, InsertChart } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useFailedActionSnackbar, IFilterChip, LoadingIndicator, StandardReadOnlyListView } from '../../Components/CoreLib/library';
import { WorkflowConfigDto, WorkflowConfigBrandDto, WorkflowConfigCountryDto } from '../../dtos';
import { MarketingFunnelSearchRequestDto } from '../../dtos/generated/MarketingFunnelSearchRequestDto';
import { NumericalComparatorDto } from '../../dtos/generated/NumericalComparatorDto';
import { useGetCountriesQuery } from '../../store/apis/country-api';
import { useLazyDownloadMarketingFunnelDataCsvQuery, useGetMarketingFunnelQuery } from '../../store/apis/marketing-funnel-api';
import { MarketingFunnelFilterDialog } from './MarketingFunnelFilterDialog';
import { MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS, MARKETING_FUNNEL_SUPPORTED_COMPARISONS, MARKETING_FUNNEL_RESULTS_COLUMNS, MARKETING_FUNNEL_COLUMN_GROUPS } from './types';
import { useConfirmationModal } from '../../util/CustomHooks/useConfirmationModal';

const defaultMarketingFunnelPaginationProps: MarketingFunnelSearchRequestDto = {
    sortKey: 'RANK',
    sortAsc: true,
    page: 0,
    pageSize: 25
};

const CHIP_KEYS = {
    WeekEnding: 'Week Ending',
    SearchQuery: 'Search Query',
    Company: 'Company',
    Brand: 'Brand',
    Country: 'Country'
}

export interface IMarketingFunnelDataTableProps {
    defaultWeekEndingFilter?: Date;
}

export const MarketingFunnelDataTable: FC<IMarketingFunnelDataTableProps> = props => {
    const { defaultWeekEndingFilter } = props;
    const [isSearchResultFilterVisible, setIsSearchResultFilterVisible] = useState(false);
    const [weekEndingFilter, setWeekEndingFilter] = useState<Date | null | undefined>(defaultWeekEndingFilter);
    const [searchQueryFilter, setSearchQueryFilter] = useState('');
    const [companyFilter, setCompanyFilter] = useState<WorkflowConfigDto | null | undefined>();
    const [brandFilter, setBrandFilter] = useState<WorkflowConfigBrandDto | null | undefined>();
    const [countryFilter, setCountryFilter] = useState<WorkflowConfigCountryDto | null | undefined>();
    const [advancedFilters, setAdvancedFilters] = useState<NumericalComparatorDto[] | null>([]);
    const {
        data: countryData,
        isLoading: isLoadingCountries,
        isError: isCountryError
    } = useGetCountriesQuery({
        searchText: '',
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });
    useFailedActionSnackbar('retrieving', 'countries', isCountryError);

    const [downloadMarketingFunnelDataCsv, { isLoading: isLoadingCsv, isError: isCsvError }] = useLazyDownloadMarketingFunnelDataCsvQuery();
    useFailedActionSnackbar('generate', 'CSV', isCsvError);

    const removeFilterChip = (filterChipKey: string) => {
        if (!filterChipKey.startsWith('Comparison:')) {
            switch (filterChipKey) {
                case CHIP_KEYS.WeekEnding:
                    setWeekEndingFilter(null);
                    break;
                case CHIP_KEYS.SearchQuery:
                    setSearchQueryFilter('');
                    break;
                case CHIP_KEYS.Company:
                    setCompanyFilter(null);
                    break;
                case CHIP_KEYS.Brand:
                    setBrandFilter(null);
                    break;
                case CHIP_KEYS.Country:
                    setCountryFilter(null);
                    break;
            }
        } else {
            const trimmedKey = filterChipKey.replace('Comparison: ', '').trim();
            const [label, number] = trimmedKey.split(/ *[<>=]+ */);
            const columnName = MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS.find(c => c.label === label)?.key ?? '';
            setAdvancedFilters([
                ...advancedFilters?.filter(f => f.columnName !== columnName || `${f.number}` !== number) ?? []
            ]);
        }
    }
    const { confirmAction, renderConfirmationModal } = useConfirmationModal<string>(removeFilterChip, 'This is the last remaining search filter and loading these results with no filters could cause long loading times. Are you sure you would like to reload these results with no filters?');

    const handleOpenFilterDialog = () => {
        setIsSearchResultFilterVisible(true);
    }

    const handleCloseFilterDialog = () => {
        setIsSearchResultFilterVisible(false);
    }

    const handleRemoveFilterChip = (filterChipKey: string) => {
        var currentFilterChips = getFilterChips() ?? [];
        var isRemovingLastFilterChip = currentFilterChips.length === 1;
        if (isRemovingLastFilterChip) {
            confirmAction(filterChipKey);
        } else {
            removeFilterChip(filterChipKey);
        }
    }

    const getFilterChips = () => {
        const filterChips: IFilterChip[] = [];
        if (weekEndingFilter) {
            filterChips.push({
                key: CHIP_KEYS.WeekEnding,
                text: `${format(new Date(weekEndingFilter.toString()), 'M/d/yyyy')}`
            })
        }
        if (searchQueryFilter) {
            filterChips.push({
                key: CHIP_KEYS.SearchQuery,
                text: searchQueryFilter
            })
        }
        if (companyFilter) {
            filterChips.push({
                key: CHIP_KEYS.Company,
                text: companyFilter.companyName
            })
        }
        if (brandFilter) {
            filterChips.push({
                key: CHIP_KEYS.Brand,
                text: brandFilter.brandName
            })
        }
        if (countryFilter) {
            if (isLoadingCountries) {
                return;
            }

            filterChips.push({
                key: CHIP_KEYS.Country,
                text: countryData?.pageResults.find(country => country.id === countryFilter.countryId)?.name ?? ''
            })
        }

        advancedFilters?.forEach(filter => {
            const displayName = MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS.find(f => f.key === filter.columnName)?.label ?? '';
            const comparison = MARKETING_FUNNEL_SUPPORTED_COMPARISONS.find(c => c.key === filter.comparison)?.operator ?? '';
            filterChips.push({
                key: `Comparison: ${displayName} ${comparison} ${filter.number}`,
                text: '',
                separator: ''
            })
        });

        return filterChips;
    }

    const downloadCsv = () => {
        downloadMarketingFunnelDataCsv(getFilterQueryParams())
            .then((results) => {
                if (results.data) {
                    fetch('data:text/csv;charset=UTF-8;base64,' + (results.data as any).contents)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const url = URL.createObjectURL(blob);
                            const fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.download = results.data.name;
                            fileLink.click();
                        });
                }
            })
            .catch(() => { });
    };

    const getFilterQueryParams = () => {
        var queryParams: any = {};
        if (brandFilter) {
            queryParams.brandId = brandFilter.id
        }
        if (countryFilter) {
            queryParams.countryId = countryFilter.countryId
        }
        if (searchQueryFilter) {
            queryParams.searchQuery = searchQueryFilter
        }
        if (weekEndingFilter) {
            queryParams.weekEnding = weekEndingFilter.toISOString();
        }
        if (companyFilter) {
            queryParams.workflowId = companyFilter.workflowId
        }
        queryParams.comparisons = advancedFilters;
        return queryParams;
    }

    const additionalMenuItems = (
        <>
            {!isLoadingCsv ? (
                <IconButton
                    onClick={downloadCsv}
                    color='secondary'>
                    <Tooltip title='Download'>
                        <DownloadForOffline fontSize='large' />
                    </Tooltip>
                </IconButton>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );

    return (
        <>
            <StandardReadOnlyListView
                defaultPaginationProps={defaultMarketingFunnelPaginationProps}
                getDataQuery={useGetMarketingFunnelQuery}
                tableColumns={MARKETING_FUNNEL_RESULTS_COLUMNS}
                tableColumnGroups={MARKETING_FUNNEL_COLUMN_GROUPS}
                entityNamePlural='Marketing Funnel'
                headerTitle='Marketing Funnel'
                headerIcon={<InsertChart />}
                breadCrumbs={[{ label: 'Home', navLink: '/' }]}
                handleFilterClicked={handleOpenFilterDialog}
                filterChips={getFilterChips()}
                handleFilterChipDelete={handleRemoveFilterChip}
                additionalQueryParameters={getFilterQueryParams()}
                additionalHeaderElements={additionalMenuItems}
                showCompactToggle
            />

            <MarketingFunnelFilterDialog
                isVisible={isSearchResultFilterVisible}
                onClose={handleCloseFilterDialog}
                weekEndingFilter={weekEndingFilter}
                setWeekEndingFilter={setWeekEndingFilter}
                searchQueryFilter={searchQueryFilter}
                setSearchQueryFilter={setSearchQueryFilter}
                companyFilter={companyFilter}
                setCompanyFilter={setCompanyFilter}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
                countryFilter={countryFilter}
                setCountryFilter={setCountryFilter}
                advancedFilters={advancedFilters}
                setAdvancedFilters={setAdvancedFilters}
            />
            { renderConfirmationModal }
        </>
    );
}