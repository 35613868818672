import { AccountBox } from '@mui/icons-material';
import {
	Button,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Switch,
	Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { UserDto } from '../../../dtos';
import { emptyGuid } from '../../../util/constants';
import { isEmail } from '../../../util/isEmail';
import { FormSection, IFormProps, NavBreadcrumbs } from '../../CoreLib/library';

export const UserForm: FC<IFormProps<UserDto>> = (props) => {
	const { save, cancel, initValues } = props;
	const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
	const [firstName, setFirstName] = useState(initValues ? initValues.firstName : '');
	const [lastName, setLastName] = useState(initValues ? initValues.lastName : '');
	const [userRole, setUserRole] = useState(initValues ? initValues.role : '');
	const [email, setEmail] = useState(initValues ? initValues.email : '');
	const [phone, setPhone] = useState(initValues ? initValues.phoneNumber : '');
	const [fieldErrors, setFieldErrors] = useState({
		FIRST_NAME: '',
		LAST_NAME: '',
		EMAIL: '',
	});
	const [changed, setFormChanged] = useState(false);

	const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsActive(event.target.checked);
	};

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormChanged(true);
		setFirstName(event.target.value);
	};

	const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormChanged(true);
		setLastName(event.target.value);
	};

	const handleUserRoleChange = (event: SelectChangeEvent<string>) => {
		setFormChanged(true);
		setUserRole(event.target.value);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormChanged(true);
		setEmail(event.target.value);
	};

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormChanged(true);
		setPhone(event.target.value);
	};

	const onFieldBlur = (fieldName: string) => () => {
		validate(fieldName);
	};

	const validate = (fieldName: string) => {
		let isValid = false;
		if (fieldName === 'FIRST_NAME') {
			if (firstName) {
				fieldErrors.FIRST_NAME = '';
				isValid = true;
			} else {
				fieldErrors.FIRST_NAME = 'First name is required';
				isValid = false;
			}
		} else if (fieldName === 'LAST_NAME') {
			if (lastName) {
				fieldErrors.LAST_NAME = '';
				isValid = true;
			} else {
				fieldErrors.LAST_NAME = 'Last name is required';
				isValid = false;
			}
		} else if (fieldName === 'EMAIL') {
			if (email && isEmail(email)) {
				fieldErrors.EMAIL = '';
				isValid = true;
			} else if (!email) {
				fieldErrors.EMAIL = 'Email is required';
				isValid = false;
			} else {
				fieldErrors.EMAIL = 'Must be a valid email address';
				isValid = false;
			}
		}
		setFieldErrors({
			FIRST_NAME: fieldErrors.FIRST_NAME,
			LAST_NAME: fieldErrors.LAST_NAME,
			EMAIL: fieldErrors.EMAIL,
		});
		return isValid;
	};

	const formIsValid = () => {
		let isValid = validate('FIRST_NAME');
		isValid = validate('LAST_NAME') && isValid;
		isValid = validate('EMAIL') && isValid;
		return isValid;
	};

	const handleSave = (event: React.SyntheticEvent) => {
		event.preventDefault();
		if (formIsValid()) {
			save({
				id: initValues ? initValues.id : emptyGuid,
				firstName: firstName,
				lastName: lastName,
				role: userRole,
				email: email,
				phoneNumber: phone,
				displayName: firstName + ' ' + lastName,
				auth0UserId: initValues ? initValues.auth0UserId : '',
				workflowConfigClientId: initValues ? initValues.workflowConfigClientId : emptyGuid,
				isActive: true,
				createdOn: new Date()
			});
		}
	};

	const handleCancel = () => {
		cancel();
	};

	return (
		<Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
			<Grid position='sticky' item container direction='row' alignItems='center'>
				<Grid item container direction='column' justifyContent='start' xs={8}>
					<Typography variant='h1' sx={{ marginBottom: '8px' }}>
						<AccountBox /> {firstName && lastName ? firstName + ' ' + lastName : 'New User'}
					</Typography>
					<NavBreadcrumbs
						links={[
							{ label: 'Home', navLink: '/' },
							{ label: 'Admin', navLink: '', isText: true },
							{ label: 'Users', navLink: '/users' },
						]}
						currentPageLabel={firstName && lastName ? firstName + ' ' + lastName : 'New User'}
					/>
				</Grid>
				<Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
					<Grid item>
						<FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
					</Grid>
					<Grid item>
						{changed || !initValues ? (
							<Button fullWidth variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
								Cancel
							</Button>
						) : (
							<Button fullWidth variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
								Close
							</Button>
						)}
					</Grid>
					<Grid item>
						<Button fullWidth variant='contained' color='primary' type='submit' onClick={handleSave}>
							Save
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<FormSection>
					<Grid item container direction='row' spacing={8}>
						<Grid item xs={4}>
							<FormControl error={fieldErrors.FIRST_NAME !== ''} fullWidth required>
								<FormLabel>First Name</FormLabel>
								<OutlinedInput value={firstName} onChange={handleFirstNameChange} onBlur={onFieldBlur('FIRST_NAME')} />
								<FormHelperText>{fieldErrors.FIRST_NAME}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl error={fieldErrors.LAST_NAME !== ''} fullWidth required>
								<FormLabel>Last Name</FormLabel>
								<OutlinedInput value={lastName} onChange={handleLastNameChange} onBlur={onFieldBlur('LAST_NAME')} />
								<FormHelperText>{fieldErrors.LAST_NAME}</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={8}>
						<Grid item xs={4}>
							<FormControl fullWidth>
								<FormLabel>User Role</FormLabel>
								<Select value={userRole} onChange={handleUserRoleChange}>
									<MenuItem key='none' value=''>
										<em>None</em>
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl error={fieldErrors.EMAIL !== ''} fullWidth required>
								<FormLabel>Email</FormLabel>
								<OutlinedInput value={email} onChange={handleEmailChange} onBlur={onFieldBlur('EMAIL')} />
								<FormHelperText>{fieldErrors.EMAIL}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth>
								<FormLabel>Phone</FormLabel>
								<OutlinedInput value={phone} onChange={handlePhoneChange} onBlur={onFieldBlur('PHONE')} />
							</FormControl>
						</Grid>
					</Grid>
				</FormSection>
			</Grid>
		</Grid>
	);
};
