import { Grid, Typography, FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { DataTableColumn, LocalDataTable } from '../../CoreLib/library';
import { GENERIC_IMPORT_ROW_STATUSES } from './types';

export interface IImportResultsTableProps {
    importData: any[];
    tableColumns: DataTableColumn<any>[];
}

export const ImportResultsTable: FC<IImportResultsTableProps> = (props) => {
    const { importData, tableColumns } = props;
    const [isErrorOnly, setIsErrorOnly] = useState(false);

    const handleErrorVisibilityToggle = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsErrorOnly(checked)
    }

    const filteredImportData = useMemo(() => {
        if (isErrorOnly) {
            return importData.filter(importRow => importRow.status !== GENERIC_IMPORT_ROW_STATUSES.GOOD)
        } else {
            return importData;
        }
    }, [importData, isErrorOnly]);

    const mapStatus = (row: any) => {
		if (row.status === GENERIC_IMPORT_ROW_STATUSES.GOOD) {
			return row.status;
		}
		return <Typography sx={{ color: 'error' }}>{row.status}</Typography>;
	};

    const headersWithStatusColumn: DataTableColumn<any>[] = [...tableColumns, {
        key: 'status',
        label: 'Status',
        sortKey: 'STATUS',
        fieldMapper: (row) => mapStatus(row)
    }];

    return (
        <Grid>
            <Grid>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>Import Results</Typography>
                    <FormControlLabel
                        control={<Switch checked={isErrorOnly} onChange={handleErrorVisibilityToggle} />}
                        label='Show Invalid Only'
                        labelPlacement='start'
                    />
                </div>
            </Grid>
            <Grid>
                <LocalDataTable columns={headersWithStatusColumn} rowData={filteredImportData} height={360} dense />
            </Grid>
        </Grid>
    );
};
