import { Dataset, InsertChart } from '@mui/icons-material';
import { Grid } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FC, useState } from 'react';
import { LoadingIndicator, ApiError, DataTableColumn, StandardCardWithHeader, PaginatedDataTable } from '../../Components/CoreLib/library';
import { MarketShareDto } from '../../dtos';
import { BrandAnalyticsChartProps, getDateString, getPercentString } from './types';


export const MarketShareChart: FC<BrandAnalyticsChartProps<MarketShareDto>> = (props) => {
    const { initValues, setPaginatedProps, isLoading, error, refetch } = props;

    const [hoverRowId, setHoverRowId] = useState('');

    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (error) {
        <ApiError onReloadClick={refetch} />;
    }

    const tableColumns: DataTableColumn<MarketShareDto>[] = [
        {
            key: 'weekOfDate', label: 'Week Of', sortKey: 'WEEK_OF', align: 'left', unsorted: true, keyFieldFormatter: getDateString,
        },
        {
            key: 'marketShare', label: 'Market Share', sortKey: 'MARKET_SHARE', align: 'center', unsorted: true, keyFieldFormatter: getPercentString
        },
        {
            key: 'prevYear', label: 'Prev Year', sortKey: 'PREV_YEAR', align: 'center', unsorted: true, keyFieldFormatter: getPercentString
        },
        {
            key: 'yoYChange', label: 'YoY % Change', sortKey: 'YOY_CHANGE', align: 'right', unsorted: true, keyFieldFormatter: getPercentString
        },

    ];

    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            events: {
                render: function () {
                    if (initValues && hoverRowId !== "") {
                        var point = this.series[0].data[initValues.pageResults.findIndex(x => x.id === hoverRowId)];

                        point.setState('hover');
                        this.tooltip.refresh(point);

                    }
                },
            },

        },
        title: {
            text: 'Market Share per Week',
            align: 'center'
        },
        yAxis: {
            title:
            {
                text: 'Market Share'
            },
            labels: {
                format: '{value}%'
            }
        },
        xAxis: {
            categories: initValues?.pageResults.map(x => getDateString(x.weekOfDate)),
            title:
            {
                text: 'Week Of'
            }
        },
        legend: {
            align: 'center',
            layout: 'horizontal',
            verticalAlign: 'top'
        },
        tooltip: {
            valueSuffix: '%',
            valueDecimals: 2,
        },
        series: [
            {
                name: 'Market Share',
                data: initValues?.pageResults.map((x) => x.marketShare),
                type: 'column',


            },
            {
                name: 'Prev Year',
                data: initValues?.pageResults.map((x) => x.prevYear),
                type: 'line'
            }
        ]
    };

    return (
        <>
            <Grid item >
                <StandardCardWithHeader headerTitle='Market Share' headerIcon={<Dataset />}>

                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={false}
                        queryResults={initValues}
                        defaultSortKey='LABEL'
                        setPagination={setPaginatedProps}
                        isDashboardStyle={true}
                        isViewOnly={true}
                        isInactiveStyleApplied={false}
                        onHover={(id) => setHoverRowId(id)}
                    />

                </StandardCardWithHeader>
            </Grid >
            <Grid item >
                <StandardCardWithHeader headerTitle='Market Share per Week' headerIcon={<InsertChart />}>
                    <HighchartsReact highcharts={Highcharts} options={options}
                        containerProps={{ style: { width: '100%' } }}
                    ></HighchartsReact>

                </StandardCardWithHeader>

            </Grid>
        </>

    );
};
