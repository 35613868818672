import { Business, Settings, Tune } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import { DataTableColumn, NavBreadcrumbs, PaginatedProps, StandardCardWithHeader, StandardListView, useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { DEFAULT_WORKFLOW_CONFIG, WorkflowConfigFormDialog } from "../../Components/Forms/WorkflowConfigForm";
import { WorkflowConfigDto } from "../../dtos";
import { useBulkArchiveWorkflowConfigMutation, useBulkUnarchiveWorkflowConfigMutation, useGetCurrentUsersTotalMonthlyEstimateQuery, useGetWorkflowConfigsQuery, useLazyGetWorkflowConfigQuery } from "../../store/apis/workflow-config-api";
import { formatCurrency } from "../../util";

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'COMPANY_NAME',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export const WorkflowConfigListView: FC = () => {
    const [selectedWorkflowConfig, setSelectedWorkflowConfig] = useState<WorkflowConfigDto | null>(null);
    const [getWorkflowConfigById, { isError: isGetByIdError }] = useLazyGetWorkflowConfigQuery()
    const { data: totalMonthlyEstimate, isLoading: isTotalMonthlyEstimateLoading, isError: isTotalMonthlyEstimateError } = useGetCurrentUsersTotalMonthlyEstimateQuery();
    const tableColumns: DataTableColumn<WorkflowConfigDto>[] = [
        { key: 'companyName', label: 'Company', sortKey: 'COMPANY_NAME', align: 'left', unsorted: true },
        { key: 'searchTermCount', label: 'Search Terms', sortKey: 'SEARCH_TERM_COUNT', align: 'right', fieldMapper: (row) => row.searchTermCount.toLocaleString(), unsorted: true },
        { key: 'countryCount', label: 'Countries', sortKey: 'COUNTRY_COUNT', align: 'right', unsorted: true },
        { key: 'runsPerMonth', label: 'Runs Per Month', sortKey: 'RUNS_PER_MONTH', align: 'right', unsorted: true },
        { key: 'searchesPerMonth', label: 'Total Searches Per Month', sortKey: 'SEARCHES_PER_MONTH', align: 'right', fieldMapper: (row) => row.searchesPerMonth.toLocaleString(), unsorted: true },
        { key: 'freeMonthlyCredit', label: 'Free Monthly Credit', sortKey: 'FREE_MONTHLY_CREDIT', align: 'right', fieldMapper: (row) => row.freeMonthlyCredit.toLocaleString(), unsorted: true },
        { key: 'billableSearchesPerMonth', label: 'Billable Searches Per Month', sortKey: 'BILLABLE_SEARCHES_PER_MONTH', align: 'right', fieldMapper: (row) => row.billableSearchesPerMonth.toLocaleString(), unsorted: true },
        { key: 'perSearchFee', label: 'Fee Per Search', sortKey: 'PER_SEARCH_FEE', align: 'right', fieldMapper: (row) => formatCurrency(row.perSearchFee), unsorted: true },
        { key: 'monthlyEstimatedCost', label: 'Total Monthly Estimate', sortKey: 'MONTHLY_ESTIMATE', align: 'right', fieldMapper: (row) => formatCurrency(row.monthlyEstimatedCost), unsorted: true },
    ];

    useFailedActionSnackbar('retrieve', 'company details', isGetByIdError);
    useFailedActionSnackbar('retrieve', 'total monthly estimate', isTotalMonthlyEstimateError);

    const handleAddClicked = () => {
        setSelectedWorkflowConfig(DEFAULT_WORKFLOW_CONFIG)
    }

    const handleEditClicked = (configId: string) => {
        getWorkflowConfigById(configId).then((result) => {
            if (result.data) {
                setSelectedWorkflowConfig(result.data)
            }
        });
    }

    const handleClearSelected = () => {
        setSelectedWorkflowConfig(null);
    }

    const breadCrumbs = [
        { label: 'Home', navLink: '/' },
        { label: 'Admin', navLink: '', isText: true },
    ]

    return (
        <Grid container direction='column'>
            <Grid item container direction='column' justifyContent='start' xs={6} mb={4}>
                <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                    <Tune /> Configuration
                </Typography>
                {breadCrumbs && <NavBreadcrumbs links={breadCrumbs} currentPageLabel='Configuration' />}
            </Grid>
            <StandardCardWithHeader headerTitle={"Companies"} headerIcon={<Business />}>
                <StandardListView
                    defaultPaginationProps={defaultPaginationProps}
                    getDataQuery={useGetWorkflowConfigsQuery}
                    archiveMutation={useBulkArchiveWorkflowConfigMutation}
                    recoverMutation={useBulkUnarchiveWorkflowConfigMutation}
                    tableColumns={tableColumns}
                    entityNameSingular='Company'
                    permissionName='workflowConfig'
                    handleAdd={handleAddClicked}
                    handleEdit={handleEditClicked}
                    isHeaderVisible={false}
                    isRowSelectionEnabled={false}
                    isPaginationVisible={false}
                    isBorderVisible={false}
                    editIcon={<Settings />}
                    editToolTip="Settings"
                />
                <WorkflowConfigFormDialog selectedWorkflowConfig={selectedWorkflowConfig} onClearSelected={handleClearSelected} />
                <Grid item container direction='column' textAlign='end' pr={2} py={1}>
                    <Typography><span style={{ backgroundColor: '#FAFAA7', padding: '0 8px', fontWeight: 'bold' }}> TOTAL MONTHLY ESTIMATE: { isTotalMonthlyEstimateLoading ? 'Loading...' : formatCurrency(totalMonthlyEstimate ?? 0)}</span></Typography>
                    <Typography variant="caption" fontStyle='italic' >This estimate is an approximation and is not guaranteed. The estimate is based on the information above. Final cost may change based on actual searches per month.</Typography>
                </Grid>
            </StandardCardWithHeader>
        </Grid>
    );
}