import { SearchTermDto } from "../../../dtos";
import { emptyGuid } from "../../../util/constants";

export const defaultSearchTerm: SearchTermDto = {
    id: emptyGuid,
    searchQuery: '',
    isActive: true,
    workflowId: emptyGuid,
    workflowConfigBrandId: emptyGuid,
    createdOn: new Date(),
    companyName: '',
    brandName: ''
};