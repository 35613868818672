import { AsinDto } from "../../../dtos";
import { emptyGuid } from "../../../util/constants";

export const defaultAsin: AsinDto = {
    id: emptyGuid,
    ecommerceAsin: '',
    sku: '',
    isActive: true,
    workflowId: emptyGuid,
    workflowConfigBrandId: emptyGuid,
    createdOn: new Date(),
    companyName: '',
    brandName: ''
};