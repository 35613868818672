export const formatCurrencyLeaveNulls = (val?: number) => {
    if (val === undefined || val === null) {
        return '';
    }
    return formatCurrency(val);
};
export const formatCurrency = (val?: number) => {
    var zero = 0;
    return val
        ? val.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
          })
        : zero.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
          });
};
