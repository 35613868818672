import { DataTableColumn, IDataTableColumnGroup } from '../../Components/CoreLib/library';
import { BaseDto, QueryResultsDto, WorkflowConfigBrandDto, WorkflowConfigCountryDto, WorkflowConfigDto } from '../../dtos';
import { MarketingFunnelDto } from '../../dtos/generated/MarketingFunnelDto';
import { NumericalComparatorDto } from '../../dtos/generated/NumericalComparatorDto';
import { NumericalOperator } from '../../dtos/generated/NumericalOperator';
import { formatCurrencyLeaveNulls } from '../../util';

export interface MarketingFunnelChartProps<T extends BaseDto> {
    initValues?: QueryResultsDto<T>;
    isLoading: boolean;
    refetch(): void;
    isError: boolean;
    setPaginatedProps: any;
}

export const getPercentString = (value: number) => {
    if (!value) {
        return '';
    }
    return `${(value).toFixed(2)}%`;
}

export const getDateString = (value: Date) => {
    return new Date(Date.parse(value.toString())).toLocaleDateString();
}

export interface MarketingFunnelFilterProps {
    isVisible: boolean;
    onClose: () => void;
    weekEndingFilter?: Date | null;
    setWeekEndingFilter: (weekEnding: Date | null) => void;
    searchQueryFilter?: string | null;
    setSearchQueryFilter: (searchQuery: string) => void;
    companyFilter?: WorkflowConfigDto | null;
    setCompanyFilter: (company: WorkflowConfigDto | null) => void;
    brandFilter?: WorkflowConfigBrandDto | null;
    setBrandFilter: (brand: WorkflowConfigBrandDto | null) => void;
    countryFilter?: WorkflowConfigCountryDto | null;
    setCountryFilter: (country: WorkflowConfigCountryDto | null) => void;
    advancedFilters?: NumericalComparatorDto[] | null;
    setAdvancedFilters: (filters: NumericalComparatorDto[] | null) => void;
}

export const MARKETING_FUNNEL_RESULTS_COLUMNS: DataTableColumn<MarketingFunnelDto>[] = [
    { key: 'rank', label: 'Rank', sortKey: 'RANK', align: 'right', unsorted: false, },
    { key: 'searchTerm.companyName', label: 'Company Name', sortKey: 'COMPANY', unsorted: false, align: 'left', },
    { key: 'brandName', label: 'Brand Name', sortKey: 'BRAND_NAME', unsorted: false, align: 'left', },
    { key: 'countryName', label: 'Country', sortKey: 'COUNTRY_NAME', unsorted: false, align: 'left', },
    { key: 'searchTerm.searchQuery', label: 'Search Query', sortKey: 'SEARCH_QUERY', align: 'left', unsorted: false },
    { key: 'weekEndingOn', label: 'Week Ending On', sortKey: 'WEEK_ENDING_ON', align: 'left', unsorted: false, keyFieldFormatter: getDateString },
    { key: 'searchQueryVolume', label: 'Search Query Volume', sortKey: 'SEARCH_QUERY_VOLUME', align: 'right', unsorted: false },
    { key: 'brandImpressionPercentage', label: 'Impressions', sortKey: 'BRAND_SHARE_IMPRESSIONS', align: 'right', unsorted: false, keyFieldFormatter: getPercentString },
    { key: 'clickPercentage', label: 'Clicks', sortKey: 'BRAND_SHARE_CLICKS', align: 'right', unsorted: false, keyFieldFormatter: getPercentString },
    { key: 'cartAddPercentage', label: 'Cart Adds', sortKey: 'BRAND_SHARE_CART_ADDS', align: 'right', unsorted: false, keyFieldFormatter: getPercentString },
    { key: 'purchasePercentage', label: 'Purchases', sortKey: 'BRAND_SHARE_PURCHASES', align: 'right', unsorted: false, keyFieldFormatter: getPercentString },
    { key: 'totalPurchases', label: 'Total Purchases', sortKey: 'TOTAL_COUNT_PURCHASES', align: 'right', unsorted: false },
    { key: 'conversionRate', label: 'Conversion Rate', sortKey: 'CONVERSION_RATE', align: 'right', unsorted: false, keyFieldFormatter: getPercentString },
    { key: 'avgPrice', label: 'Avg Price', sortKey: 'MEDIAN_OVERALL_PRICE_PURCHASES', align: 'right', unsorted: false, keyFieldFormatter: formatCurrencyLeaveNulls },
    { key: 'myPrice', label: 'My Price', sortKey: 'MEDIAN_BRAND_PRICE_PURCHASES', align: 'right', unsorted: false, keyFieldFormatter: formatCurrencyLeaveNulls },
    { key: 'clicksOverImpressions', label: 'Clicks/Impressions', sortKey: 'CLICKS_OVER_IMPRESSIONS', align: 'right', unsorted: false },
    { key: 'cartAddsOverClicks', label: 'Cart Adds/Clicks', sortKey: 'CART_ADDS_OVER_CLICKS', align: 'right', unsorted: false },
    { key: 'purchasesOverCartAdds', label: 'Purchases/Cart Adds', sortKey: 'PURCHASES_OVER_CART_ADDS', align: 'right', unsorted: false },
];

const columnGroupStyles = { backgroundColor: '#2A3BA3', textColor: 'white' }

export const MARKETING_FUNNEL_COLUMN_GROUPS: IDataTableColumnGroup[] = [
    { label: '', colSpan: 7 },
    { label: 'Brand Percentages', colSpan: 4, ...columnGroupStyles },
    { label: 'Purchase Details', colSpan: 2, ...columnGroupStyles },
    { label: 'Prices', colSpan: 2, ...columnGroupStyles },
    { label: '<1 is not very clickable', colSpan: 1, ...columnGroupStyles },
    { label: '<1 needs better content', colSpan: 1, ...columnGroupStyles },
    { label: '<1 not as relevant to customer', colSpan: 1, ...columnGroupStyles }
];

export const MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS = [
    { key: 'searchQueryVolume', label: 'Search Query Volume' },
    { key: 'brandImpressionPercentage', label: 'Impressions', isPercent: true },
    { key: 'clickPercentage', label: 'Clicks', isPercent: true },
    { key: 'cartAddPercentage', label: 'Cart Adds', isPercent: true },
    { key: 'purchasePercentage', label: 'Purchases', isPercent: true },
    { key: 'totalPurchases', label: 'Total Purchases' },
    { key: 'conversionRate', label: 'Conversion Rate', isPercent: true },
    { key: 'avgPrice', label: 'Avg Price', isDollar: true },
    { key: 'myPrice', label: 'My Price', isDollar: true },
    { key: 'clicksOverImpressions', label: 'Clicks/Impressions' },
    { key: 'cartAddsOverClicks', label: 'Cart Adds/Clicks' },
    { key: 'purchasesOverCartAdds', label: 'Purchases/Cart Adds' },
];

export const MARKETING_FUNNEL_SUPPORTED_COMPARISONS = [
    { key: NumericalOperator.GREATER_THAN, label: 'Greater Than', operator: '>' },
    { key: NumericalOperator.GREATER_THAN_OR_EQUAL, label: 'Greater Than Or Equals', operator: '>=' },
    { key: NumericalOperator.LESS_THAN, label: 'Less Than', operator: '<' },
    { key: NumericalOperator.LESS_THAN_OR_EQUAL, label: 'Less Than Or Equals', operator: '<=' },
    { key: NumericalOperator.EQUAL, label: 'Equals', operator: '=' },
];

