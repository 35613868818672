import { serverApi } from '..';
import { AsinDto } from '../../dtos';
import { BaseApi } from './base-api';

const asinApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        var baseApi = new BaseApi<AsinDto>('asin', 'Asin', builder);
        return {    
            getAsins: baseApi.getAll(),
            getAsin: baseApi.getById(),
            createAsin: baseApi.create(),
            updateAsin: baseApi.update(),
            archiveAsin: baseApi.archive(),
            unarchiveAsin: baseApi.unarchive(),
            bulkArchiveAsin: baseApi.bulkArchive(),
            bulkUnarchiveAsin: baseApi.bulkUnarchive(),
        }
    },
});

export const {
    useGetAsinsQuery,
    useLazyGetAsinsQuery,
    useGetAsinQuery,
    useLazyGetAsinQuery,
    useCreateAsinMutation,
    useUpdateAsinMutation,
    useArchiveAsinMutation,
    useUnarchiveAsinMutation,
    useBulkArchiveAsinMutation,
    useBulkUnarchiveAsinMutation
} = asinApi;
