import { BrandAnalyticsDto, QueryResultsDto } from '../../dtos';
import { MarketShareDto } from '../../dtos/generated/reporting/MarketShareDto';
import { UnitsPurchasedDto } from '../../dtos/generated/reporting/UnitsPurchasedDto';
import { buildSearchQueryRoute } from '../../util';
import { serverApi } from '../api';
import { BrandAnalyticsChartsQueryParams, BrandAnalyticsQueryParams } from '../types';

const brandAnalyticsApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getMultipleBrandAnalytics: builder.query<QueryResultsDto<BrandAnalyticsDto>, BrandAnalyticsQueryParams>({
            query: (params) => buildSearchQueryRoute('brandAnalytics', params),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
        getMarketShareData: builder.query<QueryResultsDto<MarketShareDto>, BrandAnalyticsChartsQueryParams>({
            query: (params) => buildSearchQueryRoute('brandAnalytics/marketShare', params),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
        getUnitsPurchasedData: builder.query<QueryResultsDto<UnitsPurchasedDto>, BrandAnalyticsChartsQueryParams>({
            query: (params) => buildSearchQueryRoute('brandAnalytics/unitsPurchased', params),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
        getBrandAnalytics: builder.query<BrandAnalyticsDto, string>({
            query: (id) => ({ url: `brandAnalytics/${id}` }),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
        createBrandAnalytics: builder.mutation<BrandAnalyticsDto, BrandAnalyticsDto>({
            query: (brandAnalyticsDto) => ({
                url: 'brandAnalytics',
                method: 'POST',
                body: brandAnalyticsDto,
            }),
            invalidatesTags: [{ type: 'Brand Analytics' }],
        }),
        updateBrandAnalytics: builder.mutation<void, BrandAnalyticsDto>({
            query: (brandAnalyticsDto) => ({
                url: 'brandAnalytics',
                method: 'PUT',
                body: brandAnalyticsDto,
            }),
            invalidatesTags: [{ type: 'Brand Analytics' }],
        }),
        archiveBrandAnalytics: builder.mutation<void, string>({
            query: (id) => ({
                url: `brandAnalytics/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Brand Analytics' }],
        }),
        unarchiveBrandAnalytics: builder.mutation<void, string>({
            query: (id) => ({
                url: `brandAnalytics/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Brand Analytics' }],
        }),
        downloadBrandAnalyticsDataCsv: builder.query<any, BrandAnalyticsQueryParams>({
            query: (params) => buildSearchQueryRoute('brandAnalytics/download', params),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
        getBrandAnalyticsWeeks: builder.query<Date[], BrandAnalyticsChartsQueryParams>({
            query: (params) => buildSearchQueryRoute('brandAnalytics/weekEnding', params),
            providesTags: [{ type: 'Brand Analytics' }],
        }),
    }),
});

export const {
    useGetMultipleBrandAnalyticsQuery,
    useGetBrandAnalyticsQuery,
    useCreateBrandAnalyticsMutation,
    useUpdateBrandAnalyticsMutation,
    useArchiveBrandAnalyticsMutation,
    useUnarchiveBrandAnalyticsMutation,
    useLazyGetMultipleBrandAnalyticsQuery,
    useLazyGetBrandAnalyticsQuery,
    useGetMarketShareDataQuery,
    useGetUnitsPurchasedDataQuery,
    useLazyDownloadBrandAnalyticsDataCsvQuery,
    useDownloadBrandAnalyticsDataCsvQuery,
    useGetBrandAnalyticsWeeksQuery,
} = brandAnalyticsApi;
