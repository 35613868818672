import { FormControl, FormLabel, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useEffect } from 'react';
import { WorkflowConfigDto, WorkflowConfigBrandDto, WorkflowConfigCountryDto } from '../../../dtos';
import { useGetWorkflowConfigsQuery } from '../../../store/apis/workflow-config-api';
import { useGetWorkflowConfigBrandsQuery } from '../../../store/apis/workflow-config-brand-api';
import { emptyGuid } from '../../../util';
import { useFailedActionSnackbar } from '../../CoreLib/library';
import { useGetCountriesQuery } from '../../../store/apis/country-api';

export interface ICompanyBrandCountrySelectProps {
    selectedCompanyId: string;
    setSelectedCompany: (company: WorkflowConfigDto | null) => void;
    selectedBrandId: string;
    setSelectedBrand: (brand: WorkflowConfigBrandDto | null) => void;
    selectedCountryId?: string;
    setSelectedCountry?: (country: WorkflowConfigCountryDto | null) => void;
}

export const CompanyBrandCountrySelect: FC<ICompanyBrandCountrySelectProps> = props => {
    const { selectedCompanyId, setSelectedCompany, selectedBrandId, setSelectedBrand, selectedCountryId, setSelectedCountry } = props;
    const {
        data: getWorkflowConfigResponse,
        isLoading: isLoadingWorkflowConfigs,
        isError: isGetWorkflowConfigError,
    } = useGetWorkflowConfigsQuery({
        searchText: '',
        sortKey: 'COMPANY_NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });
    const {
        data: workflowConfigBrands,
        isLoading: isLoadingWorkflowConfigBrands,
        isError: isGetWorkflowConfigBrandError,
    } = useGetWorkflowConfigBrandsQuery({
        searchText: '',
        sortKey: 'BRAND_NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });
    const {
        data: countryData,
        isLoading: isLoadingCountries,
        isError: isCountryError
    } = useGetCountriesQuery({
        searchText: '',
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });

    const isLoading = () => isLoadingWorkflowConfigs || isLoadingWorkflowConfigBrands || isLoadingCountries;

    useFailedActionSnackbar('retrieving', 'companies', isGetWorkflowConfigError);
    useFailedActionSnackbar('retrieving', 'brands', isGetWorkflowConfigBrandError);
    useFailedActionSnackbar('retrieving', 'countries', isCountryError);

    const handleCompanyChange = (event: SelectChangeEvent<string>) => {
        const workflowId = event.target.value;
        if (workflowId === emptyGuid) {
            setSelectedCompany(null);
        }
        const workflowRecord = getWorkflowConfigResponse?.pageResults.find((workflowConfig) => workflowConfig.workflowId === workflowId);
        if (!workflowRecord) {
            console.warn('Workflow ', workflowId, ' not found in fetched results');
            return;
        }

        setSelectedCompany(workflowRecord);
    };

    useEffect(() => {
        let shouldClearBrand = false;
        if (selectedBrandId && selectedBrandId !== emptyGuid && selectedCompanyId && selectedCompanyId !== emptyGuid) {
            const brandRecord = workflowConfigBrands?.pageResults.find((brand) => brand.id === selectedBrandId);
            if (!brandRecord) {
                console.warn('Brand ', selectedBrandId, ' not found in fetched results');
                return;
            }
            if (brandRecord.workflowConfig?.workflowId !== selectedCompanyId) {
                shouldClearBrand = true;
            }
        }
        if (shouldClearBrand) {
            setSelectedBrand(null);
        }
    }, [selectedBrandId, selectedCompanyId, setSelectedBrand, workflowConfigBrands?.pageResults])

    useEffect(() => {
        if (!setSelectedCountry) {
            return;
        }
        let shouldClearCountry = false;
        if (selectedCountryId && selectedCountryId !== emptyGuid && selectedCompanyId && selectedCompanyId !== emptyGuid) {
            const selectedWorkflowConfig = getWorkflowConfigResponse?.pageResults.find((config) => config.workflowId === selectedCompanyId);
            const countryRecord = selectedWorkflowConfig?.workflowConfigCountries.find(country => country.countryId === selectedCountryId)
            if (!countryRecord) {
                console.warn('Country ', selectedCountryId, ' not found in fetched results');
                return;
            }
            if (countryRecord.workflowConfigId !== selectedWorkflowConfig?.id) {
                shouldClearCountry = true;
            }
        }
        if (shouldClearCountry) {
            setSelectedCountry(null);
        }
    }, [selectedCountryId, selectedCompanyId, setSelectedCountry, getWorkflowConfigResponse?.pageResults])

    const handleBrandChange = (event: SelectChangeEvent<string>) => {
        const brandId = event.target.value;
        if (brandId === emptyGuid) {
            setSelectedBrand(null)
        }
        const brandRecord = workflowConfigBrands?.pageResults.find((brand) => brand.id === brandId);
        if (!brandRecord) {
            console.warn('Brand ', brandId, ' not found in fetched results');
            return;
        }

        setSelectedBrand(brandRecord);
    };

    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        if (!setSelectedCountry) {
            return;
        }
        const countryId = event.target.value;
        if (countryId === emptyGuid) {
            setSelectedCountry(null)
        }
        const selectedWorkflowConfig = getWorkflowConfigResponse?.pageResults.find((config) => config.workflowId === selectedCompanyId);
        const configCountryRecord = selectedWorkflowConfig?.workflowConfigCountries.find(country => country.countryId === countryId)
        if (!configCountryRecord) {
            console.warn('Country ', countryId, ' not found in fetched results');
            return;
        }

        setSelectedCountry(configCountryRecord);
    }

    const getCompanyOptions = () => {
        if (!getWorkflowConfigResponse) {
            return [];
        }
        return getWorkflowConfigResponse.pageResults.map((workflowConfig) => ({ name: workflowConfig.companyName, value: workflowConfig.workflowId }));
    };

    const getBrandOptions = () => {
        if (!workflowConfigBrands) {
            return [];
        }
        const selectedWorkflowConfig = getWorkflowConfigResponse?.pageResults.find((config) => config.workflowId === selectedCompanyId);

        return workflowConfigBrands.pageResults
            .filter((brand) => !selectedWorkflowConfig || selectedWorkflowConfig.id === brand.workflowConfigId)
            .map((workflowConfigBrand) => ({ name: workflowConfigBrand.brandName, value: workflowConfigBrand.id }));
    };

    const getCountryOptions = () => {
        const selectedWorkflowConfig = getWorkflowConfigResponse?.pageResults.find((config) => config.workflowId === selectedCompanyId);
        const brandAnalyticsCountries = selectedWorkflowConfig?.workflowConfigCountries
            .filter(country => country.isUsedInBrandAnalytics)
            .map(workflowCountry => workflowCountry.countryId) ?? [];

        return countryData?.pageResults.filter(country => brandAnalyticsCountries.includes(country.id))
            .map(country => ({ name: country.name, value: country.id })) ?? [];
    }

    const getComponentWidth = () => setSelectedCountry ? 4 : 6;

    return (
        <Grid container item direction='row' spacing={3}>
            <Grid item xs={getComponentWidth()}>
                <FormControl fullWidth disabled={isLoading()}>
                    <FormLabel>Company</FormLabel>
                    <Select value={selectedCompanyId} onChange={handleCompanyChange} sx={{ background: 'white' }}>
                        <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                        {getCompanyOptions().map((companyOption) => (
                            <MenuItem key={companyOption.value} value={companyOption.value}>
                                {companyOption.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={getComponentWidth()}>
                <FormControl fullWidth disabled={isLoading()}>
                    <FormLabel>Brand</FormLabel>
                    <Select value={selectedBrandId} onChange={handleBrandChange} sx={{ background: 'white' }}>
                        <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                        {getBrandOptions().map((brandOption) => (
                            <MenuItem key={brandOption.value} value={brandOption.value}>
                                {brandOption.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {setSelectedCountry && (
                <Grid item xs={getComponentWidth()}>
                    <FormControl fullWidth disabled={isLoading()}>
                        <FormLabel>Country</FormLabel>
                        <Select value={selectedCountryId} onChange={handleCountryChange} sx={{ background: 'white' }}>
                            <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                            {getCountryOptions().map((countryOption) => (
                                <MenuItem key={countryOption.value} value={countryOption.value}>
                                    {countryOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    )
}