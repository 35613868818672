import { Download, UploadFile } from '@mui/icons-material';
import { Button, CardActions, CardContent, CardHeader, CircularProgress, Dialog, Stack, Typography, useTheme } from '@mui/material';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import Papa from 'papaparse';
import { FC, useEffect, useMemo, useState } from 'react';
import { ImportDto, ImportType } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { DataTableColumn } from '../../CoreLib/library';
import { CsvFilePicker } from './CsvFilePicker';
import { ImportPreviewTable } from './ImportPreviewTable';
import { ImportResultsTable } from './ImportResultsTable';
import { GENERIC_IMPORT_ROW_STATUSES } from './types';

const STEP = {
    FILE_UPLOAD: 0,
    REVIEW_DATA: 1,
    VIEW_RESULTS: 2,
};

export interface IStandardImportModalProps {
    isVisible: boolean;
    onClose: () => void;
    entityNamePlural: string;
    sampleCsvFileName: string;
    requiredHeaders: string[];
    tableColumns: DataTableColumn<any>[];
    localValidationMethod: (row: any) => string;
    importMutation: UseMutation<MutationDefinition<ImportDto<any>, any, any, ImportDto<any>, any>>;
    importType: ImportType;
}

export const StandardImportModal: FC<IStandardImportModalProps> = (props) => {
    const { isVisible, onClose, entityNamePlural, sampleCsvFileName, requiredHeaders, tableColumns, localValidationMethod, importMutation, importType } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [parseResults, setParseResults] = useState<Papa.ParseResult<any> | null>();
    const [parsingErrorMessage, setParsingErrorMessage] = useState('');
    const [isParsing, setIsParsing] = useState(false);
    const [importRecords, { isLoading: isImportLoading, isSuccess: isImportSuccessful, isError: isImportError, data: importResponse, reset: resetImport }] =
        importMutation();
    const isLoading = isParsing || isImportLoading;
    const theme = useTheme();

    useEffect(() => {
        if (isImportSuccessful) {
            setCurrentStep(STEP.VIEW_RESULTS);
        }
    }, [isImportSuccessful]);

    const closeAndReset = () => {
        onClose();
        setCurrentStep(0);
        setSelectedFile(null);
        setParseResults(null);
        setParsingErrorMessage('');
        setIsParsing(false);
    };

    const areRequiredHeadersPresent = (foundHeaders: string[]) => {
        return requiredHeaders.every((requiredHeader) => foundHeaders.includes(requiredHeader));
    };

    const parsingConfig: Papa.ParseLocalConfig<any, any> = {
        header: true,
        skipEmptyLines: true,
        complete(results) {
            setParseResults(results);
            const foundHeaders = results.meta.fields ?? [];
            if (areRequiredHeadersPresent(foundHeaders)) {
                setCurrentStep(STEP.REVIEW_DATA);
            } else {
                setParsingErrorMessage(`Selected file missing required header(s)\nFound: ${foundHeaders.join(', ')}\nExpected: ${requiredHeaders.join(', ')}`);
            }
            setIsParsing(false);
        },
        error() {
            setParsingErrorMessage('Failed to parse selected file.');
        },
    };

    const handleImport = () => {
        if (isImportLoading) {
            return;
        }
        if (isImportSuccessful || isImportError) {
            resetImport();
        }
        if (!parseResults) {
            console.warn('Unable to sent import request. No parsed records found.');
            return;
        }

        const importRequest: ImportDto<any> = {
            recordsAdded: 0,
            recordsSkipped: 0,
            recordsUpdated: 0,
            importType: importType,
            fileName: selectedFile?.name ?? 'unknown.csv',
            parsedFile: parseResults.data,
            id: emptyGuid,
            isActive: true,
            createdOn: new Date(),
        };

        importRecords(importRequest);
    };

    const handleFileSelected = (file: File | null) => {
        setParsingErrorMessage('');
        setSelectedFile(file);
    };

    const getOnNextClickAction = () => {
        switch (currentStep) {
            case STEP.FILE_UPLOAD:
                if (!selectedFile) {
                    return;
                }
                setIsParsing(true);
                Papa.parse<any>(selectedFile, parsingConfig);
                break;
            case STEP.REVIEW_DATA:
                handleImport();
                break;
            case STEP.VIEW_RESULTS:
                closeAndReset();
                break;
            default:
                setCurrentStep(currentStep + 1);
        }
    };

    const getOnPreviousClickAction = () => {
        switch (currentStep) {
            case STEP.FILE_UPLOAD:
                closeAndReset();
                break;
            default:
                setCurrentStep(currentStep - 1);
        }
    };

    const getNextButtonText = (): string => {
        switch (currentStep) {
            case STEP.REVIEW_DATA:
                return 'Import';
            case STEP.VIEW_RESULTS:
                return 'Close';
            default:
                return 'Next';
        }
    };

    const getPreviousButtonText = (): string => {
        switch (currentStep) {
            case STEP.FILE_UPLOAD:
                return 'Cancel';
            default:
                return 'Previous';
        }
    };

    const getIsNextDisabled = () => {
        switch (currentStep) {
            case STEP.FILE_UPLOAD:
                var isFileSelected = !!selectedFile;
                return !isFileSelected || isParsing;
            case STEP.REVIEW_DATA:
                return isImportLoading;
            default:
                return false;
        }
    };

    const handleDownloadSample = () => {
        window.open(`${window.origin}/${sampleCsvFileName}`);
    };

    const invalidRecordCount = useMemo(() => {
        if (!parseResults) {
            return 0;
        }
        return parseResults.data.filter((parsedRecord) => localValidationMethod(parsedRecord) !== GENERIC_IMPORT_ROW_STATUSES.GOOD).length;
    }, [parseResults, localValidationMethod]);

    return (
        <Dialog open={isVisible} fullWidth maxWidth='md'>
            <CardHeader
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                }}
                title={
                    <Stack direction='row' alignItems='center' gap={1}>
                        <UploadFile />
                        <Typography variant='h3' mr={2}>
                            Import {entityNamePlural}
                        </Typography>
                    </Stack>
                }
                action={isLoading && <CircularProgress color='inherit' size={28} sx={{ marginRight: 2 }} />}
            />
            {currentStep === STEP.FILE_UPLOAD && (
                <CardContent>
                    <CsvFilePicker selectedFile={selectedFile} setSelectedFile={handleFileSelected} />
                    {parsingErrorMessage !== '' && (
                        <Typography color='error' sx={{ whiteSpace: 'pre-line' }}>
                            {parsingErrorMessage}
                        </Typography>
                    )}
                </CardContent>
            )}
            {currentStep === STEP.REVIEW_DATA && (
                <CardContent>
                    {isImportError && (
                        <Typography color='error'>
                            Import did not complete successfully, please try again. If the error persists contact technical support.
                        </Typography>
                    )}
                    <ImportPreviewTable importData={parseResults?.data ?? []} tableColumns={tableColumns} localValidationMethod={localValidationMethod} />
                </CardContent>
            )}
            {currentStep === STEP.VIEW_RESULTS && (
                <CardContent>
                    <ImportResultsTable importData={importResponse?.parsedFile ?? []} tableColumns={tableColumns} />
                </CardContent>
            )}
            <CardActions sx={{ display: 'flex', justifyContent: 'end', gap: 2, paddingX: 2 }}>
                {currentStep === STEP.FILE_UPLOAD && (
                    <Button sx={{ marginRight: 'auto' }} startIcon={<Download />} onClick={handleDownloadSample}>
                        Download Sample CSV
                    </Button>
                )}
                {currentStep === STEP.REVIEW_DATA && (
                    <div style={{ marginRight: 'auto' }}>
                        Total Records: <b>{parseResults?.data.length ?? 0}</b> | 
                        Invalid Records: <b>{invalidRecordCount}</b>
                    </div>
                )}
                {currentStep === STEP.VIEW_RESULTS && importResponse && (
                    <div style={{ marginRight: 'auto' }}>
                        Imported: <b>{importResponse.recordsAdded}</b> | 
                        Skipped: <b>{importResponse.recordsSkipped}</b> | 
                        Updated: <b>{importResponse.recordsUpdated}</b>
                    </div>
                )}
                {currentStep === STEP.REVIEW_DATA && (
                    <Button variant='outlined' onClick={closeAndReset} sx={{ width: '100px' }}>
                        Cancel
                    </Button>
                )}
                {currentStep !== STEP.VIEW_RESULTS && (
                    <Button variant='outlined' onClick={getOnPreviousClickAction}>
                        {getPreviousButtonText()}
                    </Button>
                )}
                <Button variant='contained' sx={{ width: '100px' }} onClick={getOnNextClickAction} disabled={getIsNextDisabled()}>
                    {getNextButtonText()}
                </Button>
            </CardActions>
        </Dialog>
    );
};
