import { DataTableColumn } from "../../Components/CoreLib/library";
import { SearchTermDto, ShelfSpaceDto, WorkflowConfigBrandDto, WorkflowConfigDto } from "../../dtos";

export interface ShelfSpaceFilterProps {
    isVisible: boolean;
    onClose: () => void;
    searchTermFilter?: SearchTermDto | null;
    setSearchTermFilter: (searchTerm: SearchTermDto | null) => void;
    companyFilter?: WorkflowConfigDto | null;
    setCompanyFilter: (asin: WorkflowConfigDto | null) => void;
    brandFilter?: WorkflowConfigBrandDto | null;
    setBrandFilter: (asin: WorkflowConfigBrandDto | null) => void;
}

const convertDecimalToPercentage = (decimal: number) => (decimal * 100).toFixed(1) + '%';
export const SHELF_SPACE_DEFAULT_SORT_KEY = 'SEARCH_TERM';
export const SHELF_SPACE_COLUMNS: DataTableColumn<ShelfSpaceDto>[] = [
    { key: 'companyName', label: 'Company', sortKey: 'COMPANY' },
    { key: 'brandName', label: 'Brand', sortKey: 'BRAND' },
    { key: 'searchTermText', label: 'Search Term', sortKey: 'SEARCH_TERM' },
    { key: 'searchCountry', label: 'Country', sortKey: 'SEARCH_COUNTRY' },
    {
        key: 'run1',
        label: 'run1DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_1',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run1),
    },
    {
        key: 'run2',
        label: 'run2DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_2',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run2),
    },
    {
        key: 'run3',
        label: 'run3DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_3',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run3),
    },
    {
        key: 'run4',
        label: 'run4DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_4',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run4),
    },
    {
        key: 'run5',
        label: 'run5DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_5',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run5),
    },
    {
        key: 'run6',
        label: 'run6DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_6',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run6),
    },
    {
        key: 'run7',
        label: 'run7DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_7',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run7),
    },
    {
        key: 'run8',
        label: 'run8DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_8',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run8),
    },
    {
        key: 'run9',
        label: 'run9DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_9',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run9),
    },
    {
        key: 'run10',
        label: 'run10DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_10',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run10),
    },
    {
        key: 'run11',
        label: 'run11DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_11',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run11),
    },
    {
        key: 'run12',
        label: 'run12DateString',
        shouldPullHeaderFromData: true,
        sortKey: 'RUN_12',
        align: 'right',
        fieldMapper: (row) => convertDecimalToPercentage(row.run12),
    },
];