import { IRoute } from './types';
import {
    UsersListView,
    AddUserView,
    EditUserView,
    AsinListView,
    SearchTermsListView,
    WorkflowConfigListView,
    Home,
    BrandAnalyticsView,
    SearchResultsReportView,
    ShelfSpaceReportView,
    MarketingFunnelView,
} from '../Views';
import { SupportView } from '../Views/Support';

export const routes: IRoute[] = [
    {
        component: <Home />,
        path: '/',
        requiredPermissions: [],
    },
    {
        component: <UsersListView />,
        path: '/users',
        requiredPermissions: ['read:users'],
    },
    {
        component: <AddUserView />,
        path: '/user/create',
        requiredPermissions: ['create:users'],
    },
    {
        component: <EditUserView />,
        path: '/user/:id',
        requiredPermissions: ['edit:users'],
    },
    {
        component: <AsinListView />,
        path: '/asins',
        requiredPermissions: ['read:asins'],
    },
    {
        component: <SearchTermsListView />,
        path: '/search-terms',
        requiredPermissions: ['read:searchTerms'],
    },
    {
        component: <WorkflowConfigListView />,
        path: '/client/edit',
        requiredPermissions: ['read:workflowConfig'],
    },
    {
        component: <BrandAnalyticsView />,
        path: '/reports/brand-analytics',
        requiredPermissions: ['read:brandAnalytics'],
    },
    {
        component: <MarketingFunnelView />,
        path: '/reports/marketing-funnel',
        requiredPermissions: ['read:brandAnalytics'],
    },
    {
        component: <SearchResultsReportView />,
        path: '/reports/search-results',
        requiredPermissions: ['read:reports'],
    },
    {
        component: <ShelfSpaceReportView />,
        path: '/reports/shelf-space',
        requiredPermissions: ['read:reports'],
    },
    {
        component: <SupportView />,
        path: '/support',
        requiredPermissions: ['read:support'],
    },
];

export const publicRoutes: IRoute[] = [];
