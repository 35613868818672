import { ShoppingCart } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { DataTableColumn, IFilterChip, PaginatedProps, StandardListView, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { AsinFormDialog } from '../../Components/Forms/AsinForm';
import { defaultAsin } from '../../Components/Forms/AsinForm/utils';
import { AsinDto } from '../../dtos';
import { useBulkArchiveAsinMutation, useBulkUnarchiveAsinMutation, useGetAsinsQuery, useLazyGetAsinQuery } from '../../store/apis/asin-api';
import { emptyGuid } from '../../util';
import { AsinFilterDialog, IAsinFilterOptions } from './AsinFilterDialog';
import { ImportAsinsDialog } from './ImportAsinsDialog';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'ECOMMERCE_ASIN',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const CHIP_KEYS = {
    workflow: 'Company',
    brand: 'Brand',
};

export const AsinListView: FC = () => {
    const [getAsinById, { isError: isGetByIdError }] = useLazyGetAsinQuery();
    const [selectedAsin, setSelectedAsin] = useState<AsinDto | null>(null);
    const [isImportVisible, setIsImportVisible] = useState(false);
    const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const workflowId = searchParams.get('workflowId');
    const companyName = searchParams.get('companyName');
    const brandId = searchParams.get('brandId');
    const brandName = searchParams.get('brandName');

    const [asinFilters, setAsinFilters] = useState<IAsinFilterOptions>({
        workflowId: workflowId && companyName ? workflowId : emptyGuid,
        companyName: workflowId && companyName ? companyName : '',
        brandId: brandId && brandName ? brandId : emptyGuid,
        brandName: brandId && brandName ? brandName : '',
    });

    useEffect(() => {
        const { workflowId, companyName, brandId, brandName } = asinFilters;
        let updatedSearchParams = {};
        if (workflowId !== emptyGuid && companyName !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                workflowId,
                companyName,
            };
        }

        if (brandId !== emptyGuid && brandName !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                brandId,
                brandName,
            };
        }

        setSearchParams(createSearchParams(updatedSearchParams));
    }, [asinFilters, setSearchParams]);

    const tableColumns: DataTableColumn<AsinDto>[] = [
        { key: 'companyName', label: 'Company', sortKey: 'COMPANY' },
        { key: 'brandName', label: 'Brand', sortKey: 'BRAND' },
        { key: 'ecommerceAsin', label: 'ASIN', sortKey: 'ECOMMERCE_ASIN' },
        { key: 'sku', label: 'SKU', sortKey: 'SKU' },
    ];

    useFailedActionSnackbar('retrieve', 'asins', isGetByIdError);

    const handleAsinFilterChange = (updatedFilters: IAsinFilterOptions) => {
        setAsinFilters(updatedFilters);
    };

    const handleAddClicked = () => {
        setSelectedAsin(defaultAsin);
    };

    const handleImportClicked = () => {
        setIsImportVisible(true);
    };

    const handleFilterClicked = () => {
        setIsFilterDialogVisible(true);
    };

    const handleEditClicked = (asinId: string) => {
        getAsinById(asinId).then((result) => {
            if (result.data) {
                setSelectedAsin(result.data);
            }
        });
    };

    const getFilterChips = (): IFilterChip[] => {
        const filterChips: IFilterChip[] = [];
        if (asinFilters.workflowId !== emptyGuid) {
            filterChips.push({
                key: CHIP_KEYS.workflow,
                text: asinFilters.companyName,
            });
        }
        if (asinFilters.brandId !== emptyGuid) {
            filterChips.push({
                key: CHIP_KEYS.brand,
                text: asinFilters.brandName,
            });
        }
        return filterChips;
    };

    const handleRemoveFilterChip = (filterChipKey: string) => {
        switch (filterChipKey) {
            case CHIP_KEYS.workflow:
                setAsinFilters({
                    ...asinFilters,
                    workflowId: emptyGuid,
                    companyName: '',
                });
                break;
            case CHIP_KEYS.brand:
                setAsinFilters({
                    ...asinFilters,
                    brandId: emptyGuid,
                    brandName: '',
                });
                break;
            default:
                console.warn('Invalid filter chip key provided: ', filterChipKey);
                break;
        }
    };

    return (
        <Grid container direction='column' spacing={3} padding={2}>
            <StandardListView
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetAsinsQuery}
                archiveMutation={useBulkArchiveAsinMutation}
                recoverMutation={useBulkUnarchiveAsinMutation}
                tableColumns={tableColumns}
                entityNameSingular='ASIN'
                permissionName='asins'
                handleAdd={handleAddClicked}
                handleEdit={handleEditClicked}
                headerIcon={<ShoppingCart />}
                headerTitle='ASINs'
                breadCrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Admin', navLink: '', isText: true },
                ]}
                handleImportClicked={handleImportClicked}
                handleFilterClicked={handleFilterClicked}
                filterChips={getFilterChips()}
                additionalQueryParameters={{
                    workflowId: asinFilters.workflowId,
                    brandId: asinFilters.brandId,
                }}
                handleFilterChipDelete={handleRemoveFilterChip}
            />
            <AsinFilterDialog
                isVisible={isFilterDialogVisible}
                onClose={() => setIsFilterDialogVisible(false)}
                filterProps={asinFilters}
                setFilterProps={handleAsinFilterChange}
            />
            <AsinFormDialog selectedAsin={selectedAsin} onClearSelected={() => setSelectedAsin(null)} />
            <ImportAsinsDialog isImportVisible={isImportVisible} onClose={() => setIsImportVisible(false)} />
        </Grid>
    );
};
