import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import { DataTableColumn, IFilterChip, PaginatedProps, StandardListView, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { SearchTermFormDialog } from '../../Components/Forms/SearchTermForm/SearchTermFormDialog';
import { defaultSearchTerm } from '../../Components/Forms/SearchTermForm/utils';
import { SearchTermDto } from '../../dtos';
import { useBulkArchiveSearchTermMutation, useBulkUnarchiveSearchTermMutation, useGetSearchTermsQuery, useLazyGetSearchTermQuery } from '../../store/apis/search-term-api';
import { emptyGuid } from '../../util';
import { ImportSearchTermsDialog } from './ImportSearchTermsDialog';
import { ISearchTermFilterOptions, SearchTermFilterDialog } from './SearchTermFilterDialog';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'SEARCH_QUERY',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const CHIP_KEYS = {
    workflow: 'Company',
    brand: 'Brand',
};

export const SearchTermsListView: FC = () => {
    const [getSearchTermById, { isError: isGetByIdError }] = useLazyGetSearchTermQuery();
    const [selectedSearchTerm, setSelectedSearchTerm] = useState<SearchTermDto | null>(null);
    const [isImportVisible, setIsImportVisible] = useState(false);
    const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const workflowId = searchParams.get('workflowId');
    const companyName = searchParams.get('companyName');
    const brandId = searchParams.get('brandId');
    const brandName = searchParams.get('brandName');

    const [searchTermFilters, setSearchTermFilters] = useState<ISearchTermFilterOptions>({
        workflowId: workflowId && companyName ? workflowId : emptyGuid,
        companyName: workflowId && companyName ? companyName : '',
        brandId: brandId && brandName ? brandId : emptyGuid,
        brandName: brandId && brandName ? brandName : '',
    });

    useEffect(() => {
        const { workflowId, companyName, brandId, brandName } = searchTermFilters;
        let updatedSearchParams = {};
        if (workflowId !== emptyGuid && companyName !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                workflowId,
                companyName,
            };
        }

        if (brandId !== emptyGuid && brandName !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                brandId,
                brandName,
            };
        }

        setSearchParams(createSearchParams(updatedSearchParams));
    }, [searchTermFilters, setSearchParams]);

    const tableColumns: DataTableColumn<SearchTermDto>[] = [
        { key: 'companyName', label: 'Company', sortKey: 'COMPANY' },
        { key: 'brandName', label: 'Brand', sortKey: 'BRAND' },
        { key: 'searchQuery', label: 'Search Term', sortKey: 'SEARCH_QUERY' },
    ];

    useFailedActionSnackbar('retrieve', 'search terms', isGetByIdError);

    const handleSearchTermFilterChange = (updatedFilters: ISearchTermFilterOptions) => {
        setSearchTermFilters(updatedFilters);
    };

    const handleAddClicked = () => {
        setSelectedSearchTerm(defaultSearchTerm);
    };

    const handleImportClicked = () => {
        setIsImportVisible(true);
    };

    const handleFilterClicked = () => {
        setIsFilterDialogVisible(true);
    };

    const handleEditClicked = (searchTermId: string) => {
        getSearchTermById(searchTermId).then((result) => {
            if (result.data) {
                setSelectedSearchTerm(result.data);
            }
        });
    };

    const getFilterChips = (): IFilterChip[] => {
        const filterChips: IFilterChip[] = [];
        if (searchTermFilters.workflowId !== emptyGuid) {
            filterChips.push({
                key: CHIP_KEYS.workflow,
                text: searchTermFilters.companyName,
            });
        }
        if (searchTermFilters.brandId !== emptyGuid) {
            filterChips.push({
                key: CHIP_KEYS.brand,
                text: searchTermFilters.brandName,
            });
        }
        return filterChips;
    };

    const handleRemoveFilterChip = (filterChipKey: string) => {
        switch (filterChipKey) {
            case CHIP_KEYS.workflow:
                setSearchTermFilters({
                    ...searchTermFilters,
                    workflowId: emptyGuid,
                    companyName: '',
                });
                break;
            case CHIP_KEYS.brand:
                setSearchTermFilters({
                    ...searchTermFilters,
                    brandId: emptyGuid,
                    brandName: '',
                });
                break;
            default:
                console.warn('Invalid filter chip key provided: ', filterChipKey);
                break;
        }
    };

    return (
        <Grid container direction='column' spacing={3} padding={2}>
            <StandardListView
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetSearchTermsQuery}
                archiveMutation={useBulkArchiveSearchTermMutation}
                recoverMutation={useBulkUnarchiveSearchTermMutation}
                tableColumns={tableColumns}
                entityNameSingular='Search Term'
                permissionName='searchTerms'
                handleAdd={handleAddClicked}
                handleEdit={handleEditClicked}
                headerIcon={<Search />}
                headerTitle='Search Terms'
                breadCrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Admin', navLink: '', isText: true },
                ]}
                handleImportClicked={handleImportClicked}
                handleFilterClicked={handleFilterClicked}
                filterChips={getFilterChips()}
                additionalQueryParameters={{
                    workflowId: searchTermFilters.workflowId,
                    brandId: searchTermFilters.brandId,
                }}
                handleFilterChipDelete={handleRemoveFilterChip}
            />
            <SearchTermFilterDialog
                isVisible={isFilterDialogVisible}
                onClose={() => setIsFilterDialogVisible(false)}
                filterProps={searchTermFilters}
                setFilterProps={handleSearchTermFilterChange}
            />
            <SearchTermFormDialog selectedSearchTerm={selectedSearchTerm} onClearSelected={() => setSelectedSearchTerm(null)} />
            <ImportSearchTermsDialog
                key={'import-search-terms'}
                isImportVisible={isImportVisible}
                onClose={() => setIsImportVisible(false)}
            />
        </Grid>
    );
};
