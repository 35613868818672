import { FilterList } from "@mui/icons-material";
import { Autocomplete, Button, debounce, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers'
import { useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { AsinDto, SearchTermDto, WorkflowConfigBrandDto, WorkflowConfigDto } from "../../dtos";
import { useGetSearchTermsQuery } from "../../store/apis/search-term-api";
import { useGetAsinsQuery } from "../../store/apis/asin-api";
import { SearchResultFilterProps } from "./types";
import { CompanyBrandCountrySelect } from "../../Components/Forms/CustomFormSections";
import { emptyGuid } from "../../util";
import { useGetRunDatesQuery } from "../../store/apis/search-result-api";

export const SearchResultFilterDialog: FC<SearchResultFilterProps> = (props) => {
    const {
        isVisible,
        onClose,
        runDateFilter,
        setRunDateFilter,
        searchTermFilter,
        setSearchTermFilter,
        asinFilter,
        setAsinFilter,
        companyFilter,
        setCompanyFilter,
        brandFilter,
        setBrandFilter
    } = props;
    const [selectedDate, setSelectedDate] = useState<Date | null>(runDateFilter ?? null);
    const [selectedSearchTerm, setSelectedSearchTerm] = useState<SearchTermDto | null>(searchTermFilter ?? null);
    const [selectedAsin, setSelectedAsin] = useState<AsinDto | null>(asinFilter ?? null);
    const [searchTermSearchText, setSearchTermSearchText] = useState('');
    const [searchTermQueryText, setSearchTermQueryText] = useState('');
    const [asinSearchText, setAsinSearchText] = useState('');
    const [asinQueryText, setAsinQueryText] = useState('');
    const [selectedCompany, setSelectedCompany] = useState<WorkflowConfigDto | null>(companyFilter ?? null);
    const [selectedBrand, setSelectedBrand] = useState<WorkflowConfigBrandDto | null>(brandFilter ?? null);
    const { data: searchTerms, isLoading: isLoadingSearchTerms, isFetching: isFetchingSearchTerms, isError: isErrorGettingSearchTerms } = useGetSearchTermsQuery({
        searchText: searchTermQueryText,
        sortKey: 'SEARCH_QUERY',
        page: 0,
        pageSize: 50,
        includeInactive: false
    });

    const { data: asins, isLoading: isLoadingAsins, isFetching: isFetchingAsins, isError: isErrorGettingAsins } = useGetAsinsQuery({
        searchText: asinQueryText,
        sortKey: 'ECOMMERCE_ASIN',
        page: 0,
        pageSize: 50,
        includeInactive: false
    });

    const { data: reportRunDates, isLoading: isLoadingRunDates, isError: isErrorGettingRunDates } = useGetRunDatesQuery();


    useFailedActionSnackbar('retrieving', 'search terms', isErrorGettingSearchTerms);
    useFailedActionSnackbar('retrieving', 'ASINs', isErrorGettingAsins);
    useFailedActionSnackbar('retrieving', 'Run Dates', isErrorGettingRunDates);

    useEffect(() => {
        setSelectedDate(runDateFilter ?? null);
        setSelectedSearchTerm(searchTermFilter ?? null);
        setSelectedAsin(asinFilter ?? null);
        setSelectedCompany(companyFilter ?? null);
        setSelectedBrand(brandFilter ?? null);
    }, [runDateFilter, searchTermFilter, asinFilter, companyFilter, brandFilter])

    const handleClose = () => {
        setSearchTermSearchText('');
        setAsinSearchText('');
        onClose();
    };

    const handleFilter = () => {
        setRunDateFilter(selectedDate);
        setSearchTermFilter(selectedSearchTerm);
        setAsinFilter(selectedAsin);
        setCompanyFilter(selectedCompany);
        setBrandFilter(selectedBrand);

        handleClose();
    };

    const handleClear = () => {
        setSelectedDate(null);
        setSelectedSearchTerm(null);
        setSelectedAsin(null);
        setSelectedCompany(null);
        setSelectedBrand(null);
    };

    const handleSelectDate = (newDate: Date | null | undefined) => {
        console.log('date change: ', newDate)
        setSelectedDate(newDate ?? null);
    };

    const handleSelectSearchTerm = (_: any, newTerm: SearchTermDto | null) => {
        if (newTerm) {
            setSelectedSearchTerm(newTerm);
        } else {
            setSelectedSearchTerm(null);
        }
    }

    const handleSelectAsin = (_: any, newAsin: AsinDto | null) => {
        if (newAsin) {
            setSelectedAsin(newAsin);
        } else {
            setSelectedAsin(null);
        }
    }

    // eslint-disable-next-line
    const debouncedSearchTermSearchInputHandler = useCallback(debounce(setSearchTermQueryText, 500), []);
    // eslint-disable-next-line
    const debouncedAsinSearchInputHandler = useCallback(debounce(setAsinQueryText, 500), []);

    useEffect(() => {
        debouncedSearchTermSearchInputHandler(searchTermSearchText)
    }, [searchTermSearchText, debouncedSearchTermSearchInputHandler])

    useEffect(() => {
        debouncedAsinSearchInputHandler(asinSearchText)
    }, [asinSearchText, debouncedAsinSearchInputHandler])

    return (
        <Dialog
            open={isVisible}
            onClose={handleClose}
            PaperProps={{ sx: { width: '100%' } }}
        >
            <DialogTitle>
                <Typography variant='h2'>
                    {<FilterList />} {'Filter Report'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' pt={3} spacing={2}>
                    <Grid item>
                        <Grid item xs={6}>
                            <FormLabel disabled={isLoadingRunDates}>Run Date</FormLabel>
                            <DatePicker
                                disabled={isLoadingRunDates}
                                value={selectedDate}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                onChange={(e: any) => {
                                    handleSelectDate(e?.$d);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        disabled
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        sx={{ backgroundColor: '#FFFFFF' }}
                                    />
                                )}
                                shouldDisableDate={(day: any) => {
                                    const date: Date | undefined = day?.$d;
                                    if (!date || !reportRunDates) {
                                        return true;
                                    }
                                    return !reportRunDates?.some((reportRunDate) => new Date(reportRunDate.runDate).toUTCString() === date.toUTCString()) ?? true;
                                }}
                                shouldDisableYear={(year: any) => {
                                    const date: Date | undefined = year?.$d;
                                    if (!date || !reportRunDates) {
                                        return true;
                                    }

                                    return (
                                        !reportRunDates?.some((reportRunDate) => new Date(reportRunDate.runDate).getUTCFullYear() === date.getUTCFullYear()) ??
                                        true
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent={'space-between'}>
                        <Grid item xs={6}>
                            <FormControl fullWidth disabled={isLoadingSearchTerms}>
                                <FormLabel>Search Term</FormLabel>
                                <Autocomplete
                                    loading={isFetchingSearchTerms || searchTermSearchText !== searchTermQueryText}
                                    value={selectedSearchTerm}
                                    onChange={handleSelectSearchTerm}
                                    options={searchTerms?.pageResults ?? []}
                                    getOptionLabel={(option) => option.searchQuery}
                                    isOptionEqualToValue={(option, value) => option.searchQuery === value.searchQuery}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputValue={searchTermSearchText}
                                    onInputChange={(_, newInputValue) => {
                                        setSearchTermSearchText(newInputValue);
                                    }}
                                ></Autocomplete>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ paddingLeft: '15px' }}>
                            <FormControl fullWidth disabled={isLoadingAsins}>
                                <FormLabel>ASIN</FormLabel>
                                <Autocomplete
                                    loading={isFetchingAsins || asinSearchText !== asinQueryText}
                                    value={selectedAsin}
                                    onChange={handleSelectAsin}
                                    options={asins?.pageResults ?? []}
                                    getOptionLabel={(option) => option.ecommerceAsin}
                                    isOptionEqualToValue={(option, value) => option.ecommerceAsin === value.ecommerceAsin}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputValue={asinSearchText}
                                    onInputChange={(_, newInputValue) => {
                                        setAsinSearchText(newInputValue);
                                    }}
                                ></Autocomplete>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <Grid item xs={12}>
                            <CompanyBrandCountrySelect
                                selectedCompanyId={selectedCompany?.workflowId ?? emptyGuid}
                                selectedBrandId={selectedBrand?.id ?? emptyGuid}
                                setSelectedCompany={setSelectedCompany}
                                setSelectedBrand={setSelectedBrand}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClear} size='large'>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog >
    );
};