import { IAsinSearchQueryOptions } from '../Views/Asin/AsinFilterDialog';

type RouteProps = SearchQueryParams & SearchResultQueryParams & ShelfSpaceQueryParams & BrandAnalyticsQueryParams & IAsinSearchQueryOptions;

function createKeys(keyRecord: Record<keyof RouteProps, any>): (keyof RouteProps)[] {
    return Object.keys(keyRecord) as any;
}

export const QueryRouteProps = createKeys({
    searchText: undefined,
    sortKey: undefined,
    sortAsc: undefined,
    page: undefined,
    pageSize: undefined,
    includeInactive: undefined,
    includeOnlyInstallingContractors: undefined,
    runDate: undefined,
    searchTermId: undefined,
    asin: undefined,
    companyId: undefined,
    brandId: undefined,
    countryId: undefined,
    weekEnding: undefined,
    workflowId: undefined,
    searchQuery: undefined,
});

export interface SearchQueryParams {
    searchText?: string;
    sortKey?: string;
    sortAsc?: boolean;
    page?: number;
    pageSize?: number;
    includeInactive?: boolean;
    includeOnlyInstallingContractors?: boolean;
}

export interface SearchResultQueryParams extends SearchQueryParams {
    runDate?: string;
    searchTermId?: string;
    asin?: string;
    workflowId?: string;
    brandId?: string;
}

export interface ShelfSpaceQueryParams extends SearchQueryParams {
    searchTermId?: string;
    workflowId?: string;
    brandId?: string;
}

export interface BrandAnalyticsChartsQueryParams extends SearchQueryParams {
    companyId?: string;
    brandId?: string;
    countryId?: string;
    searchQuery?: string;
}

export interface BrandAnalyticsQueryParams extends BrandAnalyticsChartsQueryParams {
    weekEnding?: string;
}

export interface SupportViewQueryParams extends SearchQueryParams {
    companyId?: string;
    brandId?: string;
}
