import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export const getErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
    if (error && 'data' in error) {
        if (typeof (error.data) === 'string') {
            return error.data;
        } else {
            return (error.data as any).detail as string;
        }
    } else {
        return 'An unhandled error has occurred.'
    }
}