import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthTokenState {
    token: string
}

const initialState: AuthTokenState = {
    token: ''
};

export const authTokenSlice = createSlice({
    name: 'authToken',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        }
    }
});

export const { setToken } = authTokenSlice.actions;