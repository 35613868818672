import { Grid, Typography, FormControlLabel, Switch, useTheme } from '@mui/material';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { DataTableColumn, LocalDataTable } from '../../CoreLib/library';
import { GENERIC_IMPORT_ROW_STATUSES } from './types';

export interface IImportPreviewTableProps {
    importData: any[];
    tableColumns: DataTableColumn<any>[];
    localValidationMethod: (row: any) => string;
}

export const ImportPreviewTable: FC<IImportPreviewTableProps> = (props) => {
    const { importData, tableColumns, localValidationMethod } = props;
    const [isErrorOnly, setIsErrorOnly] = useState(false);
    const theme = useTheme();

    const handleErrorVisibilityToggle = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsErrorOnly(checked)
    }

    const validatedImportData = useMemo(() => {
        return importData.map(importRow => {
            importRow.status = localValidationMethod(importRow);
            return importRow;
        })
    }, [importData, localValidationMethod]);

    const filteredImportData = useMemo(() => {
        if (isErrorOnly) {
            return validatedImportData.filter(importRow => importRow.status !== GENERIC_IMPORT_ROW_STATUSES.GOOD)
        } else {
            return validatedImportData;
        }
    }, [validatedImportData, isErrorOnly]);

    const mapStatus = (row: any) => {
		if (row.status === GENERIC_IMPORT_ROW_STATUSES.GOOD) {
			return row.status;
		}
		return <span style={{ color: theme.palette.error.main }}>{row.status}</span>;
	};

    const localDataTableHeaders: DataTableColumn<any>[] = [...tableColumns, {
        key: 'status',
        label: 'Status',
        sortKey: 'STATUS',
        fieldMapper: (row) => mapStatus(row)
    }];

    return (
        <Grid>
            <Grid>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>Preliminary Validation Results</Typography>
                    <FormControlLabel
                        control={<Switch checked={isErrorOnly} onChange={handleErrorVisibilityToggle} />}
                        label='Show Invalid Only'
                        labelPlacement='start'
                    />
                </div>
            </Grid>
            <Grid>
                <LocalDataTable columns={localDataTableHeaders} rowData={filteredImportData} height={360} dense />
            </Grid>
        </Grid>
    );
};
