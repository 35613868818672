import { FC } from 'react';
import { IImportRowDto, ImportAsinsRowDto, ImportType } from '../../dtos';
import { useImportAsinNumbersMutation } from '../../store/apis/import-api';
import { DataTableColumn } from '../../Components/Core/LocalDataTable';
import { StandardImportModal } from '../../Components/Forms/StandardImportModal';
import { GENERIC_IMPORT_ROW_STATUSES, ImportModalProps } from '../../Components/Forms/StandardImportModal/types';

const IMPORT_ROW_STATUSES = {
	...GENERIC_IMPORT_ROW_STATUSES,
	MISSING_COMPANY: 'Missing Company',
	MISSING_BRAND: 'Missing Brand',
	MISSING_ASIN: 'Missing ASIN'
}

export const ImportAsinsDialog: FC<ImportModalProps> = (props) => {
	const { isImportVisible, onClose } = props;

	const rowValidation = (data: ImportAsinsRowDto) => {
		if (!data.company ) {
			return IMPORT_ROW_STATUSES.MISSING_COMPANY;
		} else if (!data.brand ) {
			return IMPORT_ROW_STATUSES.MISSING_BRAND;
		} else if (!data.asin ) {
			return IMPORT_ROW_STATUSES.MISSING_ASIN;
		}
		return IMPORT_ROW_STATUSES.GOOD;
	}

	const tableColumns: DataTableColumn<IImportRowDto>[] = [
		{ key: 'company', label: 'Company', sortKey: 'COMPANY' },
		{ key: 'brand', label: 'Brand', sortKey: 'BRAND' },
		{ key: 'asin', label: 'ASIN', sortKey: 'ASIN' },
		{ key: 'sku', label: 'SKU', sortKey: 'SKU' },
	];

	return (
		<StandardImportModal
			entityNamePlural='ASINs'
			sampleCsvFileName='sample-asins.csv'
			requiredHeaders={['company', 'brand', 'asin', 'sku']}
			tableColumns={tableColumns}
			localValidationMethod={rowValidation}
			importMutation={useImportAsinNumbersMutation}
			importType={ImportType.Asin}
			isVisible={isImportVisible}
			onClose={onClose}
		/>
	);
};
