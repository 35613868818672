import { serverApi } from '..';
import { CountryDto } from '../../dtos';
import { BaseApi } from './base-api';

const countryApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        var baseApi = new BaseApi<CountryDto>('Country', 'Country', builder);
        return {
            getCountries: baseApi.getAll(),
            getCountry: baseApi.getById(),
            createCountry: baseApi.create(),
            updateCountry: baseApi.update(),
            archiveCountry: baseApi.archive(),
            unarchiveCountry: baseApi.unarchive(),
            bulkArchiveCountry: baseApi.bulkArchive(),
            bulkUnarchiveCountry: baseApi.bulkUnarchive(),
        }
    },
});

export const {
    useGetCountriesQuery,
    useGetCountryQuery,
    useCreateCountryMutation,
    useUpdateCountryMutation,
    useArchiveCountryMutation,
    useUnarchiveCountryMutation,
    useBulkArchiveCountryMutation,
    useBulkUnarchiveCountryMutation
} = countryApi;
