import { serverApi } from "..";
import { CompanyBrandSummaryDto, WorkflowConfigBrandDto, WorkflowConfigDto } from "../../dtos";
import { BaseApi } from "./base-api";

const workflowConfigApi = serverApi.injectEndpoints({
  endpoints: (builder) => {
    var baseApi = new BaseApi<WorkflowConfigDto>(
      "WorkflowConfig",
      "WorkflowConfig",
      builder
    );
    return {
      getWorkflowConfigs: baseApi.getAll(),
      getWorkflowConfig: baseApi.getById(),
      getBrandsForWorkflowConfig: builder.query<WorkflowConfigBrandDto[], string>({
        query: (id) => ({ url: `${baseApi.route}/${id}/brands` }),
        providesTags: [{ type: "WorkflowConfigBrand" }]
      }),
      createWorkflowConfig: baseApi.create(),
      updateWorkflowConfig: baseApi.update(),
      archiveWorkflowConfig: baseApi.archive(),
      unarchiveWorkflowConfig: baseApi.unarchive(),
      bulkArchiveWorkflowConfig: baseApi.bulkArchive(),
      bulkUnarchiveWorkflowConfig: baseApi.bulkUnarchive(),
      getCurrentUsersTotalMonthlyEstimate: builder.query<number, void>({
        query: () => ({ url: `${baseApi.route}/monthlyEstimate` }),
        providesTags: [{ type: "WorkflowConfig" }],
      }),
      getCurrentUsersCompanyBrandSummary: builder.query<CompanyBrandSummaryDto[], void>({
        query: () => ({ url: `${baseApi.route}/companyBrandSummary` }),
        providesTags: [{ type: "WorkflowConfig" }, { type: "SearchTerm" }, { type: "Asin" }, { type: "WorkflowConfigBrand" }],
      }),
    };
  },
});

export const {
  useGetWorkflowConfigsQuery,
  useGetWorkflowConfigQuery,
  useLazyGetWorkflowConfigQuery,
  useCreateWorkflowConfigMutation,
  useUpdateWorkflowConfigMutation,
  useArchiveWorkflowConfigMutation,
  useUnarchiveWorkflowConfigMutation,
  useBulkArchiveWorkflowConfigMutation,
  useBulkUnarchiveWorkflowConfigMutation,
  useGetCurrentUsersTotalMonthlyEstimateQuery,
  useGetBrandsForWorkflowConfigQuery,
  useGetCurrentUsersCompanyBrandSummaryQuery
} = workflowConfigApi;
