import { FilterList } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid, Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CompanyBrandCountrySelect } from '../../Components/Forms/CustomFormSections';
import { WorkflowConfigBrandDto, WorkflowConfigDto } from '../../dtos';
import { emptyGuid } from '../../util';

export interface ISearchTermFilterOptions {
    workflowId: string;
    companyName: string;
    brandId: string;
    brandName: string;
}

export interface ISearchTermFilterDialogProps {
    isVisible: boolean;
    onClose: () => void;
    filterProps: ISearchTermFilterOptions;
    setFilterProps: (updatedFilters: ISearchTermFilterOptions) => void;
}

export const SearchTermFilterDialog: FC<ISearchTermFilterDialogProps> = (props) => {
    const { isVisible, onClose, filterProps, setFilterProps } = props;
    const [searchTermFilters, setSearchTermFilters] = useState<ISearchTermFilterOptions>(filterProps);
    useEffect(() => {
        if (isVisible) {
            setSearchTermFilters(filterProps);
        }
    }, [filterProps, isVisible]);

    const isFormClean = filterProps.workflowId === searchTermFilters.workflowId && filterProps.brandId === searchTermFilters.brandId;

    const handleClose = () => {
        onClose();
    };

    const handleFilter = () => {
        setFilterProps({ ...searchTermFilters });
        handleClose();
    };

    const handleClear = () => {
        setSearchTermFilters({
            workflowId: emptyGuid,
            companyName: '',
            brandId: emptyGuid,
            brandName: '',
        });
    };

    const handleCompanyChange = (selectedCompany: WorkflowConfigDto | null) => {
        if (selectedCompany === null) {
            setSearchTermFilters({
                ...searchTermFilters,
                workflowId: emptyGuid,
                companyName: '',
            })
        } else {
            setSearchTermFilters({
                ...searchTermFilters,
                workflowId: selectedCompany.workflowId,
                companyName: selectedCompany.companyName,
            })
        }
    };

    const handleBrandChange = (selectedBrand: WorkflowConfigBrandDto | null) => {
        if (selectedBrand === null) {
            setSearchTermFilters({
                ...searchTermFilters,
                brandId: emptyGuid,
                brandName: '',
            });
        } else {
            setSearchTermFilters({
                ...searchTermFilters,
                brandId: selectedBrand.id,
                brandName: selectedBrand.brandName,
            });
        }
    };

    return (
        <Dialog open={isVisible} onClose={handleClose} PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>
                    {<FilterList />} {'Filter Search Terms'}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3 }}>
                <CompanyBrandCountrySelect
                    selectedCompanyId={searchTermFilters.workflowId}
                    selectedBrandId={searchTermFilters.brandId}
                    setSelectedCompany={handleCompanyChange}
                    setSelectedBrand={handleBrandChange}
                />
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClear} size='large'>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormClean ? 'Close' : 'Cancel'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
