import { CloudUpload } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { ChangeEvent, DragEvent, FC } from 'react';

export interface ICsvFilePickerProps {
    selectedFile: File | null;
    setSelectedFile: (selectedFile: File | null) => void;
}

export const CsvFilePicker: FC<ICsvFilePickerProps> = props => {
    const { selectedFile, setSelectedFile } = props;

    const setFileFromFileList = (files: FileList) => {
        if (files.length === 0) {
            console.warn('No files found on drop event');
        } else if (files.length > 1) {
            console.warn('more than one file found on drop event');
        } else {
            const file = files.item(0);
            if (!file) {
                console.warn('failed to retrieve file from event');
                return;
            }
            if (file.type !== 'text/csv') {
                console.warn('unsupported file type found');
                return;
            }
            setSelectedFile(file);
        }
    }

    const handleFileDialogUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            console.warn('no files found from file selector dialog');
            return;
        }
        setFileFromFileList(event.target.files);
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();    
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setFileFromFileList(event.dataTransfer.files);
    }

    return (
        <Grid item container direction='column' spacing={2}>
            <Grid
                item
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <label htmlFor='choose-file'>
                    <input name='choose-file' id='choose-file' type='file' accept='.csv' style={{ display: 'none' }} onChange={handleFileDialogUpload} />
                    <Box sx={{
                        width: '100%',
                        height: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        gap: '16px',
                        border: '2px dashed lightgray',
                        backgroundColor: '#eee',
                        cursor: 'pointer'
                    }}
                    >
                        <Typography>
                            Upload File
                        </Typography>
                        <CloudUpload fontSize='large' />
                    </Box>
                </label>
            </Grid>
            <Grid item>
                Selected File: <Typography component='span' fontWeight='bold'>{selectedFile?.name ?? 'None'}</Typography>
            </Grid>
        </Grid>
    )
}