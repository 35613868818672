import { FunctionComponent, useState } from 'react';
import {
    Autocomplete,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import { Tune } from '@mui/icons-material';
import { WorkflowConfigDto } from '../../../dtos';
import { SxProps } from '@mui/system';
import { formatCurrency } from '../../../util';
import { IWorkflowConfigFormProps } from './types';
import { TextVariants, NavBreadcrumbs, FormSection } from '../../CoreLib/library';

const defaultClient: WorkflowConfigDto = {
    id: '',
    runInterval: 0,
    clientName: '',
    freeMonthlyCredit: -1,
    perSearchFee: -1,
    workflowConfigCountries: [],
    isActive: true,
    workflowId: '',
    companyName: '',
    searchTermCount: 0,
    countryCount: 0,
    runsPerMonth: 0,
    searchesPerMonth: 0,
    billableSearchesPerMonth: 0,
    monthlyEstimatedCost: 0,
    createdOn: new Date()
};

export const WorkflowConfigForm: FunctionComponent<IWorkflowConfigFormProps> = (props) => {
    const { save, cancel, initValues, allCountries, totalSearchTermCount, isSaving } = props;
    const [fieldErrors, setFieldErrors] = useState({
        RUN_INTERVAL: '',
        COUNTRIES: '',
    });
    const [initClient, setInitClient] = useState(initValues ? initValues : defaultClient);
    const [currentClient, setCurrentClient] = useState(initValues ? initValues : defaultClient);
    const [changed, setFormChanged] = useState(false);
    const [countrySearchText, setCountrySearchText] = useState('');

    const handleAddChip = (countryId: string) => {
        setFormChanged(true);
        var updatedWorkflowConfig = { ...currentClient };
        var newWorkflowConfigCountryList = [...currentClient.workflowConfigCountries];
        newWorkflowConfigCountryList.push({
            workflowConfigId: currentClient.id,
            countryId: countryId
        });
        updatedWorkflowConfig.workflowConfigCountries = newWorkflowConfigCountryList;
        setCurrentClient(updatedWorkflowConfig);
        setCountrySearchText('');
    };

    const handleRemoveChip = (countryId: string) => {
        setFormChanged(true);
        var updatedWorkflowConfig = { ...currentClient };
        var newWorkflowConfigCountryList = updatedWorkflowConfig.workflowConfigCountries.filter((c) => c.countryId !== countryId);
        updatedWorkflowConfig.workflowConfigCountries = newWorkflowConfigCountryList;
        setCurrentClient(updatedWorkflowConfig);
        setCountrySearchText('');
    };

    const compareClients = (compare: WorkflowConfigDto, comparedTo: WorkflowConfigDto) => {
        return (
            comparedTo.clientName === compare.clientName &&
            comparedTo.runInterval === compare.runInterval &&
            comparedTo.workflowConfigCountries === compare.workflowConfigCountries
        );
    };

    const intervalOptions = [
        { value: 1, name: 'Daily' },
        { value: 2, name: 'Weekly' },
        { value: 3, name: 'Twice per Month' },
        { value: 4, name: 'Once per Month' },
    ];

    const handleRunIntervalChange = (event: SelectChangeEvent) => {
        setFormChanged(true);
        setCurrentClient({
            ...currentClient,
            runInterval: Number(event.target.value),
        });
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'RUN_INTERVAL') {
            if (currentClient.runInterval) {
                fieldErrors.RUN_INTERVAL = '';
                isValid = true;
            } else {
                fieldErrors.RUN_INTERVAL = 'Run Interval is required';
                isValid = false;
            }
        }
        if (fieldName === 'COUNTRIES') {
            if (currentClient.workflowConfigCountries.length > 0) {
                fieldErrors.COUNTRIES = '';
                isValid = true;
            } else {
                fieldErrors.COUNTRIES = 'At least one Country must be selected';
                isValid = false;
            }
        }
        setFieldErrors({
            RUN_INTERVAL: fieldErrors.RUN_INTERVAL,
            COUNTRIES: fieldErrors.COUNTRIES,
        });
        return isValid;
    };

    const formIsValid = () => {
        return validate('RUN_INTERVAL') && validate('COUNTRIES');
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save(currentClient);
            setInitClient(currentClient);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const getRunsPerMonth = () => {
        switch (currentClient.runInterval) {
            case 0:
                return 0;
            case 1:
                return 30;
            case 2:
                return 4;
            case 3:
                return 2;
            case 4:
                return 1;
            default:
                return 0;
        }
    };

    const getTotalSearchesPerMonth = () => {
        return totalSearchTermCount * currentClient.workflowConfigCountries.length * getRunsPerMonth();
    };

    const getBillableSearchesPerMonth = () => {
        var result = getTotalSearchesPerMonth() - currentClient.freeMonthlyCredit;
        return Math.max(result, 0);
    };

    const getTotalMonthlyEstimate = () => {
        return getBillableSearchesPerMonth() * currentClient.perSearchFee;
    };

    const renderLineItem = (label: string, value: string, textVariant: TextVariants = 'body1', additionalStyles?: SxProps<Theme>) => (
        <Grid item container direction='row' sx={{ justifyContent: 'space-between', margin: '2px', fontSize: '20px', padding: '6px', ...additionalStyles }}>
            <Typography variant={textVariant} sx={{ fontSize: '16px' }}>
                {label}
            </Typography>
            <Typography variant={textVariant} sx={{ fontSize: '16px' }}>
                {value}
            </Typography>
        </Grid>
    );

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Tune /> Configuration
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Admin', navLink: '', isText: true },
                        ]}
                        currentPageLabel='Configuration'
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4} sx={{ paddingRight: '24px' }}>
                    <Grid item>
                        {(changed || !initValues) && !compareClients(initClient, currentClient) ? (
                            <Button fullWidth variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </Button>
                        ) : (
                            <Button fullWidth variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button fullWidth variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isSaving}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ padding: '24px' }}>
                <Grid item xs={12} md={6} lg={8}>
                    <FormSection>
                        <Grid item container direction='row'>
                            <Grid item xs={4}>
                                <FormControl fullWidth required>
                                    <FormLabel>Run Interval</FormLabel>
                                    <Select
                                        value={currentClient.runInterval.toString()}
                                        onChange={handleRunIntervalChange}
                                        onBlur={onFieldBlur('RUN_INTERVAL')}>
                                        <MenuItem key='none' value={0} sx={{ height: '36px' }}></MenuItem>
                                        {intervalOptions.map((intervalOption) => (
                                            <MenuItem key={intervalOption.value} value={intervalOption.value}>
                                                {intervalOption.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldErrors.RUN_INTERVAL}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' alignItems='start'>
                            <Grid item container direction='column' justifyContent='start' xs={6}>
                                <FormControl fullWidth required>
                                    <FormLabel>Countries</FormLabel>
                                    <Autocomplete
                                        freeSolo
                                        id='respondants-dropdown'
                                        options={allCountries
                                            .filter((x) => !currentClient.workflowConfigCountries.find((y) => y.countryId === x.id))
                                            .map((row) => {
                                                return { label: row.name, value: row.id };
                                            })}
                                        value={''}
                                        sx={{ pr: 6 }}
                                        inputValue={countrySearchText}
                                        onChange={(event: any, newValue: any | null) => {
                                            if (newValue) {
                                                handleAddChip(newValue.value);
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setCountrySearchText(newInputValue);
                                        }}
                                        onKeyDown={(event: any) => {
                                            if (event.key === 'Enter') {
                                                event.defaultMuiPrevented = true;
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        onBlur={onFieldBlur('COUNTRIES')}
                                    />
                                    <FormHelperText>{fieldErrors.COUNTRIES}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item container spacing={1} sx={{ pt: 2.5 }} xs={6}>
                                {currentClient.workflowConfigCountries.map((countryMapping) => {
                                    var country = allCountries.find((c) => c.id === countryMapping.countryId);
                                    if (!country) {
                                        return null;
                                    }
                                    return (
                                        <Grid item xs='auto' key={country.id}>
                                            <Chip
                                                label={country!.name}
                                                size='small'
                                                variant='filled'
                                                color='secondary'
                                                onDelete={() => handleRemoveChip(country!.id)}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper elevation={0} sx={{ border: 'solid 1px #AAAAAA', padding: '24px' }}>
                        <Grid item container direction='row' sx={{ justifyContent: 'space-between', margin: '8px', fontSize: '20px' }}>
                            <Typography variant='h1' sx={{ fontSize: '20px' }}>
                                Estimated Monthly Cost
                            </Typography>
                        </Grid>
                        {renderLineItem('Search Terms', totalSearchTermCount.toLocaleString())}
                        {renderLineItem('# of Countries', `x ${currentClient.workflowConfigCountries.length}`)}
                        {renderLineItem('Runs per Month', `x ${getRunsPerMonth()}`)}
                        {renderLineItem('Total Searches per Month', getTotalSearchesPerMonth().toLocaleString(), 'h1')}
                        {currentClient.freeMonthlyCredit > 0 && renderLineItem('Free Monthly Credit', currentClient.freeMonthlyCredit.toLocaleString())}
                        {currentClient.freeMonthlyCredit > 0 && renderLineItem('Billable Searches per Month', getBillableSearchesPerMonth().toLocaleString())}
                        {renderLineItem('Fee per Search', `x ${formatCurrency(currentClient.perSearchFee)}`)}
                        {renderLineItem('TOTAL MONTHLY ESTIMATE', formatCurrency(getTotalMonthlyEstimate()), 'h1', { backgroundColor: '#F5F542' })}
                        {renderLineItem('This estimate is an approximation and is not guaranteed. The estimate is based on the information above. Final cost may change based on actual searches per month.', '', 'body2', { fontStyle: 'italic', marginTop: '30px' })}
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};
