import { QueryRouteProps } from '../store';
import { emptyGuid } from './constants';

const addQueryParameter = (currentRoute: string, params: any, propertyName: string): string => {
    if (currentRoute.indexOf('?') < 0) {
        currentRoute = currentRoute + '?';
    }

    if (params[propertyName] !== undefined && params[propertyName] !== emptyGuid) {
        return `${currentRoute}&${propertyName}=${params[propertyName]}`;
    }

    return currentRoute;
};
export const buildSearchQueryRoute = (baseRoute: string, params: any) => {
    let route = baseRoute;
    QueryRouteProps.forEach((prop) => {
        route = addQueryParameter(route, params, prop);
    });

    return { url: route };
};
