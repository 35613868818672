import { serverApi } from '..';
import { SearchTermDto } from '../../dtos';
import { BaseApi } from './base-api';

const searchTermApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        var baseApi = new BaseApi<SearchTermDto>('SearchTerm', 'SearchTerm', builder);
        return {
            getSearchTerms: baseApi.getAll(),
            getSearchTerm: baseApi.getById(),
            createSearchTerm: baseApi.create(),
            updateSearchTerm: baseApi.update(),
            archiveSearchTerm: baseApi.archive(),
            unarchiveSearchTerm: baseApi.unarchive(),
            bulkArchiveSearchTerm: baseApi.bulkArchive(),
            bulkUnarchiveSearchTerm: baseApi.bulkUnarchive(),
        };
    },
});

export const {
    useGetSearchTermsQuery,
    useLazyGetSearchTermsQuery,
    useGetSearchTermQuery,
    useLazyGetSearchTermQuery,
    useCreateSearchTermMutation,
    useUpdateSearchTermMutation,
    useArchiveSearchTermMutation,
    useUnarchiveSearchTermMutation,
    useBulkArchiveSearchTermMutation,
    useBulkUnarchiveSearchTermMutation,
} = searchTermApi;
