import { Settings } from '@mui/icons-material';
import { Chip, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RunInterval } from '../../../dtos';
import { useGetCountriesQuery } from '../../../store/apis/country-api';
import { useCreateWorkflowConfigMutation, useUpdateWorkflowConfigMutation } from '../../../store/apis/workflow-config-api';
import { emptyGuid } from '../../../util/constants';
import { LoadingIndicator, StandardFormDialog, useFailedActionSnackbar, useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../CoreLib/library';
import { IWorkflowConfigFormDialogProps } from './types';
import { DEFAULT_WORKFLOW_CONFIG } from './utils';

export const WorkflowConfigFormDialog: FC<IWorkflowConfigFormDialogProps> = (props) => {
    const { selectedWorkflowConfig, onClearSelected } = props;
    const {
        data: countryData,
        isLoading: isLoadingCountries,
        isError: isCountryError
    } = useGetCountriesQuery({
        searchText: '',
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });
    const [fieldErrors, setFieldErrors] = useState({
        RUN_INTERVAL: '',
        COUNTRIES: '',
    });
    const [currentWorkflowConfig, setCurrentWorkflowConfig] = useState(DEFAULT_WORKFLOW_CONFIG);
    const [changed, setFormChanged] = useState(false);
    const [createWorkflowConfig, { isSuccess: isCreateSuccessful, isError: isCreateError, isLoading: isCreateLoading, reset: resetCreate }] =
        useCreateWorkflowConfigMutation();
    const [updateWorkflowConfig, { isSuccess: isUpdateSuccessful, isError: isUpdateError, isLoading: isUpdateLoading, reset: resetUpdate }] =
        useUpdateWorkflowConfigMutation();
    const isLoading = () => isCreateLoading || isUpdateLoading || isLoadingCountries;

    useSuccessfulCreateSnackbar('workflow config', isCreateSuccessful, resetCreate);
    useSuccessfulUpdateSnackbar('workflow config', isUpdateSuccessful, resetUpdate);
    useFailedCreateSnackbar('workflow config', isCreateError, resetCreate);
    useFailedUpdateSnackbar('workflow config', isUpdateError, resetUpdate);
    useFailedActionSnackbar('retrieving', 'available countries', isCountryError);

    useEffect(() => {
        if (selectedWorkflowConfig !== null) {
            setCurrentWorkflowConfig(selectedWorkflowConfig);
        }
    }, [selectedWorkflowConfig]);

    const handleReset = () => {
        setCurrentWorkflowConfig(DEFAULT_WORKFLOW_CONFIG);
    };

    const intervalOptions = [
        { value: RunInterval.Daily, name: 'Daily' },
        { value: RunInterval.Weekly, name: 'Weekly' },
        { value: RunInterval.Bimonthly, name: 'Twice per Month' },
        { value: RunInterval.Monthly, name: 'Once per Month' },
    ];

    const handleRunIntervalChange = (event: SelectChangeEvent) => {
        setFormChanged(true);
        setCurrentWorkflowConfig({
            ...currentWorkflowConfig,
            runInterval: Number(event.target.value),
        });
    };

    const toggleSelectedCountry = (countryId: string) => {
        setFormChanged(true);
        const isCurrentlySelected = currentWorkflowConfig.workflowConfigCountries.some(wcc => wcc.countryId === countryId);
        var updatedWorkflowConfig = { ...currentWorkflowConfig };
        var newWorkflowConfigCountryList = [...currentWorkflowConfig.workflowConfigCountries];
        if (isCurrentlySelected) {
            newWorkflowConfigCountryList = newWorkflowConfigCountryList.filter(wcc => wcc.countryId !== countryId);
        } else {
            newWorkflowConfigCountryList.push({
                workflowConfigId: currentWorkflowConfig.id,
                countryId: countryId
            })
        }
        updatedWorkflowConfig.workflowConfigCountries = newWorkflowConfigCountryList;
        setCurrentWorkflowConfig(updatedWorkflowConfig);
    }

    const getCountryChipColor = (countryId: string) => {
        const isCurrentlySelected = currentWorkflowConfig.workflowConfigCountries.some(wcc => wcc.countryId === countryId);
        return isCurrentlySelected ? 'secondary' : 'default';
    }

    const onFieldBlur = (fieldName: keyof(typeof fieldErrors)) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: keyof(typeof fieldErrors)) => {
        let isValid = false;
        if (fieldName === 'RUN_INTERVAL') {
            if (currentWorkflowConfig.runInterval) {
                fieldErrors.RUN_INTERVAL = '';
                isValid = true;
            } else {
                fieldErrors.RUN_INTERVAL = 'Run Interval is required';
                isValid = false;
            }
        }

        if (fieldName === 'COUNTRIES') {
            if (currentWorkflowConfig.workflowConfigCountries.length > 0) {
                fieldErrors.COUNTRIES = '';
                isValid = true;
            } else {
                fieldErrors.COUNTRIES = 'At least one Country must be selected';
                isValid = false;
            }
        }

        setFieldErrors({
            RUN_INTERVAL: fieldErrors.RUN_INTERVAL,
            COUNTRIES: fieldErrors.COUNTRIES,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('RUN_INTERVAL');
        isValid = isValid && validate('COUNTRIES');
        return isValid;
    };

    const handleSave = async (): Promise<boolean> => {
        if (formIsValid()) {
            let result = null;
            if (currentWorkflowConfig.id === emptyGuid) {
                result = await createWorkflowConfig(currentWorkflowConfig);
            } else {
                result = await updateWorkflowConfig(currentWorkflowConfig);
            }
            const hasData = Object.prototype.hasOwnProperty.call(result, 'data');
            return hasData;
        }
        return false;
    };

    const isNew = () => currentWorkflowConfig.id === emptyGuid;

    const renderCountryChips = () => {

        if (isLoadingCountries) {
            return <LoadingIndicator />
        }

        if (!countryData || countryData.pageResults.length === 0) {
            return <Typography>No Countries Available For Selection</Typography>
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                {(countryData.pageResults ?? []).map(country => <Chip label={country.name} onClick={() => toggleSelectedCountry(country.id)} key={country.id} color={getCountryChipColor(country.id)} />)}
            </div>
        )
    };

    return (
        <StandardFormDialog
            dialogProps={{
                open: selectedWorkflowConfig !== null,
                maxWidth: 'sm'
            }}
            icon={<Settings />}
            title='Company Settings'
            entityName='Company'
            isLoading={isLoading()}
            onSave={handleSave}
            onClose={onClearSelected}
            onReset={handleReset}
            isFormDirty={changed}
            isKeepOpenToggleVisible={isNew()}>
            <Grid container direction='row' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6' fontWeight='bold'>
                        { selectedWorkflowConfig?.companyName }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required error={!!fieldErrors.RUN_INTERVAL}>
                        <FormLabel>Run Interval</FormLabel>
                        <Select
                            value={currentWorkflowConfig.runInterval.toString()}
                            onChange={handleRunIntervalChange}
                            onBlur={onFieldBlur('RUN_INTERVAL')}>
                            <MenuItem key='none' value={0} sx={{ height: '36px' }}></MenuItem>
                            {intervalOptions.map((intervalOption) => (
                                <MenuItem key={intervalOption.value} value={intervalOption.value}>
                                    {intervalOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.RUN_INTERVAL}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required error={!!fieldErrors.COUNTRIES}>
                        <FormLabel>Countries</FormLabel>
                        {renderCountryChips()}
                        <FormHelperText>{fieldErrors.COUNTRIES}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </StandardFormDialog>
    );
};
