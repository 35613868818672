import { FC, useMemo } from 'react';
import { MarketingFunnelDataTable } from './MarketingFunnelDataTable';
import { useGetMostRecentWeekEndingDateQuery } from '../../store/apis/marketing-funnel-api';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';

export const MarketingFunnelView: FC = () => {
    const { data, isLoading, error, refetch } = useGetMostRecentWeekEndingDateQuery();

    const defaultDateFilter = useMemo(() => {
        if (!data) {
            return;
        }
        return new Date(Date.parse(data));
    }, [data]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <MarketingFunnelDataTable defaultWeekEndingFilter={defaultDateFilter} />
};
