import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from '../../store/apis/user-api';
import { UserDto } from '../../dtos';
import { UserForm } from '../../Components/Forms/UserForm';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';

export const AddUserView: FC = () => {
    const navigate = useNavigate();
    const [createUser, { data, isError, isSuccess, reset }] = useCreateUserMutation();

    useSuccessfulCreateSnackbar('User', isSuccess, () => navigate(`/user/${data?.id}`));
    useFailedCreateSnackbar('user', isError, reset)

    const handleSave = (values: UserDto) => {
        createUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    return <UserForm save={handleSave} cancel={handleCancel} />;
};
