import { AddCircle, FilterList } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, IconButton, OutlinedInput, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { CompanyBrandCountrySelect } from "../../Components/Forms/CustomFormSections";
import { WorkflowConfigBrandDto, WorkflowConfigCountryDto, WorkflowConfigDto } from "../../dtos";
import { NumericalComparatorDto } from "../../dtos/generated/NumericalComparatorDto";
import { useGetBrandAnalyticsWeeksQuery } from "../../store/apis/brand-analytics";
import { emptyGuid } from "../../util";
import { AdvancedFilterRow, IAdvancedFilterRow } from "./AdvancedFilterRow";
import { MarketingFunnelFilterProps } from "./types";
import { useConfirmationModal } from "../../util/CustomHooks/useConfirmationModal";

const filterToRow = (filter: NumericalComparatorDto): IAdvancedFilterRow => {
    return {
        index: filter.index,
        comparison: `${filter.comparison}`,
        columnName: filter.columnName,
        number: `${filter.number}`,
        isEmpty: false,
        isValid: true
    };
}

const rowToFilter = (filter: IAdvancedFilterRow): NumericalComparatorDto => {
    return {
        index: filter.index,
        comparison: parseInt(filter.comparison ?? ''),
        columnName: filter.columnName ?? '',
        number: parseFloat(filter.number ?? ''),
    };
}

export const MarketingFunnelFilterDialog: FC<MarketingFunnelFilterProps> = (props) => {
    const {
        isVisible,
        onClose,
        companyFilter,
        brandFilter,
        countryFilter,
        searchQueryFilter,
        weekEndingFilter,
        advancedFilters,
        setCompanyFilter,
        setBrandFilter,
        setCountryFilter,
        setSearchQueryFilter,
        setWeekEndingFilter,
        setAdvancedFilters,
    } = props;

    const [selectedCompany, setSelectedCompany] = useState<WorkflowConfigDto | null>(companyFilter ?? null);
    const [selectedBrand, setSelectedBrand] = useState<WorkflowConfigBrandDto | null>(brandFilter ?? null);
    const [selectedCountry, setSelectedCountry] = useState<WorkflowConfigCountryDto | null>(countryFilter ?? null);
    const [selectedWeekEnding, setSelectedWeekEnding] = useState<Date | null>(weekEndingFilter ?? null);
    const [selectedAdvancedFilters, setSelectedAdvancedFilters] = useState<IAdvancedFilterRow[] | null>(advancedFilters?.map(f => filterToRow(f)) ?? []);
    const [advancedFiltersErrorVisible, setAdvancedFiltersErrorVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState(searchQueryFilter ?? '');
    const applyFiltersAndClose = () => {
        setCompanyFilter(selectedCompany);
        setBrandFilter(selectedBrand);
        setCountryFilter(selectedCountry);
        setSearchQueryFilter(searchQuery);
        setWeekEndingFilter(selectedWeekEnding);
        setAdvancedFilters(selectedAdvancedFilters?.filter(f => !f.isEmpty).map(rowToFilter) ?? []);
        handleClose();
    }
    const { confirmAction, renderConfirmationModal } = useConfirmationModal(applyFiltersAndClose, 'No filters are provided which could cause the results to take a long time to load. Are you sure you would like to reload these results with no filters?');

    const {
        data: brandAnalyticsWeeks,
        isLoading: isLoadingBrandAnalyticsWeeks,
        isError: isBrandAnalyticsWeeksError,
    } = useGetBrandAnalyticsWeeksQuery({
        companyId: selectedCompany?.workflowId,
        brandId: selectedBrand?.id,
        countryId: selectedCountry?.countryId,
        searchQuery: searchQuery,
    });

    useFailedActionSnackbar('retrieve', 'weeks', isBrandAnalyticsWeeksError);

    const handleClose = () => {
        setSelectedCompany(companyFilter ?? null);
        setSelectedBrand(brandFilter ?? null);
        setSelectedCountry(countryFilter ?? null);
        setSearchQuery(searchQueryFilter ?? '');
        setSelectedWeekEnding(weekEndingFilter ?? null);
        setSelectedAdvancedFilters(advancedFilters?.map(filterToRow) ?? []);
        onClose();
    };

    const handleFilter = () => {
        const allValid = (selectedAdvancedFilters ?? [])
            .map(f => f.isEmpty || f.isValid)
            .reduce((accumulator, current) => accumulator === false ? accumulator : current, true);

        if (!allValid) {
            setAdvancedFiltersErrorVisible(true);
            return;
        }

        setAdvancedFiltersErrorVisible(false);

        const advancedFiltersWithComparisons = !!selectedAdvancedFilters ? selectedAdvancedFilters.filter(f => f.comparison !== '') : [];
        const isNoFiltersApplied = !selectedCompany && !selectedBrand && !selectedCountry && !searchQuery && !selectedWeekEnding && advancedFiltersWithComparisons.length === 0;
        if (isNoFiltersApplied) {
            confirmAction();
        } else {
            applyFiltersAndClose();
        }
    };

    const handleClear = () => {
        setSelectedCompany(null);
        setSelectedBrand(null);
        setSelectedCountry(null);
        setSelectedWeekEnding(null);
        setSelectedAdvancedFilters([buildDefaultAdvancedFilter()]);
    };

    const buildDefaultAdvancedFilter = () => {
        return {
            index: new Date().valueOf(),
            columnName: '',
            comparison: '',
            number: '',
            isEmpty: true,
            isValid: false
        };
    };

    useEffect(() => {
        setSelectedCompany(companyFilter ?? null);
        setSelectedBrand(brandFilter ?? null);
        setSelectedCountry(countryFilter ?? null);
        setSearchQuery(searchQueryFilter ?? '');
        setSelectedWeekEnding(weekEndingFilter ?? null);

        if (advancedFilters && advancedFilters.length > 0) {
            setSelectedAdvancedFilters(advancedFilters.map(filterToRow));
        } else {
            setSelectedAdvancedFilters([buildDefaultAdvancedFilter()]);
        }
    }, [brandFilter, countryFilter, companyFilter, weekEndingFilter, searchQueryFilter, advancedFilters]);

    const handleSelectDate = (newDate: Date | null | undefined) => {
        setSelectedWeekEnding(newDate ?? null);
    };

    const addAdvancedFilter = () => {
        const newItem = buildDefaultAdvancedFilter();
        const newList = [...selectedAdvancedFilters ?? [], newItem];
        setSelectedAdvancedFilters(newList);
    }

    const removeAdvancedFilter = (index: number) => {
        let copyOfAdvancedFilters = [...selectedAdvancedFilters ?? []];
        setSelectedAdvancedFilters([...copyOfAdvancedFilters.filter(f => f.index !== index)]);
    }

    const updateFilter = (filter: IAdvancedFilterRow) => {
        let copyOfAdvancedFilters = [...selectedAdvancedFilters ?? []];
        const oldItemLocation = _.indexOf(copyOfAdvancedFilters, _.find(copyOfAdvancedFilters, f => f.index === filter.index));
        copyOfAdvancedFilters?.splice(oldItemLocation, 1, filter);
        setSelectedAdvancedFilters(copyOfAdvancedFilters);
    }

    return (
        <>
            <Dialog
                open={isVisible}
                onClose={handleClose}
                maxWidth='xl'
            >
                <DialogTitle>
                    <Typography variant='h2'>
                        {<FilterList />} {'Filter Report'}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='column' pt={3} spacing={2}>
                        <Grid item container direction="row" justifyContent={'space-between'} spacing={3}>
                            <Grid item xs={6}>
                                <CompanyBrandCountrySelect
                                    selectedCompanyId={selectedCompany?.workflowId ?? emptyGuid}
                                    selectedBrandId={selectedBrand?.id ?? emptyGuid}
                                    selectedCountryId={selectedCountry?.countryId ?? emptyGuid}
                                    setSelectedCompany={setSelectedCompany}
                                    setSelectedBrand={setSelectedBrand}
                                    setSelectedCountry={setSelectedCountry}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <FormLabel>Search Query</FormLabel>
                                    <OutlinedInput
                                        sx={{ backgroundColor: '#FFFFFF' }}
                                        value={searchQuery}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormLabel disabled={isLoadingBrandAnalyticsWeeks}>Week Ending</FormLabel>
                                <DatePicker
                                    disabled={isLoadingBrandAnalyticsWeeks}
                                    value={selectedWeekEnding}
                                    componentsProps={{
                                        actionBar: {
                                            actions: ['clear']
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        handleSelectDate(e?.$d);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            disabled
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            sx={{ backgroundColor: '#FFFFFF' }}
                                        />
                                    )}
                                    shouldDisableDate={(day: any) => {
                                        const date: Date | undefined = day?.$d;
                                        if (!date) {
                                            return true;
                                        }
                                        return !brandAnalyticsWeeks?.some((endingDate) => new Date(endingDate).toUTCString() === date.toUTCString()) ?? true;
                                    }}
                                    shouldDisableYear={(year: any) => {
                                        const date: Date | undefined = year?.$d;
                                        if (!date) {
                                            return true;
                                        }

                                        return (
                                            !brandAnalyticsWeeks?.some((endingDate) => new Date(endingDate).getUTCFullYear() === date.getUTCFullYear()) ??
                                            true
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justifyContent={'space-between'} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant='h2'>
                                    Advanced Filters
                                </Typography>
                            </Grid>
                        </Grid>
                        {selectedAdvancedFilters?.map((filter, i) => (
                            <AdvancedFilterRow key={filter.index} advancedFilter={filter} setAdvancedFilter={updateFilter} removeFilter={removeAdvancedFilter} isFirstRow={i === 0} />
                        ))}
                        <Grid item container direction="row" justifyContent={'space-between'} spacing={3}>
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={addAdvancedFilter}>
                                        <AddCircle fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justifyContent={'space-between'} spacing={3}>
                            <Grid item xs={12}>
                                {advancedFiltersErrorVisible && (
                                    <FormHelperText error>
                                        All advanced filters must be completely and properly filled in.
                                    </FormHelperText>)}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ py: 2 }}>
                    <Grid container direction='row' justifyContent={'space-between'}>
                        <Grid item>
                            <Button onClick={handleClear} size='large'>
                                Clear All
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog >
            { renderConfirmationModal }
        </>
    );
};