import { ShoppingCart } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { AsinDto } from '../../../dtos';
import { useCreateAsinMutation, useUpdateAsinMutation } from '../../../store/apis/asin-api';
import { useGetBrandsForWorkflowConfigQuery, useGetWorkflowConfigsQuery } from '../../../store/apis/workflow-config-api';
import { emptyGuid } from '../../../util/constants';
import { IFormFieldValidationConfig, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation, StandardFormDialog, useFailedActionSnackbar, useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../CoreLib/library';
import { IAsinFormDialogProps } from './types';
import { defaultAsin } from './utils';

export const AsinFormDialog: FC<IAsinFormDialogProps> = (props) => {
    const { selectedAsin, onClearSelected } = props;
    // TODO: after looking at some articles regarding component performance it looks like we will want to have separate useStates for each field.
    const [currentAsin, setCurrentAsin] = useState(defaultAsin);
    const [changed, setFormChanged] = useState(false);
    const [createAsin, { isSuccess: isCreateSuccessful, isError: isCreateError, isLoading: isCreateLoading, reset: resetCreate }] = useCreateAsinMutation();
    const [updateAsin, { isSuccess: isUpdateSuccessful, isError: isUpdateError, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateAsinMutation();
    const { data: getWorkflowConfigResponse, isLoading: isLoadingWorkflowConfigs, isError: isGetWorkflowConfigError } = useGetWorkflowConfigsQuery({
        searchText: '',
        sortKey: 'COMPANY_NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });
    const { data: workflowConfigBrands, isLoading: isLoadingWorkflowConfigBrands, isError: isGetWorkflowConfigBrandError, isFetching: isReloadingBrands } = useGetBrandsForWorkflowConfigQuery(currentAsin.workflowId);
    const isLoading = () => isCreateLoading || isUpdateLoading || isLoadingWorkflowConfigs || isLoadingWorkflowConfigBrands;
    const [fieldErrors, setFieldErrors] = useState<Map<keyof AsinDto, string>>(new Map([
        ['companyName', ''],
        ['brandName', ''],
        ['ecommerceAsin', ''],
        ['sku', ''],
    ]));
    const formFieldValidators = new Map<keyof AsinDto, IFormFieldValidationConfig>([
        ["workflowId", {
            validators: [isNotBlank],
            errorMessageEntityName: 'Company'
        }],
        ["workflowConfigBrandId", {
            validators: [isNotBlank],
            errorMessageEntityName: 'Brand'
        }],
        ["ecommerceAsin", {
            validators: [isNotBlank, isShorterThanMaxLength(20)],
            errorMessageEntityName: 'ASIN'
        }],
        ["sku", {
            validators: [isShorterThanMaxLength(20)],
            errorMessageEntityName: 'SKU'
        }],
    ])

    const validateField = (fieldName: keyof AsinDto, value?: any) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const fieldValue = value ?? currentAsin[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors)
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors)
            }
        }
    };

    const validateForm = () => {
        const validationResult = runFormValidation(currentAsin, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    useFailedActionSnackbar('retrieving', 'companies', isGetWorkflowConfigError);
    useFailedActionSnackbar('retrieving', 'brands', isGetWorkflowConfigBrandError);
    useSuccessfulCreateSnackbar('ASIN', isCreateSuccessful, resetCreate);
    useSuccessfulUpdateSnackbar('ASIN', isUpdateSuccessful, resetUpdate);
    useFailedCreateSnackbar('ASIN', isCreateError, resetCreate);
    useFailedUpdateSnackbar('ASIN', isUpdateError, resetUpdate);

    useEffect(() => {
        if (selectedAsin !== null) {
            setCurrentAsin(selectedAsin);
        }
    }, [selectedAsin]);

    const handleReset = () => {
        setCurrentAsin(defaultAsin);
    };

    const handleFieldChange = (fieldName: keyof AsinDto, value: any) => {
        setFormChanged(true);
        let updatedAsin = { ...currentAsin };
        updatedAsin[fieldName] = value as never;
        setCurrentAsin(updatedAsin);
    }

    const handleAsinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldChange('ecommerceAsin', event.target.value);
    };

    const handleSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldChange('sku', event.target.value);
    };

    const handleCompanyChange = (event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setCurrentAsin({
            ...currentAsin,
            workflowId: event.target.value,
            workflowConfigBrandId: emptyGuid
        });
    };

    const handleBrandChange = (event: SelectChangeEvent<string>) => {
        handleFieldChange('workflowConfigBrandId', event.target.value);
    };

    const isFormValid = () => {
        return validateForm();
    };

    const handleSave = async (): Promise<boolean> => {
        if (isFormValid()) {
            let result = null;
            if (currentAsin.id === emptyGuid) {
                result = await createAsin(currentAsin);
            } else {
                result = await updateAsin(currentAsin);
            }
            const hasData = Object.prototype.hasOwnProperty.call(result, 'data');
            return hasData;
        }
        return false;
    };

    const isNew = () => currentAsin.id === emptyGuid;
    
    const getCompanyOptions = () => {
        if (!getWorkflowConfigResponse) {
            return [];
        }
        return getWorkflowConfigResponse.pageResults.map(workflowConfig => ({ name: workflowConfig.companyName, value: workflowConfig.workflowId }));
    }

    const getBrandOptions = () => {
        if (!workflowConfigBrands) {
            return [];
        }
        return workflowConfigBrands.map(workflowConfigBrand => ({ name: workflowConfigBrand.brandName, value: workflowConfigBrand.id }));
    }

    return (
        <StandardFormDialog
            dialogProps={{
                open: selectedAsin !== null,
            }}
            icon={<ShoppingCart />}
            title={isNew() ? 'New ASIN' : 'Edit ASIN'}
            entityName='ASIN'
            isLoading={isLoading()}
            onSave={handleSave}
            onClose={onClearSelected}
            onReset={handleReset}
            isFormDirty={changed}
            isKeepOpenToggleVisible={isNew()}>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={6}>
                    <FormControl fullWidth required error={!!fieldErrors.get('workflowId')} disabled={isLoading()}>
                        <FormLabel>Company</FormLabel>
                        <Select
                            value={currentAsin.workflowId}
                            onChange={handleCompanyChange}
                            onBlur={() => validateField('workflowId')}>
                            <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                            {getCompanyOptions().map((companyOption) => (
                                <MenuItem key={companyOption.value} value={companyOption.value}>
                                    {companyOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.get('workflowId')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth required error={!!fieldErrors.get('workflowConfigBrandId')} disabled={isLoading() || isReloadingBrands || currentAsin.workflowId === emptyGuid}>
                        <FormLabel>Brand</FormLabel>
                        <Select
                            value={currentAsin.workflowConfigBrandId}
                            onChange={handleBrandChange}
                            onBlur={() => validateField('workflowConfigBrandId')}>
                            <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                            {getBrandOptions().map((brandOption) => (
                                <MenuItem key={brandOption.value} value={brandOption.value}>
                                    {brandOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.get('workflowConfigBrandId')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl error={!!fieldErrors.get('ecommerceAsin')} fullWidth required disabled={isLoading()}>
                        <FormLabel>ASIN</FormLabel>
                        <OutlinedInput value={currentAsin['ecommerceAsin']} onChange={handleAsinChange} onBlur={() => validateField('ecommerceAsin')}/>
                        <FormHelperText>{fieldErrors.get('ecommerceAsin')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl error={fieldErrors.get('sku') !== ''} fullWidth disabled={isLoading()}>
                        <FormLabel>SKU</FormLabel>
                        <OutlinedInput value={currentAsin.sku} onChange={handleSkuChange} onBlur={() => validateField('sku')} />
                        <FormHelperText>{fieldErrors.get('sku')}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </StandardFormDialog>
    );
};
