import { Search } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useCreateSearchTermMutation, useUpdateSearchTermMutation } from '../../../store/apis/search-term-api';
import { useGetBrandsForWorkflowConfigQuery, useGetWorkflowConfigsQuery } from '../../../store/apis/workflow-config-api';
import { emptyGuid } from '../../../util/constants';
import { StandardFormDialog, useFailedActionSnackbar, useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../CoreLib/library';
import { ISearchTermFormDialogProps } from './types';
import { defaultSearchTerm } from './utils';

export const SearchTermFormDialog: FC<ISearchTermFormDialogProps> = (props) => {
    const { selectedSearchTerm, onClearSelected } = props;
    const [fieldErrors, setFieldErrors] = useState({
        COMPANY: '',
        BRAND: '',
        SEARCH_QUERY: '',
    });
    const [currentSearchTerm, setCurrentSearchTerm] = useState(defaultSearchTerm);
    const [changed, setFormChanged] = useState(false);
    const [createSearchTerm, { isSuccess: isCreateSuccessful, isError: isCreateError, isLoading: isCreateLoading, reset: resetCreate }] =
        useCreateSearchTermMutation();
    const [updateSearchTerm, { isSuccess: isUpdateSuccessful, isError: isUpdateError, isLoading: isUpdateLoading, reset: resetUpdate }] =
        useUpdateSearchTermMutation();
    const { data: getWorkflowConfigResponse, isLoading: isLoadingWorkflowConfigs, isError: isGetWorkflowConfigError } = useGetWorkflowConfigsQuery({
        searchText: '',
        sortKey: 'COMPANY_NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
    });
    const { data: workflowConfigBrands, isLoading: isLoadingWorkflowConfigBrands, isError: isGetWorkflowConfigBrandError, isFetching: isReloadingBrands } = useGetBrandsForWorkflowConfigQuery(currentSearchTerm.workflowId);
    const isLoading = () => isCreateLoading || isUpdateLoading || isLoadingWorkflowConfigs || isLoadingWorkflowConfigBrands;
        
    useFailedActionSnackbar('retrieving', 'companies', isGetWorkflowConfigError)
    useFailedActionSnackbar('retrieving', 'brands', isGetWorkflowConfigBrandError)
    useSuccessfulCreateSnackbar('Search Term', isCreateSuccessful, resetCreate);
    useSuccessfulUpdateSnackbar('Search Term', isUpdateSuccessful, resetUpdate);
    useFailedCreateSnackbar('search term', isCreateError, resetCreate);
    useFailedUpdateSnackbar('search term', isUpdateError, resetUpdate);

    useEffect(() => {
        if (selectedSearchTerm !== null) {
            setCurrentSearchTerm(selectedSearchTerm);
        }
    }, [selectedSearchTerm]);

    const handleReset = () => {
        setCurrentSearchTerm(defaultSearchTerm);
    };

    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setCurrentSearchTerm({
            ...currentSearchTerm,
            searchQuery: event.target.value,
        });
    };

    const handleCompanyChange = (event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setCurrentSearchTerm({
            ...currentSearchTerm,
            workflowId: event.target.value,
            workflowConfigBrandId: emptyGuid
        });
    };

    const handleBrandChange = (event: SelectChangeEvent<string>) => {
        setFormChanged(true);
        setCurrentSearchTerm({
            ...currentSearchTerm,
            workflowConfigBrandId: event.target.value,
        });
    };

    const onFieldBlur = (fieldName: keyof(typeof fieldErrors)) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: keyof(typeof fieldErrors)) => {
        let isValid = false;
        if (fieldName === 'COMPANY') {
            if (currentSearchTerm.workflowId !== emptyGuid) {
                fieldErrors.COMPANY = '';
                isValid = true;
            } else {
                fieldErrors.COMPANY = 'Company is required';
                isValid = false;
            }
        } else if (fieldName === 'BRAND') {
            if (currentSearchTerm.workflowConfigBrandId !== emptyGuid) {
                fieldErrors.BRAND = '';
                isValid = true;
            } else {
                fieldErrors.BRAND = 'Brand is required';
                isValid = false;
            }
        } else if (fieldName === 'SEARCH_QUERY') {
            if (currentSearchTerm.searchQuery) {
                fieldErrors.SEARCH_QUERY = '';
                isValid = true;
            } else {
                fieldErrors.SEARCH_QUERY = 'Search term is required';
                isValid = false;
            }
        }
        setFieldErrors({
            COMPANY: fieldErrors.COMPANY,
            BRAND: fieldErrors.BRAND,
            SEARCH_QUERY: fieldErrors.SEARCH_QUERY,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('COMPANY');
        isValid = validate('BRAND') && isValid;
        isValid = validate('SEARCH_QUERY') && isValid;
        return isValid;
    };

    const handleSave = async (): Promise<boolean> => {
        if (formIsValid()) {
            let result = null;
            if (currentSearchTerm.id === emptyGuid) {
                result = await createSearchTerm(currentSearchTerm);
            } else {
                result = await updateSearchTerm(currentSearchTerm);
            }
            const hasData = Object.prototype.hasOwnProperty.call(result, 'data');
            return hasData;
        }
        return false;
    };

    const isNew = () => currentSearchTerm.id === emptyGuid;

    const getCompanyOptions = () => {
        if (!getWorkflowConfigResponse) {
            return [];
        }
        return getWorkflowConfigResponse.pageResults.map(workflowConfig => ({ name: workflowConfig.companyName, value: workflowConfig.workflowId }));
    }

    const getBrandOptions = () => {
        if (!workflowConfigBrands) {
            return [];
        }
        return workflowConfigBrands.map(workflowConfigBrand => ({ name: workflowConfigBrand.brandName, value: workflowConfigBrand.id }));
    }

    return (
        <StandardFormDialog
            dialogProps={{
                open: selectedSearchTerm !== null,
            }}
            icon={<Search />}
            title={isNew() ? 'New Search Term' : 'Edit Search Term'}
            entityName='search term'
            isLoading={isLoading()}
            onSave={handleSave}
            onClose={onClearSelected}
            onReset={handleReset}
            isFormDirty={changed}
            isKeepOpenToggleVisible={isNew()}>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={6}>
                    <FormControl fullWidth required error={!!fieldErrors.COMPANY} disabled={isLoading()}>
                        <FormLabel>Company</FormLabel>
                        <Select
                            value={currentSearchTerm.workflowId}
                            onChange={handleCompanyChange}
                            onBlur={onFieldBlur('COMPANY')}>
                            <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                            {getCompanyOptions().map((companyOption) => (
                                <MenuItem key={companyOption.value} value={companyOption.value}>
                                    {companyOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.COMPANY}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth required error={!!fieldErrors.BRAND} disabled={isLoading() || isReloadingBrands || currentSearchTerm.workflowId === emptyGuid}>
                        <FormLabel>Brand</FormLabel>
                        <Select
                            value={currentSearchTerm.workflowConfigBrandId}
                            onChange={handleBrandChange}
                            onBlur={onFieldBlur('BRAND')}>
                            <MenuItem key='none' value={emptyGuid} sx={{ height: '36px' }}></MenuItem>
                            {getBrandOptions().map((brandOption) => (
                                <MenuItem key={brandOption.value} value={brandOption.value}>
                                    {brandOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.BRAND}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl error={fieldErrors.SEARCH_QUERY !== ''} fullWidth required disabled={isLoading()}>
                        <FormLabel>Search Term</FormLabel>
                        <OutlinedInput value={currentSearchTerm.searchQuery} onChange={handleSearchTermChange} onBlur={onFieldBlur('SEARCH_QUERY')} />
                        <FormHelperText>{fieldErrors.SEARCH_QUERY}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </StandardFormDialog>
    );
};
