import { serverApi } from '..';
import { QueryResultsDto, SearchResultDto } from '../../dtos';
import { SearchResultQueryParams } from '../types';
import { buildSearchQueryRoute } from '../../util';
import { ReportRunDateDto } from '../../dtos/generated/ReportRunDateDto';

const searchResultApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            getSearchResults: builder.query<QueryResultsDto<SearchResultDto>, SearchResultQueryParams>({
                query: (params) => buildSearchQueryRoute('Reports/SearchResult', params),
                providesTags: [{ type: 'SearchResult' }],
            }),
            getRunDates: builder.query<ReportRunDateDto[], void>({
                query: () => 'Reports/SearchResult/runDates',
                providesTags: [{ type: 'SearchResult' }],
            }),
        };
    },
});

export const { useGetSearchResultsQuery, useGetRunDatesQuery } = searchResultApi;
