import { useContext } from 'react';
import { Button, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Menu, Settings, Summarize } from '@mui/icons-material';
import { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { INavigationDrawerProps, NavigationDrawerRouteDTO } from './types';
import { AuthenticatedComponent, userHasPermissions, UserPermissionContext } from '../CoreLib/library';

const openedMixin = (theme: Theme): CSSObject => ({
	width: '256px',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: '72px',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: '256px',
	flexShrink: 0,
	whiteSpace: 'nowrap',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const NavigationDrawer: FC<INavigationDrawerProps> = (props) => {
	const { routes, adminRoutes, reportRoutes } = props;
	const [isOpen, setIsOpen] = useState(true);
	const [isHoverOpen, setIsHoverOpen] = useState(false);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { permissions } = useContext(UserPermissionContext);

	const toggleDrawer = () => {
		setIsOpen(!isOpen);
		setIsHoverOpen(!isOpen);
	};

	const renderNavigationButtons = (routesToRender: NavigationDrawerRouteDTO[]) => {
		const visibleRoutes = routesToRender.filter((r) => !r.isHidden);
		return visibleRoutes.map((routeToRender) => {
			return createNavButton(routeToRender);
		});
	};

	const getPermittedAdminRoutes = () => {
		if (!adminRoutes) {
			return [];
		}
		
		return adminRoutes.filter(adminRoute => userHasPermissions(adminRoute.requiredPermissions ?? [], permissions));
	}

	const getPermittedReportRoutes = () => {
		if (!reportRoutes) {
			return [];
		}
		
		return reportRoutes.filter(reportRoute => userHasPermissions(reportRoute.requiredPermissions ?? [], permissions));
	}

	const createNavButton = (route: NavigationDrawerRouteDTO) => {
		const NavButton =
			isOpen || isHoverOpen ? (
				<Button
					key={route.route}
					className='nav-button full-width'
					onClick={() => {
						navigate(route.route!);
					}}
					startIcon={route.icon}
					sx={pathname === route.route ? { backgroundColor: '#F6C6A0', fontWeight: 'bold' } : { fontWeight: 'normal' }}>
					{route.name}
				</Button>
			) : (
				<IconButton
					key={route.route}
					className='nav-button'
					onClick={() => {
						navigate(route.route!);
					}}
					sx={pathname === route.route ? { backgroundColor: '#F6C6A0', fontWeight: 'bold' } : { fontWeight: 'normal' }}>
					{route.icon}
				</IconButton>
			);

		return <AuthenticatedComponent key={route.route} component={NavButton} requiredPermissions={route.requiredPermissions ?? []} />;
	};

	return (
		<>
			<Drawer
				variant='permanent'
				anchor='left'
				open={isOpen || isHoverOpen}
				onMouseEnter={() => {
					setIsHoverOpen(true);
				}}
				onMouseLeave={() => {
					setIsHoverOpen(false);
				}}>
				<Grid container alignItems='start' sx={{ marginBottom: '20px' }}>
					<IconButton edge='start' onClick={toggleDrawer}>
						<Menu />
					</IconButton>
				</Grid>
				<Grid
					container
					direction='column'
					alignItems='start'
					gap='8px'
					onMouseEnter={() => {
						setIsHoverOpen(true);
					}}>
					{renderNavigationButtons(routes)}
					{!(isOpen || isHoverOpen) && getPermittedReportRoutes().length > 0 && (
						<IconButton className='nav-button' sx={{ fontWeight: 'bold' }}>
							<Summarize />
						</IconButton>
					)}
					{!(isOpen || isHoverOpen) && getPermittedAdminRoutes().length > 0 && (
						<IconButton className='nav-button' sx={{ fontWeight: 'bold' }}>
							<Settings />
						</IconButton>
					)}
				</Grid>
				{(isOpen || isHoverOpen) && getPermittedReportRoutes().length > 0 && (
					<>
						<Divider sx={{ background: 'white', margin: '20px -20px 20px' }} />
						<Grid container>
							<Typography sx={{ marginBottom: '10px' }}>Reports</Typography>
							{renderNavigationButtons(getPermittedReportRoutes())}
						</Grid>
					</>
				)}
				{(isOpen || isHoverOpen) && getPermittedAdminRoutes().length > 0 && (
					<>
						<Divider sx={{ background: 'white', margin: '20px -20px 20px' }} />
						<Grid container>
							<Typography sx={{ marginBottom: '10px' }}>Admin</Typography>
							{renderNavigationButtons(getPermittedAdminRoutes())}
						</Grid>
					</>
				)}
			</Drawer>
			<Grid
				sx={
					isOpen || isHoverOpen
						? { marginLeft: '256px', transition: 'margin .2s', transitionTimingFunction: 'ease-out' }
						: { marginLeft: '72px', transition: 'margin .2s', transitionTimingFunction: 'ease-in', width: 'calc(100vw - 72px)' }
				}>
				{props.children}
			</Grid>
		</>
	);
};

export default NavigationDrawer;
