import { serverApi } from '..';
import { WorkflowConfigBrandDto } from '../../dtos';
import { BaseApi } from './base-api';

const WorkflowConfigBrandApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        var baseApi = new BaseApi<WorkflowConfigBrandDto>('WorkflowConfigBrand', 'WorkflowConfigBrand', builder);
        return {
            getWorkflowConfigBrands: baseApi.getAll(),
            getWorkflowConfigBrand: baseApi.getById(),
            createWorkflowConfigBrand: baseApi.create(),
            updateWorkflowConfigBrand: baseApi.update(),
            archiveWorkflowConfigBrand: baseApi.archive(),
            unarchiveWorkflowConfigBrand: baseApi.unarchive(),
            bulkArchiveWorkflowConfigBrand: baseApi.bulkArchive(),
            bulkUnarchiveWorkflowConfigBrand: baseApi.bulkUnarchive(),
        };
    },
});

export const {
    useGetWorkflowConfigBrandsQuery,
    useLazyGetWorkflowConfigBrandsQuery,
    useGetWorkflowConfigBrandQuery,
    useLazyGetWorkflowConfigBrandQuery,
    useCreateWorkflowConfigBrandMutation,
    useUpdateWorkflowConfigBrandMutation,
    useArchiveWorkflowConfigBrandMutation,
    useUnarchiveWorkflowConfigBrandMutation,
    useBulkArchiveWorkflowConfigBrandMutation,
    useBulkUnarchiveWorkflowConfigBrandMutation,
} = WorkflowConfigBrandApi;
