import { serverApi } from '..';
import { ImportAsinsRowDto, ImportDto, ImportSearchTermsRowDto } from '../../dtos';

const importApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        importAsinNumbers: builder.mutation<ImportDto<ImportAsinsRowDto>, ImportDto<ImportAsinsRowDto>>({
            query: (importDto) => ({
                url: 'import/asins',
                method: 'POST',
                body: importDto,
            }),
            invalidatesTags: [{ type: 'Import' }, { type: 'Asin' }],
        }),
        importSearchTerms: builder.mutation<ImportDto<ImportSearchTermsRowDto>, ImportDto<ImportSearchTermsRowDto>>({
            query: (importDto) => ({
                url: 'import/searchTerms',
                method: 'POST',
                body: importDto,
            }),
            invalidatesTags: [{ type: 'Import' }, { type: 'SearchTerm' }],
        }),
    }),
});

export const { useImportAsinNumbersMutation, useImportSearchTermsMutation } = importApi;
