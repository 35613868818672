import { format } from 'date-fns';
import { DataTableColumn } from "../../Components/CoreLib/library";
import { AsinDto, SearchResultDto, SearchTermDto, WorkflowConfigBrandDto, WorkflowConfigDto } from "../../dtos";

const DEFAULT_UNRANKED_RANK = 999999999;

export interface SearchResultFilterProps {
    isVisible: boolean;
    onClose: () => void;
    runDateFilter?: Date | null;
    setRunDateFilter: (runDate: Date | null) => void;
    searchTermFilter?: SearchTermDto | null;
    setSearchTermFilter: (searchTerm: SearchTermDto | null) => void;
    asinFilter?: AsinDto | null;
    setAsinFilter: (asin: AsinDto | null) => void;
    companyFilter?: WorkflowConfigDto | null;
    setCompanyFilter: (asin: WorkflowConfigDto | null) => void;
    brandFilter?: WorkflowConfigBrandDto | null;
    setBrandFilter: (asin: WorkflowConfigBrandDto | null) => void;
}

export const AMAZON_SEARCH_RESULTS_DEFAULT_SORT_KEY = 'POSITION';
export const AMAZON_SEARCH_RESULTS_COLUMNS: DataTableColumn<SearchResultDto>[] = [
    { key: 'position', label: 'Position', sortKey: 'POSITION', align: 'right', width: '100px' },
    { key: 'companyName', label: 'Company', sortKey: 'COMPANY' },
    { key: 'brandName', label: 'Brand', sortKey: 'BRAND' },
    { key: 'searchTermText', label: 'Search Term', sortKey: 'SEARCH_TERM', width: '125px' },
    { key: 'asin', label: 'ASIN', sortKey: 'ASIN' },
    { key: 'isOwnAsin', label: 'Owner?', sortKey: 'IS_OWN_ASIN', fieldMapper: (row) => (row.isOwnAsin ? 'Yes' : '') },
    { key: 'title', label: 'Title', sortKey: 'TITLE', width: '400px' },
    { key: 'country', label: 'Country', sortKey: 'COUNTRY' },
    { key: 'price', label: 'Price', sortKey: 'PRICE', align: 'right' },
    { key: 'rank1', label: 'Rank 1', sortKey: 'RANK_1', align: 'right', fieldMapper: (row) => row.rank1 === DEFAULT_UNRANKED_RANK ? '-' : row.rank1	},
    { key: 'rank1Category', label: 'Rank 1 Category', sortKey: 'RANK_1_CATEGORY', width: '125px' },
    { key: 'rank2', label: 'Rank 2', sortKey: 'RANK_2', align: 'right', fieldMapper: (row) => row.rank2 === DEFAULT_UNRANKED_RANK ? '-' : row.rank2 },
    { key: 'rank2Category', label: 'Rank 2 Category', sortKey: 'RANK_2_CATEGORY', width: '125px' },
    { key: 'runDate', label: 'Run Date', sortKey: 'RUN_DATE', fieldMapper: (row) => format(new Date(row.runDate.toString()), 'MM/dd/yyyy') },
];