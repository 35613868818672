import { AttachMoney, Percent, RemoveCircle } from "@mui/icons-material";
import { FormControl, FormLabel, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { MARKETING_FUNNEL_SUPPORTED_COMPARISONS, MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS } from "./types";

export interface IAdvancedFilterRow {
    index: number;
    columnName?: string;
    comparison?: string;
    number?: string;
    isEmpty: boolean;
    isValid: boolean;
}

export interface IAdvancedFilterRowProps {
    advancedFilter: IAdvancedFilterRow;
    setAdvancedFilter: (advancedFilter: IAdvancedFilterRow) => void;
    removeFilter: (index: number) => void;
    isFirstRow?: boolean;
}

export const AdvancedFilterRow: FC<IAdvancedFilterRowProps> = props => {
    const { advancedFilter, setAdvancedFilter, removeFilter, isFirstRow } = props;
    const [filterIndex] = useState(advancedFilter.index);
    const [isFirst] = useState(isFirstRow ?? false);
    const [isPercent, setIsPercent] = useState(false);
    const [isDollar, setIsDollar] = useState(false);

    const isRowEmpty = (field?: string, comparison?: string, number?: string): boolean => `${field}${comparison}${number}`.trim().length === 0;
    const isRowValid = (field?: string, comparison?: string, number?: string): boolean => {
        if (`${field}`.trim().length === 0) {
            return false;
        }

        if (`${comparison}`.trim().length === 0) {
            return false;
        }

        const parsedNumber = parseFloat(`${number}` ?? '');
        if (Number.isNaN(parsedNumber)) {
            return false;
        }
        return true;
    }

    const handleFieldChange = (event: SelectChangeEvent<string>) => {
        setAdvancedFilter({
            columnName: event.target.value,
            comparison: advancedFilter.comparison,
            number: advancedFilter.number,
            index: filterIndex,
            isEmpty: isRowEmpty(event.target.value, advancedFilter.comparison, advancedFilter.number),
            isValid: isRowValid(event.target.value, advancedFilter.comparison, advancedFilter.number),
        });
    }

    const handleComparisonChange = (event: SelectChangeEvent<string>) => {
        setAdvancedFilter({
            columnName: advancedFilter.columnName,
            comparison: event.target.value,
            number: advancedFilter.number,
            index: filterIndex,
            isEmpty: isRowEmpty(advancedFilter.columnName, event.target.value, advancedFilter.number),
            isValid: isRowValid(advancedFilter.columnName, event.target.value, advancedFilter.number),
        });
    }

    const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAdvancedFilter({
            columnName: advancedFilter.columnName,
            comparison: advancedFilter.comparison,
            number: event.target.value,
            index: filterIndex,
            isEmpty: isRowEmpty(advancedFilter.columnName, advancedFilter.comparison, event.target.value),
            isValid: isRowValid(advancedFilter.columnName, advancedFilter.comparison, event.target.value),
        });
    }

    useEffect(() => {
        const columnSelected = MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS.find(c => c.key === advancedFilter.columnName) ?? { isPercent: false, isDollar: false };
        setIsPercent(columnSelected.isPercent ?? false);
        setIsDollar(columnSelected.isDollar ?? false);
    }, [advancedFilter.columnName])

    return (
        <Grid item container direction="row" justifyContent={'space-between'} alignItems={'center'} spacing={3}>
            <Grid item xs={4}>
                <FormControl fullWidth >
                    <FormLabel>Field</FormLabel>
                    <Select value={advancedFilter.columnName} onChange={handleFieldChange} sx={{ background: 'white' }}>
                        <MenuItem key='none' value={''} sx={{ height: '36px' }}></MenuItem>
                        {MARKETING_FUNNEL_SUPPORTED_FILTER_COLUMNS.map(column => (
                            <MenuItem key={column.key} value={column.key}>
                                {column.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth >
                    <FormLabel>Comparison</FormLabel>
                    <Select value={advancedFilter.comparison} onChange={handleComparisonChange} sx={{ background: 'white' }}>
                        <MenuItem key='none' value={''} sx={{ height: '36px' }}></MenuItem>
                        {MARKETING_FUNNEL_SUPPORTED_COMPARISONS.map(comparison => (
                            <MenuItem key={comparison.key} value={comparison.key}>
                                {comparison.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel>Value</FormLabel>
                    <OutlinedInput
                        startAdornment={isDollar &&
                            (<InputAdornment position="start">
                                <AttachMoney />
                            </InputAdornment>)
                        }
                        endAdornment={isPercent &&
                            (<InputAdornment position="end">
                                <Percent />
                            </InputAdornment>)
                        }
                        sx={{ backgroundColor: '#FFFFFF' }}
                        value={advancedFilter.number}
                        onChange={handleNumberChange}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                {!isFirst && (
                    <IconButton color='primary' size='large' onClick={() => removeFilter(filterIndex)}>
                        <RemoveCircle color="primary" fontSize="large" sx={{ marginTop: '24px' }} />
                    </IconButton>)}
            </Grid>
        </Grid>
    );
}
