import { FC } from 'react';
import { IImportRowDto, ImportSearchTermsRowDto, ImportType } from '../../dtos';
import { useImportSearchTermsMutation } from '../../store/apis/import-api';
import { DataTableColumn } from '../../Components/Core/DataTable';
import { StandardImportModal } from '../../Components/Forms/StandardImportModal';
import { GENERIC_IMPORT_ROW_STATUSES, ImportModalProps } from '../../Components/Forms/StandardImportModal/types';

const IMPORT_ROW_STATUSES = {
	...GENERIC_IMPORT_ROW_STATUSES,
	MISSING_COMPANY: 'Missing Company',
	MISSING_BRAND: 'Missing Brand',
	MISSING_SEARCH_TERM: 'Missing Search Term'
}

export const ImportSearchTermsDialog: FC<ImportModalProps> = (props) => {
	const { isImportVisible, onClose } = props;

	const rowValidation = (data: ImportSearchTermsRowDto) => {
		if (!data.company ) {
			return IMPORT_ROW_STATUSES.MISSING_COMPANY;
		} else if (!data.brand ) {
			return IMPORT_ROW_STATUSES.MISSING_BRAND;
		} else if (!data.searchTerm ) {
			return IMPORT_ROW_STATUSES.MISSING_SEARCH_TERM;
		}
		return IMPORT_ROW_STATUSES.GOOD;
	}
	
	const tableColumns: DataTableColumn<IImportRowDto>[] = [
		{ key: 'company', label: 'Company', sortKey: 'COMPANY' },
		{ key: 'brand', label: 'Brand', sortKey: 'BRAND' },
		{ key: 'searchTerm', label: 'Search Term', sortKey: 'SEARCH_TERM' },
	];
					
	return (
		<StandardImportModal
			entityNamePlural='Search Terms'
			sampleCsvFileName='sample-search-terms.csv'
			requiredHeaders={['company', 'brand', 'searchTerm']}
			tableColumns={tableColumns}
			localValidationMethod={rowValidation}
			importMutation={useImportSearchTermsMutation}
			importType={ImportType.SearchTerm}
			isVisible={isImportVisible}
			onClose={onClose}
		/>
	);
};
