import { serverApi, SupportViewQueryParams } from '..';
import { QueryResultsDto } from '../../dtos';
import { AmazonDataSummaryDto } from '../../dtos/generated/reporting/AmazonDataSummaryDto';
import { DailySearchSummaryDto } from '../../dtos/generated/reporting/DailySearchSummaryDto';
import { buildSearchQueryRoute } from '../../util';

const supportApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            getDailySearches: builder.query<QueryResultsDto<DailySearchSummaryDto>, SupportViewQueryParams>({
                query: (params) => buildSearchQueryRoute('support/dailySearch', params),
            }),
            getAmazonData: builder.query<QueryResultsDto<AmazonDataSummaryDto>, SupportViewQueryParams>({
                query: (params) => buildSearchQueryRoute('support/amazonData', params),
            }),
        };
    },
});

export const { useGetDailySearchesQuery, useGetAmazonDataQuery } = supportApi;
