import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { BaseDto, QueryResultsDto } from '../../dtos';

export interface BrandAnalyticsTabProps {
    label: string;
    value: string;
    panel: any;
}

export interface BrandAnalyticsChartProps<T extends BaseDto> {
    initValues?: QueryResultsDto<T>;
    isLoading: boolean;
    refetch(): void;
    error: FetchBaseQueryError | SerializedError | undefined;
    setPaginatedProps: any;
}

export const getPercentString = (value: number) => {
    return `${(value).toFixed(2)}%`;
}

export const getDateString = (value: Date) => {
    return new Date(Date.parse(value.toString())).toLocaleDateString();
}