import { ContentCopy, Dataset, Lightbulb } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { DataTableColumn, FormSection, NavBreadcrumbs, PaginatedDataTable, PaginatedProps, StandardCardWithHeader, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { CompanyBrandCountrySelect } from '../../Components/Forms/CustomFormSections';
import { WorkflowConfigDto, WorkflowConfigBrandDto } from '../../dtos';
import { useGetAmazonDataQuery, useGetDailySearchesQuery } from '../../store/apis/support-api';
import { SupportViewQueryParams } from '../../store';
import { DailySearchSummaryDto } from '../../dtos/generated/reporting/DailySearchSummaryDto';
import { AmazonDataSummaryDto } from '../../dtos/generated/reporting/AmazonDataSummaryDto';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'COMPANY',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const SupportView: FC = () => {
    const [company, setCompany] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<SupportViewQueryParams>(defaultPaginationProps);
    const [brand, setBrand] = useState('');

    const {
        data: dailySearchData,
        isFetching: dailySearchIsLoading,
        isError: dailySearchIsError,
    } = useGetDailySearchesQuery({
        ...paginatedProps,

    });

    const {
        data: amazonData,
        isFetching: amazonDataIsLoading,
        isError: amazonDataIsError,
    } = useGetAmazonDataQuery({
        ...paginatedProps,

    });


    useFailedActionSnackbar('retrieve', 'daily searches', dailySearchIsError);
    useFailedActionSnackbar('retrieve', 'amazon data', amazonDataIsError);

    const handleCompanyChange = (company: WorkflowConfigDto | null) => {
        if (company === null) {
            setCompany('');
        } else {
            setCompany(company.workflowId);
        }
    }

    const handleBrandChange = (selectedBrand: WorkflowConfigBrandDto | null) => {
        if (selectedBrand === null) {
            setBrand('');
        } else {
            setBrand(selectedBrand.id);
        }
    }

    const wrapWithCopyButton = (value: string) => {
        return value === null ? '' : <>{value}<IconButton onClick={(e) => navigator.clipboard.writeText(value)}><ContentCopy /></IconButton></>
    }

    const dailySearchTableColumns: DataTableColumn<DailySearchSummaryDto>[] = [
        {
            key: 'workflowId', label: 'Workflow ID', sortKey: 'TODO', align: 'left', unsorted: true, fieldMapper: (row) => (wrapWithCopyButton(row.workflowId))
        },
        {
            key: 'workflowInstanceId', label: 'Workflow Instance Id', sortKey: 'TODO', align: 'left', unsorted: true, fieldMapper: (row) => (wrapWithCopyButton(row.workflowInstanceId))
        },
        {
            key: 'companyName', label: 'Company Name', sortKey: 'TODO', align: 'left', unsorted: true
        },
        {
            key: 'brandName', label: 'Brand Name', sortKey: 'TODO', align: 'left', unsorted: true
        },
        {
            key: 'total', label: 'Total', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'extracted', label: 'WFU-Extracted', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'prepared', label: 'Prepared', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'inProgress', label: 'In Progress', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'completed', label: 'Completed', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'failed', label: 'Failed', sortKey: 'TODO', align: 'left', unsorted: true
        },

    ];

    const amazonDataTableColumns: DataTableColumn<AmazonDataSummaryDto>[] = [
        {
            key: 'workflowId', label: 'Workflow ID', sortKey: 'TODO', align: 'left', unsorted: true, fieldMapper: (row) => (wrapWithCopyButton(row.workflowId))
        },
        {
            key: 'workflowInstanceId', label: 'Workflow Instance Id', sortKey: 'TODO', align: 'left', unsorted: true, fieldMapper: (row) => (wrapWithCopyButton(row.workflowInstanceId))
        },
        {
            key: 'companyName', label: 'Company Name', sortKey: 'TODO', align: 'left', unsorted: true
        },
        {
            key: 'brandName', label: 'Brand Name', sortKey: 'TODO', align: 'left', unsorted: true
        },
        {
            key: 'total', label: 'Total', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'prepared', label: 'Prepared', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'inProgress', label: 'In Progress', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'awaitingCountryCode', label: 'Awaiting CC', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'completed', label: 'Completed', sortKey: 'TODO', align: 'left', unsorted: true
        }, {
            key: 'failed', label: 'Failed', sortKey: 'TODO', align: 'left', unsorted: true
        },

    ];

    const isLoadingData = () => dailySearchIsLoading || amazonDataIsLoading

    const onSearch = () => {
        setPaginatedProps({
            ...paginatedProps,
            companyId: company,
            brandId: brand,
        })
    }

    const breadCrumbs = [
        { label: 'Home', navLink: '/' },
        { label: 'Admin', navLink: '', isText: true },
    ]

    return (
        <Grid container direction='column'>
            <Grid item container direction='column' justifyContent='start' xs={6} mb={4}>
                <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                    <Lightbulb /> RoboSource Support
                </Typography>
                {breadCrumbs && <NavBreadcrumbs links={breadCrumbs} currentPageLabel='RoboSource Support' />}
            </Grid>
            <Grid item>
                <FormSection backgroundColor={'white'}>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={6}>
                            <CompanyBrandCountrySelect
                                selectedCompanyId={company}
                                selectedBrandId={brand}
                                setSelectedCompany={handleCompanyChange}
                                setSelectedBrand={handleBrandChange}
                            />
                        </Grid>
                        <Grid item container xs={1} alignItems='center' marginTop={'22px'}>
                            <Button variant='contained' color='secondary' onClick={onSearch} sx={{ width: 88 }} disabled={isLoadingData()}>
                                {isLoadingData() ? <CircularProgress size={24} color='inherit' /> : 'Search'}
                            </Button>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item paddingY={2}>
                <StandardCardWithHeader headerTitle='Daily Search' headerIcon={<Dataset />}>
                    <PaginatedDataTable
                        columns={dailySearchTableColumns}
                        loading={dailySearchIsLoading}
                        queryResults={dailySearchData!}
                        defaultSortKey='COMPANY'
                        setPagination={setPaginatedProps}
                        isDashboardStyle={true}
                        isViewOnly={true}
                        isInactiveStyleApplied={false}
                    />
                </StandardCardWithHeader>
            </Grid >
            <Grid item >
                <StandardCardWithHeader headerTitle='Amazon Data' headerIcon={<Dataset />}>
                    <PaginatedDataTable
                        columns={amazonDataTableColumns}
                        loading={amazonDataIsLoading}
                        queryResults={amazonData!}
                        defaultSortKey='COMPANY'
                        setPagination={setPaginatedProps}
                        isDashboardStyle={true}
                        isViewOnly={true}
                        isInactiveStyleApplied={false}
                    />
                </StandardCardWithHeader>
            </Grid >

        </Grid>
    );
};
