import { Grid } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import { FC, useState } from 'react';
import { UnitsPurchasedDto } from '../../dtos';
import { BrandAnalyticsChartProps, getDateString } from './types';
import { Dataset, InsertChart } from '@mui/icons-material';
import Highcharts from 'highcharts';
import { DataTableColumn, LoadingIndicator, ApiError, StandardCardWithHeader, PaginatedDataTable } from '../../Components/CoreLib/library';

export const UnitsPurchasedChart: FC<BrandAnalyticsChartProps<UnitsPurchasedDto>> = (props) => {
    const { initValues, setPaginatedProps, isLoading, error, refetch } = props;

    const [hoverRowId, setHoverRowId] = useState('');

    const tableColumns: DataTableColumn<UnitsPurchasedDto>[] = [
        { key: 'weekOfDate', label: 'Week Of', sortKey: 'WEEK_OF', align: 'left', unsorted: true, keyFieldFormatter: getDateString },
        { key: 'totalMarket', label: 'Total Market', sortKey: 'TOTAL_MARKET', align: 'center', unsorted: true, },
        { key: 'marketPrevYear', label: 'Market Prev Year', sortKey: 'MARKET_PREV_YEAR', align: 'center', unsorted: true, },
        { key: 'brandUnits', label: 'Brand Units', sortKey: 'BRAND_UNITS', align: 'center', unsorted: true, },
        { key: 'brandPrevYear', label: 'Brand Prev Units', sortKey: 'BRAND_PREV_UNITS', align: 'right', unsorted: true, },
    ];

    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            events: {
                render: function () {
                    if (initValues && hoverRowId !== "") {
                        var point = this.series[0].data[initValues.pageResults.findIndex(x => x.id === hoverRowId)];

                        point.setState('hover');
                        this.tooltip.refresh(point);

                    }
                },
            },

        },
        title: {
            text: 'Units Purchased by Week Of',
            align: 'center'
        },
        yAxis: {
            title:
            {
                text: 'Total Market'
            },
        },
        xAxis: {
            categories: initValues?.pageResults.map(x => getDateString(x.weekOfDate)),
            title:
            {
                text: 'Week Of'
            }
        },
        legend: {
            align: 'center',
            layout: 'horizontal',
            verticalAlign: 'top'
        },
        series: [
            {
                name: 'Total Market',
                data: initValues?.pageResults.map(x => x.totalMarket),
                type: 'column',


            },
            {
                name: 'Brand Units',
                data: initValues?.pageResults.map(x => x.brandUnits),
                type: 'line'
            }
        ]
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (error) {
        <ApiError onReloadClick={refetch} />;
    }

    return (
        <>
            <Grid item >
                <StandardCardWithHeader headerTitle='Units Purchased' headerIcon={<Dataset />}>
                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={false}
                        queryResults={initValues}
                        defaultSortKey='LABEL'
                        setPagination={setPaginatedProps}
                        isDashboardStyle={true}
                        isViewOnly={true}
                        isInactiveStyleApplied={false}
                        onHover={(id) => setHoverRowId(id)}
                    />
                </StandardCardWithHeader>
            </Grid >
            <Grid item >
                <StandardCardWithHeader headerTitle='Units Purchased by Week Of' headerIcon={<InsertChart />}>
                    <HighchartsReact highcharts={Highcharts} options={options}
                        containerProps={{ style: { width: '100%' } }}
                    ></HighchartsReact>
                </StandardCardWithHeader>
            </Grid>
        </>

    );
};
